a.internal-download {
  margin-bottom: 10px;
  color: $mainOrange;
  &:after {
    content: "";
    display: inline-block;
    background-image: url($img-path + 'icon_link.png');
    background-repeat: no-repeat;
    background-position: right 3px;
    background-size: contain;
    width: 22px;
    height: 22px;
    padding-left: 10px;
  }
}
a.internal-link {
  margin-bottom: 10px;
  color: $mainOrange;
}

.internal {
  position: relative;
  padding: 70px 0;
  .container {
    padding-top: 80px;
    padding-left: 80px;
    &-header {
      overflow: hidden;
      margin-bottom: 30px;
      .lable {
        font-family: 'NexaBold';
        font-size: 13px;
        color: $mainRed;
        display: block;
      }
      .title {
        display: block;
        font-family: 'NexaThin';
        font-size: 45px;
        color: $darkGrayText;
      }
    }
    .content {
      .infografico {
        max-width: 1200px;
      }
      .side {
        &.left {
          width: 54%;
        }
        &.right {
          width: 40%;
        }
        @media only screen and (max-width: 868px) {
          &.right, &.left {
            width: 100% !important;
          }
          &.right{
            float:none;
          }
        }
      }
      p {
        font-family: 'Arial';
        font-size: 13px;
        color: $darkGrayText;
        line-height: 20px;
        font-weight: 100;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      a.internal-download {
        margin-bottom: 10px;
        color: $mainOrange;
        &:after {
          content: "";
          display: inline-block;
          background-image: url($img-path + 'icon_link.png');
          background-repeat: no-repeat;
          background-position: right 3px;
          background-size: contain;
          width: 22px;
          height: 22px;
          padding-left: 10px;
        }
      }
      a.internal-link {
        margin-bottom: 10px;
        color: $mainOrange;
      }
      h1 {
        display: block;
        font-family: 'NexaThin';
        font-size: 45px;
        color: $darkGrayText;
      }
      @media only screen and (max-width: 868px) {
        h1{
          font-size:28px;
          margin-bottom: 0;
        }
      }
      h2 {
        display: block;
        font-family: 'NexaThin';
        font-size: 36px;
        color: $darkGrayText;
      }
      h3 {
        display: block;
        font-family: 'NexaThin';
        font-size: 24px;
        color: $darkGrayText;
      }
      h4 {
        display: block;
        font-family: 'NexaBold';
        font-size: 16px;
        color: $darkGrayText;
      }
      h5 {
        display: block;
        font-family: 'NexaBold';
        font-size: 12px;
        color: $darkGrayText;
      }
      h6 {
        display: block;
        font-family: 'NexaLight';
        font-size: 12px;
        color: $mainOrange;
      }
      .center {
        display: block;
        margin: 0 auto;
      }
      .paula {
        display: block;
        margin-left: 50px;
      }
      .fix-topPosition {
        margin-top: -132px;
      }
      .big-image {
        .bar {
          position: relative;
          width: 100%;
          height: 100px;
          background-color: $greyText2;
          .text {
            font-family: 'NexaBold';
            font-size: 15px;
            color: $darkGrayText;
            text-align: right;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) translateZ(0);
            margin-left: -20px;
            @media (max-width: 1200px) {
              margin-left: -50px;
            }
          }
          .social-list {
            position: absolute;
            top: 0;
            right: 0;
            height: 100px;
            overflow: hidden;
            &__item {
              display: block;
              float: left;
              width: 50px;
              height: 100px;
              background-image: url($img-path + 'internal-social-list.png');
              background-repeat: no-repeat;
              &.facebook {
                background-position: 0 0;
              }
              &.instagram {
                background-position: -50px 0;
              }
              &.twitter {
                background-position: -100px 0;
              }
            }
          }
        }
        .secretaria-foto {
          width: 50%;
        }
        .secretaria-foto-paisagem {
          width: 100%;
        }
      }
    }
    table.danos,th,td{
      border: 1px solid #CCC;
      width: 100%;
      font-family: 'Arial';
      font-size: 13px;
      color: #666666;
      line-height: 20px;
      font-weight: 100;
    }
    table.danos th{
      background-color: #CCC;
    }
    table.danos .veiculos{
      background-color: gainsboro;
      width: auto;
    }
    table.danos .veiculos_num{
      background-color: gainsboro;
      width:0%;
    }

    table.danos input{
      position: relative;
      display: inline-block;
      padding: 0px 10px;
      -webkit-appearance: checkbox !important;
    }
    .title-page{
      font-family: 'NexaLight';
      font-size: 24px;
      color: #F4A14E;
    }
    .text {
      padding-right: 27px !important;
    }
  }
  @media only screen and (max-width: 868px) {
   .container {
    padding: 0;
  }
}
}
@media only screen and (max-width: 868px) {
  .internal {
    padding-top:35px;
  }
}

.noticias {
  position: relative;
  padding: 70px 0;
  .container {
    padding-top: 80px;
    padding-left: 80px;
    &-header {
      overflow: hidden;
      margin-bottom: 30px;
      .lable {
        font-family: 'NexaBold';
        font-size: 13px;
        color: $mainRed;
        display: block;
      }
      .title {
        display: block;
        font-family: 'NexaThin';
        font-size: 45px;
        color: $darkGrayText;
      }
    }
    .content {
      .infografico {
        max-width: 1200px;
      }
      .side {
        &.left {
          width: 64.44%;
        }
        &.right {
          width: 40%;
        }
      }
      p {
        font-family: 'Arial';
        font-size: 13px;
        color: $darkGrayText;
        line-height: 20px;
        font-weight: 100;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        &.template-link {
          margin-bottom: 10px;
        }
      }
      .pagination {
        display: inline-block;
        font-family: 'NexaBold';
        padding: 35px 0px 0px 0px;
        a {
          color: #aaaaaa;
          float: left;
          text-align: center;
          padding: 5px 10.44px;
          text-decoration: none;
          transition: border-color .3s, color .3s;
          -webkit-transition: border-color .3s, color .3s;
          -moz-transition: border-color .3s, color .3s;
          -o-transition: border-color .3s, color .3s;
          border: 1px solid #dddddd;
          margin-right: 15px;
        }
        .numbers {
          float: left;
          overflow: hidden;
        }
        .total-pages {
          color: #aaaaaa;
          float: left;
          width: 65.77px; // max-width: 70px;
          // min-width: 31px;
          height: 32px;
          text-decoration: none;
          -moz-transition: border-color .3s, color .3s;
          -o-transition: border-color .3s, color .3s;
          -webkit-transition: border-color .3s, color .3s;
          transition: border-color .3s, color .3s;
          border: 1px solid #dddddd;
          text-align: center;
        }
        a.active {
          //  background-color: #4CAF50;
          color: $darkOrange;
          border: 1px solid $darkOrange;
        }
        a:hover:not(.active) {
          border-color: $darkOrange;
          color: $darkOrange;
        }
      }
      .container-link {
        height: 147px;

        @include mid {
          float: right;
          width: 60%;
          max-width: 464px;
          padding: 20px;
        }
        @include xl {
          float: right;
          width: 100%;
          max-width: 464px;
          margin: 0px -3px 0 0;
        }
        @include xm {
          float: right;
          width: 100%;
          max-width: 464px;
          margin: 0px 60px 0 0;
        }
        @include xll {
          float: right;
          width: 100%;
          max-width: 464px;
          margin: 0px 130px 0 0;
        }
      }
      h1 {
        display: block;
        font-family: 'NexaThin';
        font-size: 45px;
        color: $darkGrayText;
      }
      .template-title {
        display: block;
        font-family: 'Nexalight';
        font-size: 26px;
        color: $darkGrayText;
        line-height: 1.35em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        a {
          color: $darkGrayText;
        }
      }

      .borderBottom {
        display: none;
      }
      
      .template-descripiton {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .template-descripiton,
      .template-time {
        font-family: 'NexaBold';
        font-size: 15px;
        font-weight: 100;
        color: #aaaaaa;
        line-height: 22px;
        font-weight: lighter;
        margin-bottom: 37px;
        .template-time {
          line-height: 1.35em;
        }
        .template-descripiton {
          line-height: 1.35em;
        }
      }
      blockquote {
        position: relative;
        /* width: 0; */
        text-transform: uppercase;
        border-left: 6px solid #222222;
        padding: 0px 0px 0px 20px;
        font-family: 'Nexabold';
        font-size: 13px;
        line-height: 35px;
        color: #ff5400;
        margin: 40px 0px 32px 0px;
      }
      h3 {
        display: block;
        font-family: 'NexaThin';
        font-size: 24px;
        color: $darkGrayText;
      }
      h4 {
        display: block;
        font-family: 'NexaBold';
        font-size: 16px;
        color: $darkGrayText;
      }
      h5 {
        display: block;
        font-family: 'NexaBold';
        font-size: 12px;
        color: $darkGrayText;
      }
      h6 {
        display: block;
        font-family: 'NexaLight';
        font-size: 12px;
        color: $mainOrange;
      }
      .center {
        display: block;
        margin: 0 auto;
      }

    }
  }


}