.digital {
  position: relative;
  width: 100%;
  background: url($img-path + 'bg_digital.png') center no-repeat;
  background-size: cover;

  &-header {
    padding: 120px 0 90px;
    width: 80%;
    @media only screen and (max-width: 1023px) {
      width:100%;
    }

    .description {
      font-family: 'Arial';
      font-size: 15px;
      color: $darkGrayText;
      line-height: 22px;
      margin: 15px 0 0;

      .light {
        color: $mainOrange;
        font-style: italic;
      }
    }
  }

  .digital-menu {
    overflow: hidden;

    .line {
      overflow: hidden;

      &:nth-child(odd) {
        .digital-menu__item {
          &:nth-child(odd) {
            background-color: rgba(221, 221, 221, .25);
          }

          &:nth-child(even) {
            background-color: rgba(221, 221, 221, .1);
          }
        }
      }

      &:nth-child(even) {
        .digital-menu__item {
          &:nth-child(odd) {
            background-color: rgba(221, 221, 221, .1);
          }

          &:nth-child(even) {
            background-color: rgba(221, 221, 221, .25);
          }
        }
      }

      .digital-menu__item {
        position: relative;
        display: table;
        float: left;
        width: 25%;
        @media only screen and (max-width: 800px) {
          width:50%;
        }

        @media only screen and (max-width: 500px) {
          width:100%;
          margin: 2px;
        }
        height: 250px;
        overflow: hidden;

        .content {
          display: table-cell;
          vertical-align: middle;

          .image {
            background-position: center;
            background-repeat: no-repeat;

            display: block;
            width: 101px;
            height: 95px;
            margin: 0 auto 20px;

            &.immobile { background-image: url($img-path + '/menu_digital/icon_immobile.png'); }
            &.edificapel { background-image: url($img-path + '/menu_digital/icon_edificapel.png'); }
            &.sala-do-empreendedor { background-image: url($img-path + '/menu_digital/icon_sala_empreendedor.png'); }
            &.company { background-image: url($img-path + '/menu_digital/icon_company.png'); }
            &.rural-producer { background-image: url($img-path + '/menu_digital/icon_rural-producer.png'); }
            &.procon { background-image: url($img-path + '/menu_digital/icon_procon.png'); }
            &.transport { background-image: url($img-path + '/menu_digital/icon_transport.png'); }
            &.environment { background-image: url($img-path + '/menu_digital/icon_environment.png'); }
            &.sanep { background-image: url($img-path + '/menu_digital/icon_sanep.png'); }
            &.server { background-image: url($img-path + '/menu_digital/icon_server.png'); }
            &.phone { background-image: url($img-path + '/menu_digital/icon_phone.png'); }
            &.road { background-image: url($img-path + '/menu_digital/icon_road.png'); }
            &.lost { background-image: url($img-path + '/menu_digital/icon_lost.png'); }
            &.tribute { background-image: url($img-path + '/menu_digital/icon_tribute.png'); }
            &.refispel {
              background-image: url($img-path + '/menu_digital/icon_refispel.png');
              background-size: contain;
              width: 160px;
              height: 145px;
              margin: 0 auto 0;
            }
          }

          .label {
            font-family: 'NexaBold';
            font-size: 16px;
            color: $darkGray;
            text-transform: uppercase;
            opacity: .75;
            display: block;
            text-align: center;
          }

          .description {
            font-family: 'Arial';
            font-size: 12px;
            color: $darkGrayText;
            display: block;
            text-align: center;
            line-height: 18px;
            width: 90%;
            margin: 30px auto 0;
          }
        }
      }
    }
  }

  .icon-smile {
    width: 100%;
    overflow: hidden;
    margin-top: 90px;
    margin-bottom: 20px;

    .icon {
      background: url($img-path + 'icon_smile.png') center no-repeat;
      float: right;
      display: block;
      width: 82px;
      height: 80px;
    }
  }
}
