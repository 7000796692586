.social-media {
  position: relative;
  min-height: 0px !important;
  height: 0px;
  //min-height: 446px;
  //background-color: $bgLight;
  //background-image: url($img-path + 'bg.png');
  //background-position: center top;
  //background-repeat: no-repeat;
  //padding-bottom: 70px;

  .section-label {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .resume {
    overflow: hidden;
    width: 100%;

    .col {
      float: left;
      width: 25%;
      min-height: 690px;
      margin-right: 8%;

      &:last-child {
        margin-right: 0;
        width: 34%;
      }

      .social-resume {

        .head {
          width: 100%;
          height: 160px;
          background-color: $bgLight2;
          padding: 0 12.8%;
        }

        .interations {
          background-color: $white;
          padding: 0 14%;
        }

        .title {
          font-family: 'NexaBold';
          font-size: 22px;
          color: $darkGray;

          &.pelotas-conectada {
            background: url($img-path + 'pelotas-conectada.png') center left no-repeat;
            padding: 60px 0;
            padding-left: 130px;
          }

          &.heart {
            background: url($img-path + 'heart.png') center left no-repeat;
            padding: 30px 0;
            padding-left: 73px;
          }

          .description {
            display: block;
            font-family: 'Arial';
            font-size: 12px;
            color: $darkGray;
          }
        }

        .social-list {
          overflow: hidden;

          &__item {
            width: 33.33%;
            height: 80px;
            float: left;

            .value {
              font-family: 'Arial';
              font-size: 11px;
              color: $white;
              display: block;
              text-align: center;
              padding: 12px 0;

              &:before {
                content: '';
                display: block;
                height: 30px;
                margin: 0 auto 15px;
                background-position: center;
                background-repeat: no-repeat;
              }
            }

            &.facebook {
              background-color: $facebook;

              .value:before {
                width: 12px;
                background-image: url($img-path + 'icon_facebook.png');
              }
            }

            &.twitter {
              background-color: $twitter;

              .value:before {
                width: 29px;
                background-image: url($img-path + 'icon_twitter.png');
              }
            }

            &.youtube {
              background-color: $youtube;

              .value:before {
                width: 26px;
                background-image: url($img-path + 'icon_youtube.png');
              }
            }

            &.flickr {
              background-color: $flickr;

              .value:before {
                width: 28px;
                background-image: url($img-path + 'icon_flickr.png');
              }
            }

            &.instagram {
              background-color: $instagram;

              .value:before {
                width: 30px;
                background-image: url($img-path + 'icon_instagram.png');
              }
            }

            &.colab {
              background-color: $colab;

              .value:before {
                width: 26px;
                background-image: url($img-path + 'icon_colab.png');
              }
            }
          }
        }

        .report {
          background: $white;
          padding: 12.8%;

          .title {
            font-family: 'NexaBold';
            font-size: 16px;
            color: $greyText;
            text-transform: uppercase;
          }

          .description {
            font-family: 'Arial';
            font-size: 12px;
            color: $darkGrayText;
            display: block;
            margin: 20px 0 0;
            line-height: 18px;
          }

          .text {
            width: 100%;
            height: 40px;
            background-color: $bgLight3;
            padding: 0 10px;
            margin-top: 40px;
          }

          .status {
            font-family: 'Arial';
            font-size: 9px;
            opacity: 1;

            &.success {
              color: $mainGreen;
            }
          }
        }
      }
    }
  }
}