.header2 {
  position: relative;
  width: 100%;
  //height: 100vh;
  overflow: visible;

  .banner {
    position: relative;
    width: 100%;
    height: 420px;
    z-index: 5;

    background-image: url($img-path + '/banners/banner1.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    .video{
      position: absolute;
      z-index: -1;
      width: 100%;
      height:420px;
      overflow:hidden;
      .bg_video {
        position: absolute;
        top: 0;
        left: 0;
        width: 1920px;
        height: 1080px;
        min-height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
      }
    }

    .search {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) translateZ(0);
      z-index: 99;

      &__text {
        position: relative;
        margin: 0 auto;
        width: 960px;

        @media (max-width: 1366px) {
          width: 850px;
        }
        
        .text {
          display: block;
          width: 100%;
          height: 80px;
          background-color: white;
          padding: 0 20px;
          color: $mediumGray;
          font-family: 'NexaLight';
          font-size: 26px;

          ::-webkit-input-placeholder {
            color: $mediumGray;
            font-family: 'NexaLight';
            font-size: 26px;
          }
          ::-moz-placeholder {
            color: $mediumGray;
            font-family: 'NexaLight';
            font-size: 26px;
          }
          :-ms-input-placeholder {
            color: $mediumGray;
            font-family: 'NexaLight';
            font-size: 26px;
          }
          :-moz-placeholder {
            color: $mediumGray;
            font-family: 'NexaLight';
            font-size: 26px;
          }

          @media (max-width: 1366px) {
            height: 65px;
          }
        }

        .search-button {
          position: absolute;
          top: -5px;
          right: 5px;

          display: block;
          width: 90px;
          height: 90px;
          border: none;

          background-image: url($img-path + 'icon_search.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: rgba(255,255,255,0);

          @media (max-width: 1366px) {
            width: 75px;
            height: 75px;
          }
        }
      }

      &__buttons {
        position: relative;
        margin: 0 auto;
        width: 960px;
        margin-top: 5px;

        @media (max-width: 1366px) {
          width: 850px;
        }
      }
    }
  }

  .scroll-down {
    display: block;
    width: 100%;
    position: absolute;
    // left: 50%;
    bottom: 15px;
    margin-left: -15px;
    z-index: 3;

    p{
      text-align: center;
      font-size: 14px;
      color: #fff;
      font-family: 'NexaLight';
      line-height: 20px;
    }

    .icon_mouse {
      background: url($img-path + 'icon_mouse.png') center no-repeat;

      display: block;
      width: 100%;
      height: 52px;
      margin-bottom: 5px;
    }

    .icon_arrow {
      background: url($img-path + 'icon_mouse_arrow.png') center no-repeat;
      display: block;
      width: 16px;
      height: 10px;
      margin: 0 auto;
      animation-fill-mode: forwards;

      &.arrow1 { animation: arrow 1s ease-in-out infinite; }
      &.arrow2 { animation: arrow 1s ease-in-out infinite .3s; }

      @keyframes arrow {
        0% { opacity: 1; }
        50% { opacity: .5; }
        100% { opacity: 1; }
      }
    }
  }
}