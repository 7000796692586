.navbar {
  position: relative;
  width: 100%;
  height: 150px;
  background-color: $superLightGray;
  z-index: 7;

  .logo {
    margin: 0px 0;
  }
  @media only screen and (max-width: 868px) {
    .logo{
      width: 120px;
      height: 35px;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 59px 10px;
    }
  }
  .sandwich{
    display: none;
    margin: 59px 10px;
    width: 30px;
    height:30px;
    transition: 0.3s;
    & .bar{
      width:100%;
      height: 3px;
      background-color:#666;
      margin:5px 0;
      transition: 0.3s all;
    }

    &.sandwich--open div:nth-child(1){
      -webkit-transform: rotate(-45deg) translate(-11px, 0px);
        transform: rotate(-45deg) translate(-11px, 0px);
        background-color: #f4a14e;
    }
    &.sandwich--open div:nth-child(2){
      opacity: 0;
    }
    &.sandwich--open div:nth-child(3){
      -webkit-transform: rotate(45deg) translate(-12px, 0px);
        transform: rotate(45deg) translate(-11px, 0px);
        background-color:#f4a14e;
    }
  }
  @media only screen and (max-width: 1023px) {
    .sandwich{
      display:block;
    }
  }


  .menu {
    position: relative;
    overflow: visible;
    width: auto;
    height: 100%;
    z-index:999;

    &__item {
      position: relative;
      float: left;
      display: block;
      height: 100%;
      padding-top: 40px;
      // transition: all .3s ease-in-out;
      width: 100px;
      background-color: $superLightGray;

      &.active {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, .3), $superLightGray);
        background-color: rgba(0, 0, 0, .20);

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 20px;

          background-image: url($img-path + 'icon_arrow_orange.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 29px;
          background-color: #f1f1f1;

          position: absolute;
          bottom: -1px;
          left: 0;
          z-index: 3;
        }
      }

      &.btn {
        background-color: red;
      }

      a {
        text-transform: uppercase;
        color: $darkGray;
        font-size: 11px;
        font-family: 'NexaBold';
        display: block;
        line-height: 150px;
        background-position: center top;
        background-repeat: no-repeat;
        position: relative;
        text-align: center;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -15px;
          left: 50%;
          transform: translateX(-50%) translateZ(0);
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      @media only screen and (max-width: 1023px) {
        a{
          font-size: 16px !important;
          text-align: left;
          padding-left: 10px;
          line-height: 0;
          &:before{
            width: 0 !important;
            height: 0 !important;
          }
        }
      }

      &.services a:before { background-image: url($img-path + '/menu/icon_services.png'); width: 57px; height: 70px; }
      &.government a:before { background-image: url($img-path + '/menu/icon_government.png'); width: 50px; height: 70px; }
      &.employment a:before { background-image: url($img-path + '/menu/icon_employment.png'); width: 61px; height: 70px; }
      &.education a:before { background-image: url($img-path + '/menu/icon_education.png'); width: 38px; height: 70px; }
      &.health a:before { background-image: url($img-path + '/menu/icon_health.png'); width: 58px; height: 70px; }
      &.social a:before { background-image: url($img-path + '/menu/icon_social.png'); width: 70px; height: 70px; }
      &.city a:before { background-image: url($img-path + '/menu/icon_city.png'); width: 52px; height: 70px; }
      &.visite a:before { background-image: url($img-path + '/menu/icon_visite.png'); width: 47px; height: 70px; }
      &.culture a:before { background-image: url($img-path + '/menu/icon_culture.png'); width: 61px; height: 70px; }
      &.transparency a:before { background-image: url($img-path + '/menu/icon_transparency.png'); width: 61px; height: 70px; }

      @media (min-width: 1228px) and (max-width: 1350px) {
        width: 100px;
      }

      @media (max-width: 1228px) {
        width: 80px;

        a {
          font-size: 9px;

          &:before {
            max-width: 50px;
          }
        }
      }
      @media only screen and (max-width: 1023px) {
          width: 100%;
      }
    }
    @media only screen and (max-width: 1023px) {
      &__item{
        padding: 20px 10px;
        height: auto;
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    .menu {
      display: none;
      &.menu--open{
        display:block;
        width: 100%;
        position: absolute;
        top: 150px;
        height: 100vh;
        // margin-left: -8px;
        left: 0;
      }
    }
  }

  .menu-hover {
    display: none;
    width: 100%;
    height: 250px;
    background-color: $superLightGray;
    border-top: 1px solid $lightGrayText;

    position: absolute;
    top: 150px;
    left: 0;
    z-index: 2;

    &.menu-hover--open{
      display:block;
    }

    .list {
      display: none;
      overflow: hidden;

      position: absolute;
      top: 50px;

      .col {
        float: left;
        width: 150px;

        &.col-small{
          width: 100px;
        }

        .list__item {
          display: block;
          font-family: 'Arial';
          color: $darkGrayText;
          font-size: 13px;
          padding: 10px 0;
          line-height: 18px;

          &:hover { text-decoration: underline; }
        }
        @media only screen and (max-width: 1023px) {
            .list__item{
              padding: 5px 10px;
              text-align: left !important;
            }
        }



        @media (min-width: 1200px) and (max-width: 1350px) {
          width: 100px;
        }

        @media (max-width: 1200px) {
          width: 80px;
        }
      }
      @media only screen and (max-width: 1023px) {
          .col{
            width:100%;
          }
      }

      .col__btn{
        float: left;
        width: 200px;
        @media only screen and (max-width: 1023px) {
            width: 100%;
        }


        .list__item__btn {
          display: block;
          font-family: 'Arial';
          color: $darkGrayText;
          font-size: 13px;
          padding: 8px 10px;
          margin: 5px 10px;
          border: 1px solid $darkGrayText;
          line-height: 18px;
          text-align: center;

          &:hover { text-decoration: underline; }

          @media only screen and (max-width: 1023px) {
              border: none;
              text-align: left;
              padding: 5px 10px;
              margin: 0px;
          }

        }

        .list__item {
          display: block;
          font-family: 'Arial';
          color: $darkGrayText;
          font-size: 13px;
          padding: 10px 0;
          line-height: 18px;
          text-align: center;
          &:hover { text-decoration: underline; }

          @media only screen and (max-width: 1023px) {
              border: none;
              text-align: left;
              padding: 5px 10px;
              margin: 0px;
          }

        }
      }

      &.pull-right {

        .list__item {
          text-align: right;
        }
      }
    }
    @media only screen and (max-width: 1023px) {
        .list{
          top: 10px;
        }
    }
    //
    .list[data-index='9']{
      .list__item {
        padding: 5px 0;
        width: 100px;
        @media only screen and (max-width: 1023px) {
          width:100%;
          padding: 5px 10px;
        }
      }
    }
  }

}
