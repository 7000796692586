@charset "UTF-8";
html {
  margin: 0;
  padding: 0;
  border: 0; }

body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline; }

article, aside, dialog, figure, footer, header, hgroup, nav, section {
  display: block; }

body {
  line-height: 1.5;
  background: white; }

table {
  border-collapse: separate;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  float: none !important; }

table, th, td {
  vertical-align: middle; }

blockquote before, blockquote after, q before, q after {
  content: ''; }

a img {
  border: none; }

b, i {
  display: none; }

@font-face {
  font-family: "ArmoniosoRegular";
  src: url("../fonts/Armonioso/ArmoniosoRegular.eot");
  src: url("../fonts/Armonioso/ArmoniosoRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/Armonioso/ArmoniosoRegular.woff") format("woff"), url("../fonts/Armonioso/ArmoniosoRegular.ttf") format("truetype"), url("../fonts/Armonioso/ArmoniosoRegular.svg#ArmoniosoRegular") format("svg"); }

@font-face {
  font-family: "NexaBold";
  src: url("../fonts/Nexa/NexaBold.eot");
  src: url("../fonts/Nexa/NexaBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Nexa/NexaBold.woff") format("woff"), url("../fonts/Nexa/NexaBold.ttf") format("truetype"), url("../fonts/Nexa/NexaBold.svg#NexaBold") format("svg"); }

@font-face {
  font-family: "NexaLight";
  src: url("../fonts/Nexa/NexaLight.eot");
  src: url("../fonts/Nexa/NexaLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Nexa/NexaLight.woff") format("woff"), url("../fonts/Nexa/NexaLight.ttf") format("truetype"), url("../fonts/Nexa/NexaLight.svg#NexaLight") format("svg"); }

@font-face {
  font-family: "NexaThin";
  src: url("../fonts/Nexa/NexaThin.eot");
  src: url("../fonts/Nexa/NexaThin.eot?#iefix") format("embedded-opentype"), url("../fonts/Nexa/NexaThin.woff") format("woff"), url("../fonts/Nexa/NexaThin.ttf") format("truetype"), url("../fonts/Nexa/NexaThin.svg#NexaThin") format("svg"); }

@font-face {
  font-family: "ClearfaceRegular";
  src: url("../fonts/Clearface/ClearfaceRegular.eot");
  src: url("../fonts/Clearface/ClearfaceRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/Clearface/ClearfaceRegular.woff") format("woff"), url("../fonts/Clearface/ClearfaceRegular.ttf") format("truetype"), url("../fonts/Clearface/ClearfaceRegular.svg#ClearfaceRegular") format("svg"); }

* {
  box-sizing: border-box; }

html, body {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  backface-visibility: hidden;
  line-height: normal; }
  html.tempMobile, body.tempMobile {
    min-width: auto;
    overflow-x: hidden; }
    html.tempMobile .swiper-button-next,
    html.tempMobile .swiper-button-prev, body.tempMobile .swiper-button-next,
    body.tempMobile .swiper-button-prev {
      opacity: 1 !important;
      background-color: rgba(0, 0, 0, 0.5) !important;
      padding: 0 20px; }
    html.tempMobile .noticias, body.tempMobile .noticias {
      padding: 32px; }
      html.tempMobile .noticias .section-label, body.tempMobile .noticias .section-label {
        width: 121px; }
        html.tempMobile .noticias .section-label .text, body.tempMobile .noticias .section-label .text {
          display: none; }
        html.tempMobile .noticias .section-label .button, body.tempMobile .noticias .section-label .button {
          display: block;
          width: 121px;
          margin-top: 20px; }
      html.tempMobile .noticias .container, body.tempMobile .noticias .container {
        padding: 0;
        width: 100%;
        padding-top: 30px; }
        html.tempMobile .noticias .container .news-list .template-link img, body.tempMobile .noticias .container .news-list .template-link img {
          width: 100%; }
        html.tempMobile .noticias .container .news-list .template-link .container-link .template-time, body.tempMobile .noticias .container .news-list .template-link .container-link .template-time {
          line-height: 34px; }
        html.tempMobile .noticias .container .news-list .template-link .container-link .template-title, body.tempMobile .noticias .container .news-list .template-link .container-link .template-title {
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical; }
        html.tempMobile .noticias .container .news-list .template-link .container-link .template-descripiton, body.tempMobile .noticias .container .news-list .template-link .container-link .template-descripiton {
          display: none; }
        html.tempMobile .noticias .container .news-list .borderBottom, body.tempMobile .noticias .container .news-list .borderBottom {
          display: block;
          width: 100%;
          height: 1px;
          background-color: #e5e5e5;
          margin-top: 29px;
          margin-bottom: 20px; }
        html.tempMobile .noticias .container .pagination, body.tempMobile .noticias .container .pagination {
          margin-top: 10px;
          padding: 0; }
          html.tempMobile .noticias .container .pagination a.prev, html.tempMobile .noticias .container .pagination a.next, html.tempMobile .noticias .container .pagination div a, body.tempMobile .noticias .container .pagination a.prev, body.tempMobile .noticias .container .pagination a.next, body.tempMobile .noticias .container .pagination div a {
            margin-right: 3.6px; }
            @media (min-width: 360px) {
              html.tempMobile .noticias .container .pagination a.prev, html.tempMobile .noticias .container .pagination a.next, html.tempMobile .noticias .container .pagination div a, body.tempMobile .noticias .container .pagination a.prev, body.tempMobile .noticias .container .pagination a.next, body.tempMobile .noticias .container .pagination div a {
                margin-right: 10.84px; } }
            @media (min-width: 375px) {
              html.tempMobile .noticias .container .pagination a.prev, html.tempMobile .noticias .container .pagination a.next, html.tempMobile .noticias .container .pagination div a, body.tempMobile .noticias .container .pagination a.prev, body.tempMobile .noticias .container .pagination a.next, body.tempMobile .noticias .container .pagination div a {
                margin-right: 14.2px; } }
            @media (min-width: 412px) {
              html.tempMobile .noticias .container .pagination a.prev, html.tempMobile .noticias .container .pagination a.next, html.tempMobile .noticias .container .pagination div a, body.tempMobile .noticias .container .pagination a.prev, body.tempMobile .noticias .container .pagination a.next, body.tempMobile .noticias .container .pagination div a {
                margin-right: 20.8px; } }
            @media (min-width: 414px) {
              html.tempMobile .noticias .container .pagination a.prev, html.tempMobile .noticias .container .pagination a.next, html.tempMobile .noticias .container .pagination div a, body.tempMobile .noticias .container .pagination a.prev, body.tempMobile .noticias .container .pagination a.next, body.tempMobile .noticias .container .pagination div a {
                margin-right: 21.2px; } }
          html.tempMobile .noticias .container .pagination a.last, body.tempMobile .noticias .container .pagination a.last {
            margin: 0; }
        html.tempMobile .noticias .container .side, body.tempMobile .noticias .container .side {
          width: 100% !important; }
          html.tempMobile .noticias .container .side .left, body.tempMobile .noticias .container .side .left {
            float: none; }
        html.tempMobile .noticias .container p.internal-link a, body.tempMobile .noticias .container p.internal-link a {
          line-height: 14px; }

body.hidden-scroll {
  overflow: hidden; }

.clearfix:after {
  content: '';
  display: table;
  clear: both; }

a {
  text-decoration: none; }

.left {
  float: left; }

.right {
  float: right; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style: none;
    margin: 0; }

input, select, textarea {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none; }

.app .wrapper {
  height: 100%;
  min-height: 100%;
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2%;
  overflow: hidden; }
  .app .wrapper.small {
    max-width: 1200px; }
  .app .wrapper.no-hidden {
    overflow: visible; }

.app .logo {
  background-image: url("../images/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  width: 225px;
  height: 149px; }
  .app .logo.white {
    width: 100%;
    height: 214px;
    background: url("../images/logo-white.png") center no-repeat;
    background-size: contain; }
  .app .logo.logo-digital {
    display: block;
    background: url("../images/logo-digital.png") center no-repeat;
    width: 316px;
    height: 38px; }

.app .button {
  font-family: 'NexaLight';
  font-size: 14px;
  color: #77787F;
  text-transform: uppercase;
  border: 1px solid #77787F;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  overflow: hidden; }
  .app .button.bold {
    font-family: 'NexaBold'; }
  .app .button.small {
    padding: 0 15px; }
  .app .button.white {
    border-color: white;
    color: white; }
    .app .button.white .icon.access {
      background: url("../images/icon_access_white.png") center no-repeat; }
  .app .button.orange {
    display: inline-block;
    height: 40px;
    padding: 0 15px;
    text-align: center;
    line-height: 40px;
    background-color: #F4A14E;
    color: white;
    margin-right: 8px;
    font-family: 'NexaBold';
    font-size: 16px;
    border: 1px solid #F4A14E;
    text-transform: initial;
    transition: all .2s ease-in-out; }
    .app .button.orange:last-child {
      margin-right: 0; }
    .app .button.orange:hover {
      opacity: .9; }
  .app .button .value {
    display: inline-block; }
  .app .button .icon {
    display: inline-block;
    vertical-align: middle; }
    .app .button .icon.access {
      background: url("../images/icon_access.png") center no-repeat;
      width: 13px;
      height: 13px;
      margin-left: 11px; }
    .app .button .icon.back {
      background: url("../images/icon_back.png") center no-repeat;
      width: 18px;
      height: 16px;
      margin: 11px 11px 11px 0; }

.app .section-label {
  position: relative;
  z-index: 1;
  height: 52px; }
  .app .section-label .text {
    position: relative;
    display: inline-block;
    background-color: #F4A14E;
    padding: 0 20px;
    line-height: 42px;
    height: 40px;
    font-family: 'NexaBold';
    color: white;
    font-size: 13px;
    text-transform: uppercase;
    z-index: 3;
    margin: 6px 0; }
    .app .section-label .text:before {
      content: '';
      display: block;
      width: 37px;
      height: 52px;
      background: url("../images/big_arrow.png") center no-repeat;
      background-size: contain;
      position: absolute;
      top: -6px;
      left: 5px;
      z-index: -1; }
    .app .section-label .text:after {
      content: '';
      display: block;
      width: 69px;
      height: 30px;
      background: url("../images/section_arrows.png") center no-repeat;
      position: absolute;
      top: 50%;
      right: -79px;
      margin-top: -15px; }
    @media only screen and (max-width: 868px) {
      .app .section-label .text:after {
        display: none; } }

@media only screen and (max-width: 500px) {
  .app .section-label > a {
    display: none; } }

.app .highlight-list .slider {
  overflow: hidden;
  height: 400px; }

.app .slider-arrows .arrow {
  background: url("../images/slider_arrow.png") center no-repeat;
  background-size: 10px 18px;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 1px solid white; }
  .app .slider-arrows .arrow.next {
    transform: rotate(180deg);
    margin-left: 5px; }
  .app .slider-arrows .arrow.swiper-button-disabled {
    opacity: .5;
    cursor: default; }

.app .popup-video {
  display: none;
  width: 100%;
  min-width: 1024px;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3; }
  .app .popup-video .cover {
    width: 100%;
    height: 100vh;
    opacity: .9;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #CE3E26; }
  .app .popup-video .modal {
    width: 100%;
    height: 100%; }
    .app .popup-video .modal__header {
      margin-top: 80px;
      overflow: hidden; }
      .app .popup-video .modal__header .title {
        font-family: 'NexaLight';
        font-size: 30px;
        color: white; }
    .app .popup-video .modal .youtube-player {
      position: relative;
      width: 100%;
      height: 75%;
      margin-top: 20px; }

.app .popup-vimeo {
  display: none;
  width: 100%;
  min-width: 1024px;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  text-align: center; }
  .app .popup-vimeo .cover {
    width: 100%;
    height: 100vh;
    opacity: .45;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #CE3E26; }
  .app .popup-vimeo .modal {
    width: 900px;
    height: 100%;
    margin: 0 auto; }
    .app .popup-vimeo .modal__header {
      margin-top: 80px;
      overflow: hidden; }
      .app .popup-vimeo .modal__header .title {
        font-family: 'NexaLight';
        font-size: 30px;
        color: white; }
    .app .popup-vimeo .modal .youtube-player {
      position: relative;
      width: 100%;
      height: 65%;
      margin-top: 20px; }
    .app .popup-vimeo .modal a.button {
      background-color: #fe5405;
      color: #fff;
      border: none; }
    .app .popup-vimeo .modal a.saiba-mais {
      position: absolute;
      bottom: -30px;
      font-family: 'NexaLight';
      font-size: 18px;
      text-align: left;
      width: 100%;
      padding: 7.5px;
      background: #fff;
      color: #333; }

.app .search-bar {
  width: 100%;
  height: 90px;
  background: #aaa;
  border-top: 5px solid white;
  border-bottom: 5px solid white;
  background-color: #F4F4F4; }
  .app .search-bar .text {
    display: block;
    width: 100%;
    height: 80px;
    background-color: transparent;
    padding-right: 115px;
    color: #727279;
    font-family: 'NexaLight';
    font-size: 26px; }
    .app .search-bar .text ::-webkit-input-placeholder {
      color: #727279;
      font-family: 'NexaLight';
      font-size: 26px; }
    .app .search-bar .text ::-moz-placeholder {
      color: #727279;
      font-family: 'NexaLight';
      font-size: 26px; }
    .app .search-bar .text :-ms-input-placeholder {
      color: #727279;
      font-family: 'NexaLight';
      font-size: 26px; }
    .app .search-bar .text :-moz-placeholder {
      color: #727279;
      font-family: 'NexaLight';
      font-size: 26px; }
  .app .search-bar .search-button {
    position: absolute;
    top: -5px;
    right: 40px;
    display: block;
    width: 90px;
    height: 90px;
    border: none;
    background-image: url("../images/icon_search.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: rgba(255, 255, 255, 0); }

.app .link {
  color: #F4A14E;
  font-style: italic; }

.form .text {
  display: block;
  width: 100%;
  padding: 12.5px 12.5px 12.5px 30px;
  background-color: #F4F4F4;
  padding-right: 115px;
  color: #727279;
  font-family: 'NexaLight';
  font-size: 16px;
  margin: 5px 0; }
  .form .text ::-webkit-input-placeholder {
    color: #727279;
    font-family: 'NexaLight';
    font-size: 16px; }
  .form .text ::-moz-placeholder {
    color: #727279;
    font-family: 'NexaLight';
    font-size: 16px; }
  .form .text :-ms-input-placeholder {
    color: #727279;
    font-family: 'NexaLight';
    font-size: 16px; }
  .form .text :-moz-placeholder {
    color: #727279;
    font-family: 'NexaLight';
    font-size: 16px; }

.form select {
  width: 100%;
  font-family: 'NexaLight';
  font-size: 14px;
  color: #727279;
  padding: 12px;
  background-color: #F4F4F4;
  padding-left: 40px;
  -webkit-appearance: none;
  /* Remove estilo padrão do Chrome */
  -moz-appearance: none;
  /* Remove estilo padrão do FireFox */
  appearance: none;
  /* Remove estilo padrão do FireFox*/
  background: url("../images/icon.select.jpg") no-repeat #eeeeee;
  /* Imagem de fundo (Seta) */
  background-position: 100% center;
  /*Posição da imagem do background*/ }
  .form select option {
    width: 100%;
    font-family: 'NexaLight';
    color: #727279;
    font-size: 16px;
    padding: 12.5px; }

.form .option {
  position: relative;
  display: inline-block;
  padding: 0px 10px; }
  .form .option label {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid #c8c8cc;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0; }
    .form .option label:after {
      content: '';
      width: 7px;
      height: 5px;
      position: absolute;
      top: 1px;
      left: 1px;
      border: 2px solid #727279;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg); }
    .form .option label:hover::after {
      opacity: 0.5; }
  .form .option input[type=checkbox] {
    visibility: hidden; }
    .form .option input[type=checkbox]:checked + label:after {
      opacity: 1; }

.form span {
  display: inline-block;
  font-family: 'NexaLight';
  color: #727279;
  font-size: 13px; }
  .form span.status {
    display: block;
    font-family: 'NexaBold';
    font-size: 16px; }
    .form span.status.success {
      color: #6BACA3; }
    .form span.status.error {
      color: #CE3E26; }
    .form span.status.warning {
      color: #F4A14E; }

.form p {
  margin-bottom: 10px !important; }

.form .switch-input {
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */ }
  .form .switch-input .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px; }
  .form .switch-input .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .form .switch-input .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s; }
  .form .switch-input .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s; }
  .form .switch-input input:checked + .slider {
    background-color: #F4A14E; }
  .form .switch-input input:focus + .slider {
    box-shadow: 0 0 1px #2196F3; }
  .form .switch-input input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px); }
  .form .switch-input .slider.round {
    border-radius: 34px; }
  .form .switch-input .slider.round:before {
    border-radius: 50%; }

/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 * 
 * http://www.idangero.us/swiper/
 * 
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: March 10, 2017
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide, .swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform,height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform,height; }

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%; }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: .2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate(0, -50%);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-cube-shadow, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-coverflow .swiper-wrapper, .swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px; }

.swiper-container-cube, .swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide, .swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide, .swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active, .swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right, .swiper-container-cube .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right, .swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-next + .swiper-slide, .swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center; }

.swiper-zoom-container > canvas, .swiper-zoom-container > img, .swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; }

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

.navbar {
  position: relative;
  width: 100%;
  height: 150px;
  background-color: #F4F4F4;
  z-index: 7; }
  .navbar .logo {
    margin: 0px 0; }
  @media only screen and (max-width: 868px) {
    .navbar .logo {
      width: 120px;
      height: 35px;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 59px 10px; } }
  .navbar .sandwich {
    display: none;
    margin: 59px 10px;
    width: 30px;
    height: 30px;
    transition: 0.3s; }
    .navbar .sandwich .bar {
      width: 100%;
      height: 3px;
      background-color: #666;
      margin: 5px 0;
      transition: 0.3s all; }
    .navbar .sandwich.sandwich--open div:nth-child(1) {
      -webkit-transform: rotate(-45deg) translate(-11px, 0px);
      transform: rotate(-45deg) translate(-11px, 0px);
      background-color: #f4a14e; }
    .navbar .sandwich.sandwich--open div:nth-child(2) {
      opacity: 0; }
    .navbar .sandwich.sandwich--open div:nth-child(3) {
      -webkit-transform: rotate(45deg) translate(-12px, 0px);
      transform: rotate(45deg) translate(-11px, 0px);
      background-color: #f4a14e; }
  @media only screen and (max-width: 1023px) {
    .navbar .sandwich {
      display: block; } }
  .navbar .menu {
    position: relative;
    overflow: visible;
    width: auto;
    height: 100%;
    z-index: 999; }
    .navbar .menu__item {
      position: relative;
      float: left;
      display: block;
      height: 100%;
      padding-top: 40px;
      width: 100px;
      background-color: #F4F4F4; }
      .navbar .menu__item.active {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), #F4F4F4);
        background-color: rgba(0, 0, 0, 0.2); }
        .navbar .menu__item.active:after {
          content: '';
          display: block;
          width: 100%;
          height: 20px;
          background-image: url("../images/icon_arrow_orange.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 29px;
          background-color: #f1f1f1;
          position: absolute;
          bottom: -1px;
          left: 0;
          z-index: 3; }
      .navbar .menu__item.btn {
        background-color: red; }
      .navbar .menu__item a {
        text-transform: uppercase;
        color: #5A5C65;
        font-size: 11px;
        font-family: 'NexaBold';
        display: block;
        line-height: 150px;
        background-position: center top;
        background-repeat: no-repeat;
        position: relative;
        text-align: center; }
        .navbar .menu__item a:before {
          content: '';
          display: block;
          position: absolute;
          top: -15px;
          left: 50%;
          transform: translateX(-50%) translateZ(0);
          background-position: center;
          background-repeat: no-repeat; }
      @media only screen and (max-width: 1023px) {
        .navbar .menu__item a {
          font-size: 16px !important;
          text-align: left;
          padding-left: 10px;
          line-height: 0; }
          .navbar .menu__item a:before {
            width: 0 !important;
            height: 0 !important; } }
      .navbar .menu__item.services a:before {
        background-image: url("../images//menu/icon_services.png");
        width: 57px;
        height: 70px; }
      .navbar .menu__item.government a:before {
        background-image: url("../images//menu/icon_government.png");
        width: 50px;
        height: 70px; }
      .navbar .menu__item.employment a:before {
        background-image: url("../images//menu/icon_employment.png");
        width: 61px;
        height: 70px; }
      .navbar .menu__item.education a:before {
        background-image: url("../images//menu/icon_education.png");
        width: 38px;
        height: 70px; }
      .navbar .menu__item.health a:before {
        background-image: url("../images//menu/icon_health.png");
        width: 58px;
        height: 70px; }
      .navbar .menu__item.social a:before {
        background-image: url("../images//menu/icon_social.png");
        width: 70px;
        height: 70px; }
      .navbar .menu__item.city a:before {
        background-image: url("../images//menu/icon_city.png");
        width: 52px;
        height: 70px; }
      .navbar .menu__item.visite a:before {
        background-image: url("../images//menu/icon_visite.png");
        width: 47px;
        height: 70px; }
      .navbar .menu__item.culture a:before {
        background-image: url("../images//menu/icon_culture.png");
        width: 61px;
        height: 70px; }
      .navbar .menu__item.transparency a:before {
        background-image: url("../images//menu/icon_transparency.png");
        width: 61px;
        height: 70px; }
      @media (min-width: 1228px) and (max-width: 1350px) {
        .navbar .menu__item {
          width: 100px; } }
      @media (max-width: 1228px) {
        .navbar .menu__item {
          width: 80px; }
          .navbar .menu__item a {
            font-size: 9px; }
            .navbar .menu__item a:before {
              max-width: 50px; } }
      @media only screen and (max-width: 1023px) {
        .navbar .menu__item {
          width: 100%; } }
    @media only screen and (max-width: 1023px) {
      .navbar .menu__item {
        padding: 20px 10px;
        height: auto; } }
  @media only screen and (max-width: 1023px) {
    .navbar .menu {
      display: none; }
      .navbar .menu.menu--open {
        display: block;
        width: 100%;
        position: absolute;
        top: 150px;
        height: 100vh;
        left: 0; } }
  .navbar .menu-hover {
    display: none;
    width: 100%;
    height: 250px;
    background-color: #F4F4F4;
    border-top: 1px solid #CCCCCC;
    position: absolute;
    top: 150px;
    left: 0;
    z-index: 2; }
    .navbar .menu-hover.menu-hover--open {
      display: block; }
    .navbar .menu-hover .list {
      display: none;
      overflow: hidden;
      position: absolute;
      top: 50px; }
      .navbar .menu-hover .list .col {
        float: left;
        width: 150px; }
        .navbar .menu-hover .list .col.col-small {
          width: 100px; }
        .navbar .menu-hover .list .col .list__item {
          display: block;
          font-family: 'Arial';
          color: #666666;
          font-size: 13px;
          padding: 10px 0;
          line-height: 18px; }
          .navbar .menu-hover .list .col .list__item:hover {
            text-decoration: underline; }
        @media only screen and (max-width: 1023px) {
          .navbar .menu-hover .list .col .list__item {
            padding: 5px 10px;
            text-align: left !important; } }
        @media (min-width: 1200px) and (max-width: 1350px) {
          .navbar .menu-hover .list .col {
            width: 100px; } }
        @media (max-width: 1200px) {
          .navbar .menu-hover .list .col {
            width: 80px; } }
      @media only screen and (max-width: 1023px) {
        .navbar .menu-hover .list .col {
          width: 100%; } }
      .navbar .menu-hover .list .col__btn {
        float: left;
        width: 200px; }
        @media only screen and (max-width: 1023px) {
          .navbar .menu-hover .list .col__btn {
            width: 100%; } }
        .navbar .menu-hover .list .col__btn .list__item__btn {
          display: block;
          font-family: 'Arial';
          color: #666666;
          font-size: 13px;
          padding: 8px 10px;
          margin: 5px 10px;
          border: 1px solid #666666;
          line-height: 18px;
          text-align: center; }
          .navbar .menu-hover .list .col__btn .list__item__btn:hover {
            text-decoration: underline; }
          @media only screen and (max-width: 1023px) {
            .navbar .menu-hover .list .col__btn .list__item__btn {
              border: none;
              text-align: left;
              padding: 5px 10px;
              margin: 0px; } }
        .navbar .menu-hover .list .col__btn .list__item {
          display: block;
          font-family: 'Arial';
          color: #666666;
          font-size: 13px;
          padding: 10px 0;
          line-height: 18px;
          text-align: center; }
          .navbar .menu-hover .list .col__btn .list__item:hover {
            text-decoration: underline; }
          @media only screen and (max-width: 1023px) {
            .navbar .menu-hover .list .col__btn .list__item {
              border: none;
              text-align: left;
              padding: 5px 10px;
              margin: 0px; } }
      .navbar .menu-hover .list.pull-right .list__item {
        text-align: right; }
    @media only screen and (max-width: 1023px) {
      .navbar .menu-hover .list {
        top: 10px; } }
    .navbar .menu-hover .list[data-index='9'] .list__item {
      padding: 5px 0;
      width: 100px; }
      @media only screen and (max-width: 1023px) {
        .navbar .menu-hover .list[data-index='9'] .list__item {
          width: 100%;
          padding: 5px 10px; } }

.header {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .header .banner {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url("../images//banners/banner1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    .header .banner .bg_video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      object-fit: cover; }
    .header .banner .search {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) translateZ(0);
      margin-top: -75px;
      z-index: 2; }
      .header .banner .search__text {
        position: relative;
        margin: 0 auto;
        width: 960px; }
        @media (max-width: 1366px) {
          .header .banner .search__text {
            width: 850px; } }
        .header .banner .search__text .text {
          display: block;
          width: 100%;
          height: 80px;
          background-color: white;
          padding: 0 20px;
          color: #727279;
          font-family: 'NexaLight';
          font-size: 26px; }
          .header .banner .search__text .text ::-webkit-input-placeholder {
            color: #727279;
            font-family: 'NexaLight';
            font-size: 26px; }
          .header .banner .search__text .text ::-moz-placeholder {
            color: #727279;
            font-family: 'NexaLight';
            font-size: 26px; }
          .header .banner .search__text .text :-ms-input-placeholder {
            color: #727279;
            font-family: 'NexaLight';
            font-size: 26px; }
          .header .banner .search__text .text :-moz-placeholder {
            color: #727279;
            font-family: 'NexaLight';
            font-size: 26px; }
          @media (max-width: 1366px) {
            .header .banner .search__text .text {
              height: 65px; } }
        .header .banner .search__text .search-button {
          position: absolute;
          top: -5px;
          right: 5px;
          display: block;
          width: 90px;
          height: 90px;
          border: none;
          background-image: url("../images/icon_search.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: rgba(255, 255, 255, 0); }
          @media (max-width: 1366px) {
            .header .banner .search__text .search-button {
              width: 75px;
              height: 75px; } }
      .header .banner .search__buttons {
        position: relative;
        margin: 0 auto;
        width: 960px;
        margin-top: 5px; }
        @media (max-width: 1366px) {
          .header .banner .search__buttons {
            width: 850px; } }
  .header .scroll-down {
    display: block;
    width: 30px;
    position: absolute;
    left: 50%;
    bottom: 40px;
    margin-left: -15px;
    z-index: 1; }
    .header .scroll-down .icon_mouse {
      background: url("../images/icon_mouse.png") center no-repeat;
      display: block;
      width: 100%;
      height: 52px;
      margin-bottom: 5px; }
    .header .scroll-down .icon_arrow {
      background: url("../images/icon_mouse_arrow.png") center no-repeat;
      display: block;
      width: 16px;
      height: 10px;
      margin: 0 auto;
      animation-fill-mode: forwards; }
      .header .scroll-down .icon_arrow.arrow1 {
        animation: arrow 1s ease-in-out infinite; }
      .header .scroll-down .icon_arrow.arrow2 {
        animation: arrow 1s ease-in-out infinite .3s; }

@keyframes arrow {
  0% {
    opacity: 1; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

.full-slider {
  position: relative;
  width: 100%;
  padding: 20px 0; }
  .full-slider .slider {
    position: relative; }
    .full-slider .slider .swiper-container {
      width: 100%;
      height: 200px; }
    .full-slider .slider__item {
      width: 100%;
      height: 200px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer; }
    .full-slider .slider .description {
      height: 100px;
      padding: 0 40px;
      background-color: #5A5C65;
      position: absolute;
      right: 9%;
      bottom: 15px;
      z-index: 1;
      transition: all .3s ease-in-out; }
      .full-slider .slider .description:before {
        content: '';
        display: block;
        width: 92px;
        height: 130px;
        background: url("../images/big_arrow.png") center no-repeat;
        position: absolute;
        top: -15px;
        left: 15px;
        z-index: 2; }
      .full-slider .slider .description .text {
        position: relative;
        display: block;
        line-height: 107px;
        text-transform: uppercase;
        font-family: 'NexaBold';
        color: white;
        font-size: 20px;
        text-align: center;
        z-index: 3; }
    .full-slider .slider .slider-arrows {
      position: absolute;
      top: 30px;
      right: 9%;
      height: 40px;
      z-index: 2; }
      .full-slider .slider .slider-arrows .arrow {
        background-size: 10px 18px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 1px solid white; }
        .full-slider .slider .slider-arrows .arrow.next {
          background: url("../images/slider_arrow-right.png") center no-repeat;
          transform: rotate(0deg);
          margin-left: 5px; }
        .full-slider .slider .slider-arrows .arrow.prev {
          background: url("../images/slider_arrow-left.png") center no-repeat; }

.updates {
  position: relative;
  background-color: #F9F9F9;
  padding: 70px 0 0; }
  .updates .highlights {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-top: 65px;
    padding-top: 10px; }
    .updates .highlights .side {
      position: relative;
      width: 50%;
      min-height: 400px; }
      .updates .highlights .side.left {
        width: 46%;
        padding-left: 20px; }
        .updates .highlights .side.left:before {
          content: '';
          display: block;
          width: 6px;
          height: 150px;
          background-color: #CE3E26;
          position: absolute;
          top: 0;
          left: 0; }
      .updates .highlights .side .title {
        font-family: 'NexaBold';
        font-size: 13px;
        color: #CE3E26;
        text-transform: uppercase;
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical; }
        .updates .highlights .side .title .updated-time {
          font-family: 'Arial';
          font-size: 9px;
          color: #ACACB2;
          text-transform: initial;
          margin-left: 5px; }
      .updates .highlights .side .big-title {
        font-family: 'NexaThin';
        font-size: 60px;
        color: #666666;
        margin: 40px 0 0;
        line-height: 60px; }
        .updates .highlights .side .big-title .bold {
          font-family: 'NexaBold'; }
        @media (min-width: 1200px) and (max-width: 1400px) {
          .updates .highlights .side .big-title {
            font-size: 50px;
            line-height: 50px; } }
        @media (max-width: 1200px) {
          .updates .highlights .side .big-title {
            font-size: 40px;
            line-height: 40px; } }
      .updates .highlights .side .description {
        font-family: 'Arial';
        font-size: 13px;
        margin: 20px 0 0;
        color: #666666;
        line-height: 24px; }
      .updates .highlights .side .button {
        font-family: 'NexaLight';
        font-size: 14px;
        color: #666666;
        border: 1px solid #ACACB2;
        display: inline-block;
        height: 40px;
        padding: 0 25px;
        line-height: 40px;
        text-transform: uppercase;
        margin-top: 45px; }
      .updates .highlights .side .block-image {
        display: block;
        width: 100%;
        height: 400px; }
      .updates .highlights .side .cover-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 230px;
        height: 100%;
        background-color: rgba(90, 92, 101, 0.95);
        padding: 40px 25px 40px 40px;
        z-index: 2; }
        .updates .highlights .side .cover-text .content {
          position: absolute;
          top: 40px;
          left: 40px;
          z-index: 3; }
          .updates .highlights .side .cover-text .content .section-label .wrapper {
            padding: 0; }
          .updates .highlights .side .cover-text .content .section-label .text {
            min-width: 218px;
            width: 100%;
            display: inline-block;
            white-space: nowrap;
            color: white !important; }
          .updates .highlights .side .cover-text .content .section-label a {
            color: white !important; }
          .updates .highlights .side .cover-text .content .title {
            display: block;
            max-width: 180px;
            color: white;
            font-family: 'NexaBold';
            font-size: 20px;
            text-transform: initial;
            margin-top: 25px;
            min-height: 100px; }
          .updates .highlights .side .cover-text .content .slider-arrows {
            position: relative;
            height: 40px;
            margin-top: 15px; }
            .updates .highlights .side .cover-text .content .slider-arrows .arrow {
              background: url("../images/slider_arrow.png") center no-repeat;
              background-size: 10px 18px;
              display: inline-block;
              width: 40px;
              height: 40px;
              border: 1px solid white; }
              .updates .highlights .side .cover-text .content .slider-arrows .arrow.next {
                transform: rotate(180deg);
                margin-left: 5px; }
          .updates .highlights .side .cover-text .content .button {
            font-family: 'NexaLight';
            font-size: 14px;
            color: #CCCCCC;
            border: 1px solid #B8B8B9;
            height: 40px;
            line-height: 40px;
            text-transform: uppercase;
            padding: 0 30px;
            text-align: center; }

.multimedia {
  position: relative;
  background-color: #F9F9F9;
  padding: 150px 0 30px; }
  .multimedia .side.left {
    width: 43%; }
  .multimedia .side.right {
    width: 50%; }
  .multimedia .side .gallery-photos {
    width: 100%;
    overflow: hidden;
    margin-top: 70px; }
    .multimedia .side .gallery-photos__item {
      display: block;
      float: left;
      width: 48%;
      height: 200px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 2%;
      margin-bottom: 2%; }
      .multimedia .side .gallery-photos__item:nth-child(2) {
        margin-right: 0; }
      .multimedia .side .gallery-photos__item:nth-child(3) {
        margin-bottom: 0; }
      .multimedia .side .gallery-photos__item:nth-child(4) {
        margin-right: 0;
        margin-bottom: 0; }
      @media (max-width: 1180px) {
        .multimedia .side .gallery-photos__item {
          height: 180px; } }
  .multimedia .side .youtube-list {
    width: 100%;
    margin-top: 70px; }
    .multimedia .side .youtube-list .line {
      overflow: hidden;
      width: 100%; }
      .multimedia .side .youtube-list .line .youtube-list__item {
        position: relative;
        float: left;
        display: block;
        width: 23.6%;
        height: 95px;
        margin-right: 1.8%;
        margin-top: 1.8%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; }
        .multimedia .side .youtube-list .line .youtube-list__item:nth-child(4n) {
          margin-right: 0; }
        .multimedia .side .youtube-list .line .youtube-list__item .icon-play {
          display: block;
          width: 52px;
          height: 52px;
          border-radius: 50%;
          border: 3px solid white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) translateZ(0);
          opacity: .5;
          background-color: rgba(255, 255, 255, 0.2);
          background-image: url("../images/icon_play.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 13px 15px;
          transition: all .3s ease-in-out; }
      .multimedia .side .youtube-list .line.big .youtube-list__item {
        width: 49.1%;
        height: 200px;
        margin-top: 0; }
        .multimedia .side .youtube-list .line.big .youtube-list__item:last-child {
          margin-right: 0; }
        .multimedia .side .youtube-list .line.big .youtube-list__item .icon-play {
          width: 107px;
          height: 107px;
          background-size: auto; }
  .multimedia .side .button {
    margin-top: 25px; }

.social-media {
  position: relative;
  min-height: 0px !important;
  height: 0px; }
  .social-media .section-label {
    margin-top: 70px;
    margin-bottom: 70px; }
  .social-media .resume {
    overflow: hidden;
    width: 100%; }
    .social-media .resume .col {
      float: left;
      width: 25%;
      min-height: 690px;
      margin-right: 8%; }
      .social-media .resume .col:last-child {
        margin-right: 0;
        width: 34%; }
      .social-media .resume .col .social-resume .head {
        width: 100%;
        height: 160px;
        background-color: #F8F8F8;
        padding: 0 12.8%; }
      .social-media .resume .col .social-resume .interations {
        background-color: white;
        padding: 0 14%; }
      .social-media .resume .col .social-resume .title {
        font-family: 'NexaBold';
        font-size: 22px;
        color: #5A5C65; }
        .social-media .resume .col .social-resume .title.pelotas-conectada {
          background: url("../images/pelotas-conectada.png") center left no-repeat;
          padding: 60px 0;
          padding-left: 130px; }
        .social-media .resume .col .social-resume .title.heart {
          background: url("../images/heart.png") center left no-repeat;
          padding: 30px 0;
          padding-left: 73px; }
        .social-media .resume .col .social-resume .title .description {
          display: block;
          font-family: 'Arial';
          font-size: 12px;
          color: #5A5C65; }
      .social-media .resume .col .social-resume .social-list {
        overflow: hidden; }
        .social-media .resume .col .social-resume .social-list__item {
          width: 33.33%;
          height: 80px;
          float: left; }
          .social-media .resume .col .social-resume .social-list__item .value {
            font-family: 'Arial';
            font-size: 11px;
            color: white;
            display: block;
            text-align: center;
            padding: 12px 0; }
            .social-media .resume .col .social-resume .social-list__item .value:before {
              content: '';
              display: block;
              height: 30px;
              margin: 0 auto 15px;
              background-position: center;
              background-repeat: no-repeat; }
          .social-media .resume .col .social-resume .social-list__item.facebook {
            background-color: #375C9B; }
            .social-media .resume .col .social-resume .social-list__item.facebook .value:before {
              width: 12px;
              background-image: url("../images/icon_facebook.png"); }
          .social-media .resume .col .social-resume .social-list__item.twitter {
            background-color: #00ADEE; }
            .social-media .resume .col .social-resume .social-list__item.twitter .value:before {
              width: 29px;
              background-image: url("../images/icon_twitter.png"); }
          .social-media .resume .col .social-resume .social-list__item.youtube {
            background-color: #D32221; }
            .social-media .resume .col .social-resume .social-list__item.youtube .value:before {
              width: 26px;
              background-image: url("../images/icon_youtube.png"); }
          .social-media .resume .col .social-resume .social-list__item.flickr {
            background-color: #D73468; }
            .social-media .resume .col .social-resume .social-list__item.flickr .value:before {
              width: 28px;
              background-image: url("../images/icon_flickr.png"); }
          .social-media .resume .col .social-resume .social-list__item.instagram {
            background-color: #654D40; }
            .social-media .resume .col .social-resume .social-list__item.instagram .value:before {
              width: 30px;
              background-image: url("../images/icon_instagram.png"); }
          .social-media .resume .col .social-resume .social-list__item.colab {
            background-color: #5BD999; }
            .social-media .resume .col .social-resume .social-list__item.colab .value:before {
              width: 26px;
              background-image: url("../images/icon_colab.png"); }
      .social-media .resume .col .social-resume .report {
        background: white;
        padding: 12.8%; }
        .social-media .resume .col .social-resume .report .title {
          font-family: 'NexaBold';
          font-size: 16px;
          color: #ACACB2;
          text-transform: uppercase; }
        .social-media .resume .col .social-resume .report .description {
          font-family: 'Arial';
          font-size: 12px;
          color: #666666;
          display: block;
          margin: 20px 0 0;
          line-height: 18px; }
        .social-media .resume .col .social-resume .report .text {
          width: 100%;
          height: 40px;
          background-color: #EEEEEE;
          padding: 0 10px;
          margin-top: 40px; }
        .social-media .resume .col .social-resume .report .status {
          font-family: 'Arial';
          font-size: 9px;
          opacity: 1; }
          .social-media .resume .col .social-resume .report .status.success {
            color: #6BACA3; }

.transparency {
  background: white;
  padding: 70px 0 30px; }
  .transparency__list {
    overflow: hidden;
    margin-top: 40px; }
    .transparency__list__item {
      float: left;
      width: 30%;
      margin-right: 18%; }
      .transparency__list__item:first-child {
        margin-top: 0;
        width: 33%; }
      .transparency__list__item .title {
        font-family: 'NexaBold';
        font-size: 14px;
        color: #5A5C65;
        text-transform: uppercase;
        text-align: center; }
      .transparency__list__item .description {
        font-family: 'Arial';
        font-size: 13px;
        color: #666666;
        line-height: 18px;
        display: block;
        margin-top: 5px; }
      .transparency__list__item img {
        width: 125%;
        height: auto; }
      .transparency__list__item .button {
        margin-top: 50px; }
      .transparency__list__item .graph-labels__item {
        font-family: 'Arial';
        font-size: 11px;
        color: #5A5C65;
        display: inline-block; }
        .transparency__list__item .graph-labels__item .orange {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #F4A14E; }
        .transparency__list__item .graph-labels__item .green {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #6BACA3; }

.navbar {
  padding: 0 !important; }

.transparency-portal {
  background-image: url("../images/transparencia/menu-background.png");
  background-repeat: no-repeat;
  background-size: 100%;
  font-family: Roboto, sans-serif; }
  .transparency-portal .wrapper {
    text-align: -webkit-center; }
    .transparency-portal .wrapper .download-dados {
      display: block !important;
      margin-left: 50px !important; }
    .transparency-portal .wrapper .section-label {
      margin-bottom: 70px;
      padding: 100px 100px 0 100px; }
      .transparency-portal .wrapper .section-label h1 {
        background-color: #F4A14E;
        padding: 0 20px;
        line-height: 42px;
        height: 40px;
        font-family: 'NexaBold';
        color: white;
        font-size: 13px;
        text-transform: uppercase;
        display: block;
        float: left; }
    .transparency-portal .wrapper .menu .line {
      overflow: hidden;
      width: 70% !important; }
      .transparency-portal .wrapper .menu .line .image {
        height: 60%;
        display: block;
        background-repeat: no-repeat;
        background-position-x: center; }
    .transparency-portal .wrapper .menu .line:nth-child(1) a:nth-child(1) .image {
      background-image: url("../images/transparencia/administracao-icon.png");
      background-size: 25%; }
    .transparency-portal .wrapper .menu .line:nth-child(1) a:nth-child(2) .image {
      background-image: url("../images/transparencia/receita-icon.png");
      background-size: 35%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu .line:nth-child(1) a:nth-child(3) .image {
      background-image: url("../images/transparencia/despesa-icon.png");
      background-size: 25%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu .line:nth-child(2) a:nth-child(1) .image {
      background-image: url("../images/transparencia/gestao-de-pessoas-icon.png");
      background-size: 25%; }
    .transparency-portal .wrapper .menu .line:nth-child(2) a:nth-child(2) .image {
      background-image: url("../images/transparencia/fale-conosco-icon.png");
      background-size: 35%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu .line:nth-child(2) a:nth-child(3) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 25%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu .description {
      margin: 60px 0 60px 60px;
      text-align: left;
      font-weight: bold;
      color: #5A5C65; }
      .transparency-portal .wrapper .menu .description .secretaria {
        margin-bottom: 15px; }
    .transparency-portal .wrapper .menu .menu__item {
      position: relative;
      display: table;
      float: left;
      width: 33%;
      height: 200px;
      overflow: hidden;
      background-color: white; }
      .transparency-portal .wrapper .menu .menu__item.darken {
        background-color: #dddddd; }
    .transparency-portal .wrapper .menu .content {
      display: table-cell;
      padding-top: 10%; }
      .transparency-portal .wrapper .menu .content .label {
        font-family: 'NexaBold';
        font-size: 16px;
        color: #5A5C65;
        text-transform: uppercase;
        opacity: .75;
        display: block;
        text-align: center; }
    .transparency-portal .wrapper .menu-transparencia .line {
      overflow: hidden;
      width: 70% !important; }
      .transparency-portal .wrapper .menu-transparencia .line .image {
        height: 60%;
        display: block;
        background-repeat: no-repeat;
        background-position-x: center; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(1) a:nth-child(1) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 25%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(1) a:nth-child(2) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 35%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(1) a:nth-child(3) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 25%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(2) a:nth-child(1) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 25%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(2) a:nth-child(2) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 35%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(2) a:nth-child(3) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 25%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(3) a:nth-child(1) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 25%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(3) a:nth-child(2) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 35%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(3) a:nth-child(3) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 25%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(4) a:nth-child(1) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 25%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(4) a:nth-child(2) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 35%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(4) a:nth-child(3) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 25%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(5) a:nth-child(1) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 25%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(5) a:nth-child(2) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 35%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu-transparencia .line:nth-child(5) a:nth-child(3) .image {
      background-image: url("../images/transparencia/mais-publicacoes-icon.png");
      background-size: 25%;
      background-position-y: 30%; }
    .transparency-portal .wrapper .menu-transparencia .description {
      margin: 60px 0 60px 60px;
      text-align: left;
      font-weight: bold;
      color: #5A5C65; }
      .transparency-portal .wrapper .menu-transparencia .description .secretaria {
        margin-bottom: 15px; }
    .transparency-portal .wrapper .menu-transparencia .menu__item {
      position: relative;
      display: table;
      float: left;
      width: 33%;
      height: 200px;
      overflow: hidden;
      background-color: white; }
      .transparency-portal .wrapper .menu-transparencia .menu__item.darken {
        background-color: #dddddd; }
    .transparency-portal .wrapper .menu-transparencia .content {
      display: table-cell;
      padding-top: 10%; }
      .transparency-portal .wrapper .menu-transparencia .content .label {
        font-family: 'NexaBold';
        font-size: 16px;
        color: #5A5C65;
        text-transform: uppercase;
        opacity: .75;
        display: block;
        text-align: center; }

.internal .wrapper .download-dados {
  display: block !important;
  margin-left: 50px !important;
  content: "";
  background-image: url("../images//images/icon_link.png");
  background-repeat: no-repeat;
  background-position: right 3px;
  background-size: contain;
  padding-left: 18px;
  color: #F4A14E !important; }

.news {
  position: relative;
  min-width: 906px;
  max-width: 935px;
  width: auto;
  padding: 100px 0; }
  .news iframe {
    width: 100%; }
  .news object {
    width: 100%;
    border-bottom: 1px solid gainsboro;
    height: 600px; }
  .news .container {
    position: relative; }
    .news .container.small {
      width: 906px; }
    .news .container.big {
      width: 935px; }
    .news .container .borderBottom {
      display: block;
      width: 100%;
      height: 1px;
      background-color: #DDDDDD;
      margin-top: 15px; }
    .news .container .text, .news .container p {
      font-family: 'Arial';
      font-size: 15px;
      font-weight: 100;
      color: #222222;
      line-height: 22px;
      font-weight: lighter;
      margin-bottom: 37px; }
    .news .container .marginTop30 {
      margin-top: 30px; }
    .news .container .marginTop45 {
      margin-top: 45px; }
    .news .container .marginTop60 {
      margin-top: 60px; }
  .news .headline {
    position: relative;
    width: 100%; }
    .news .headline.left-border {
      padding: 10px 0 0 25px; }
      .news .headline.left-border:before {
        content: '';
        display: block;
        width: 6px;
        height: 150px;
        background-color: #222222;
        position: absolute;
        top: 0;
        left: 0; }
    .news .headline .breadcrumb {
      font-family: 'NexaBold';
      font-size: 13px;
      color: #FF5400;
      text-transform: uppercase; }
    .news .headline .title {
      font-family: 'NexaThin';
      font-size: 45px;
      color: #222222;
      line-height: 48px; }
      .news .headline .title.size30 {
        font-size: 30px;
        line-height: 33px; }
      .news .headline .title.size20 {
        font-size: 20px;
        line-height: 23px; }
      .news .headline .title.bold {
        font-family: 'NexaBold'; }
    .news .headline .description {
      font-family: 'Arial';
      font-size: 15px;
      font-weight: 100;
      color: #343434;
      line-height: 22px;
      margin: 10px 0 0; }
    .news .headline .headline-info {
      font-family: 'Arial';
      font-size: 13px;
      font-weight: 100;
      color: #343434;
      line-height: 18px;
      margin-top: 25px; }
      .news .headline .headline-info .name {
        font-family: 'NexaBold';
        color: #FF5400; }
      .news .headline .headline-info .date {
        display: block;
        font-size: 11px; }
    .news .headline .social-icons {
      margin-top: 45px;
      margin-bottom: 10px;
      overflow: hidden; }
      .news .headline .social-icons .icon {
        background-image: url("../images/sprite-social.png");
        background-repeat: no-repeat;
        display: block;
        float: left;
        width: 65px;
        height: 45px;
        margin-right: 6px; }
        .news .headline .social-icons .icon.facebook {
          background-position: 0; }
        .news .headline .social-icons .icon.twitter {
          background-position: -70px; }
        .news .headline .social-icons .icon.plus {
          background-position: -140px; }
        .news .headline .social-icons .icon.linkedin {
          background-position: -210px; }
        .news .headline .social-icons .icon.whatsapp {
          background-position: -280px; }
        .news .headline .social-icons .icon.email {
          background-position: -350px; }
  .news .container-image.full {
    width: 100%; }
  .news .container-image .image.gif {
    max-width: 700px; }
  .news .container-image .info {
    overflow: hidden;
    margin-top: 10px; }
    .news .container-image .info .subtitle {
      font-family: 'Arial';
      font-weight: 100;
      font-size: 11px;
      color: #343434; }
    .news .container-image .info .author {
      font-family: 'Arial';
      font-weight: 100;
      font-size: 13px;
      color: #343434;
      background: url("../images/icon_camera.png") center left no-repeat;
      padding-left: 20px; }
      .news .container-image .info .author .name {
        font-family: 'NexaBold';
        font-size: 15px; }
  .news .container-text {
    padding-left: 25px;
    margin-top: 75px;
    width: 735px; }
    .news .container-text img {
      width: 100%; }
    .news .container-text a {
      color: #FF9900;
      text-decoration: none;
      font-weight: bold; }
    .news .container-text ul {
      margin: 0;
      -webkit-margin-start: 0px;
      -webkit-margin-end: 0px;
      -webkit-padding-start: 40px; }
      .news .container-text ul li {
        font-family: Roboto, sans-serif;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 37px;
        list-style: disc;
        text-align: -webkit-match-parent; }
    .news .container-text h1 {
      font-size: 40px;
      line-height: 43px; }
    .news .container-text h2 {
      font-size: 30px;
      line-height: 33px; }
    .news .container-text h3 {
      font-size: 20px;
      line-height: 23px; }
    .news .container-text h6 {
      font-size: 11px; }
    .news .container-text h1, .news .container-text h2, .news .container-text h3 {
      font-family: 'NexaThin';
      color: #222222;
      margin-bottom: 37px; }
    .news .container-text h6 {
      color: #666666;
      margin: 5px 0px 37px 0;
      font-family: Roboto, sans-serif;
      font-weight: 100; }
    .news .container-text blockquote {
      position: relative;
      width: 560px;
      border-left: 6px solid #222222;
      padding: 20px 0 20px 20px;
      font-family: 'ClearfaceRegular';
      font-size: 20px;
      line-height: 35px;
      color: #666666;
      margin: 0px 0px 37px 20px; }
  .news .container-facebook {
    margin: 20px 0 20px 25px; }
  .news .container-audio {
    margin: 0; }
    .news .container-audio audio {
      width: 100%; }
  .news .container-media {
    margin: 0; }
    .news .container-media video {
      width: 100%; }
    .news .container-media #youtube-player {
      width: 700px;
      height: 394px; }
  .news .container-tags {
    padding-top: 55px;
    padding-left: 25px;
    padding-bottom: 70px; }
    .news .container-tags .title {
      font-family: 'NexaBold';
      font-size: 20px;
      color: #222222;
      text-transform: uppercase;
      margin-bottom: 5px; }
    .news .container-tags .tag-list {
      font-family: 'Arial';
      font-size: 15px;
      color: #666666; }
      .news .container-tags .tag-list .tag {
        font-weight: bolder;
        font-style: italic;
        color: #F4A14E; }
  .news .related-news {
    width: 100%; }
    .news .related-news__item {
      display: block;
      width: 24.2%;
      float: left;
      box-shadow: 0 0 2px gray;
      margin-right: 1%; }
      .news .related-news__item:last-child {
        margin-right: 0; }
      .news .related-news__item .image {
        background-image: url("../images/imagempelotas.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 100%;
        height: 147px; }
      .news .related-news__item .info {
        padding: 15px 20px;
        height: 63px; }
        .news .related-news__item .info .title {
          font-family: 'NexaBold';
          font-size: 14px;
          color: #727279;
          line-height: 16px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; }
        .news .related-news__item .info .description {
          font-family: 'Arial';
          font-weight: lighter;
          font-size: 11px;
          color: #666666;
          display: block;
          line-height: 16px;
          margin-top: 15px; }
      .news .related-news__item .read-more {
        font-family: 'NexaBold';
        font-size: 16px;
        color: #F4A14E;
        display: block;
        width: 100%;
        height: 43px;
        line-height: 43px;
        text-transform: uppercase;
        text-align: center;
        border-top: 1px solid #E9E9E9; }
  .news .gallery .gallery-top {
    width: 700px;
    height: 466px;
    margin: 30px 0 0 0px; }
  .news .gallery .gallery-thumbs {
    width: 700px;
    height: 112px;
    margin: 8px 0 0 0px; }
    .news .gallery .gallery-thumbs .swiper-slide {
      width: 169px;
      height: 112px; }
  .news .gallery .swiper-slide {
    background-size: cover; }
  .news .gallery .swiper-button-next,
  .news .gallery .swiper-button-prev {
    background: url("../images/slide-arrow.png") center no-repeat;
    background-color: black;
    opacity: .5;
    transition: opacity .3s ease-in-out;
    height: 100%;
    bottom: 0;
    top: auto; }
    .news .gallery .swiper-button-next:hover,
    .news .gallery .swiper-button-prev:hover {
      opacity: 0.7; }
  .news .gallery .swiper-button-prev {
    margin-left: -10px;
    transform: rotate(180deg); }
  .news .gallery .swiper-button-next {
    margin-right: -10px; }
  @media only screen and (max-width: 768px) {
    .news .displayer {
      display: none; } }
  .news .mobile-back {
    display: none; }
  .news.tempMobile {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding: 5px 0 25px; }
    .news.tempMobile .mobile-back {
      display: block;
      margin: 25px 0;
      padding-left: 25px; }
    .news.tempMobile .container.small {
      width: 100%; }
    .news.tempMobile .container.big {
      width: 100%; }
    .news.tempMobile .container .text, .news.tempMobile .container p {
      font-size: 11px;
      line-height: 16px; }
    .news.tempMobile .container .headline .breadcrumb {
      font-size: 11px; }
    .news.tempMobile .container .headline .title {
      font-size: 34px;
      margin-top: 15px;
      line-height: 34px; }
    .news.tempMobile .container .headline .description {
      font-size: 14px;
      line-height: 18px; }
    .news.tempMobile .container .headline .social-icons {
      width: 215px; }
      .news.tempMobile .container .headline .social-icons a {
        margin-bottom: 5px; }
    .news.tempMobile .container .container-tags {
      padding-top: 25px;
      padding-bottom: 10px; }
      .news.tempMobile .container .container-tags .tag-list {
        margin-bottom: 0; }
    .news.tempMobile .container .related-news__item {
      width: 100%;
      max-width: 220px;
      margin-right: 0;
      margin-bottom: 15px; }
      .news.tempMobile .container .related-news__item .info {
        height: auto; }
        .news.tempMobile .container .related-news__item .info .title {
          margin: 0;
          font-size: 18px;
          line-height: 22px; }
    .news.tempMobile .container .container-text {
      width: 100%;
      margin-top: 25px;
      padding-right: 25px; }
      .news.tempMobile .container .container-text img {
        margin-bottom: 25px; }
      .news.tempMobile .container .container-text video {
        margin-bottom: 25px; }
      .news.tempMobile .container .container-text audio {
        margin-bottom: 25px; }
      .news.tempMobile .container .container-text blockquote {
        width: 100%;
        font-size: 18px;
        line-height: 28px;
        padding: 15px;
        margin: 0 0 20px; }
    .news.tempMobile .gallery .gallery-top {
      width: 100%;
      height: 170px;
      margin: 25px 0 0; }
      @media (min-width: 360px) {
        .news.tempMobile .gallery .gallery-top {
          height: 197px; } }
      @media (min-width: 375px) {
        .news.tempMobile .gallery .gallery-top {
          height: 206px; } }
      @media (min-width: 412px) {
        .news.tempMobile .gallery .gallery-top {
          height: 231px; } }
      @media (min-width: 414px) {
        .news.tempMobile .gallery .gallery-top {
          height: 232px; } }
      @media (min-width: 768px) {
        .news.tempMobile .gallery .gallery-top {
          height: 457px; } }
    .news.tempMobile .gallery .gallery-thumbs {
      display: none;
      width: 100%; }

.visite {
  position: relative;
  width: 100%;
  padding-top: 40px;
  background-color: #F9F9F9; }
  .visite .container .slider__item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 663px; }
    .visite .container .slider__item:nth-child(1) {
      background-image: url("../images/bigslider_image1.png"); }
    .visite .container .slider__item:nth-child(2) {
      background-image: url("../images/bigslider_image2.png"); }
    .visite .container .slider__item:nth-child(3) {
      background-image: url("../images/bigslider_image3.png"); }
    .visite .container .slider__item:nth-child(4) {
      background-image: url("../images/bigslider_image4.png"); }
  .visite .container .box-info {
    padding: 70px 0;
    position: absolute;
    top: 40px;
    left: 0;
    width: 50%;
    height: calc(100% - 40px);
    background-color: rgba(90, 92, 101, 0.75);
    z-index: 1; }
    .visite .container .box-info .content {
      width: auto;
      max-width: 445px;
      margin: 0 auto;
      padding-left: 2%; }
    .visite .container .box-info .description {
      font-family: 'NexaLight';
      font-size: 18px;
      color: white;
      display: block;
      margin-top: 70px; }
    .visite .container .box-info .divider {
      position: relative;
      display: block;
      width: 100%;
      max-width: 445px;
      height: 1px;
      background-color: white;
      margin: 15px 0; }
      .visite .container .box-info .divider:before {
        content: '';
        display: block;
        width: 8px;
        height: 11px;
        background: white;
        background: url("../images/icon_losango.png") center no-repeat;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -5.5px;
        margin-left: -4px; }
    .visite .container .box-info .title {
      font-family: 'NexaThin';
      font-size: 34px;
      color: white;
      display: block;
      line-height: 60px; }
      .visite .container .box-info .title .big {
        display: block;
        font-family: 'ArmoniosoRegular';
        font-size: 80px;
        color: white; }
    .visite .container .box-info .slider-arrows {
      margin-top: 10px;
      margin-bottom: 50px; }

.footer {
  position: relative;
  background-color: #585A63; }
  .footer .site-map {
    padding: 70px 0 0; }
    .footer .site-map .list {
      width: 100%;
      overflow: hidden;
      margin-top: 70px; }
      .footer .site-map .list .col {
        float: left;
        width: 11.11%; }
        .footer .site-map .list .col .title {
          font-family: 'NexaBold';
          font-size: 14px;
          color: #DDDDDD;
          text-transform: uppercase; }
        .footer .site-map .list .col .links {
          margin-top: 5px; }
          .footer .site-map .list .col .links__item {
            font-family: 'Arial';
            font-size: 11px;
            color: #DDDDDD;
            display: block;
            line-height: 20px; }
            .footer .site-map .list .col .links__item:hover {
              text-decoration: underline; }
  .footer .bottom-info {
    padding: 0; }
    .footer .bottom-info .wrapper {
      bottom: -115px;
      z-index: 1; }
    .footer .bottom-info .cols {
      overflow: hidden; }
      .footer .bottom-info .cols .col {
        float: left;
        margin-top: 100px; }
        @media (max-width: 1250px) {
          .footer .bottom-info .cols .col {
            margin-top: 45px; } }
        .footer .bottom-info .cols .col:nth-child(1) {
          width: 12%;
          margin-right: 3%; }
        .footer .bottom-info .cols .col:nth-child(2) {
          width: 20%;
          margin-right: 5%; }
          @media (max-width: 1250px) {
            .footer .bottom-info .cols .col:nth-child(2) {
              width: 23%;
              margin-right: 2%; } }
        .footer .bottom-info .cols .col:nth-child(3) {
          width: 18%;
          margin-right: 2%; }
          @media (max-width: 1250px) {
            .footer .bottom-info .cols .col:nth-child(3) {
              width: 20%;
              margin-right: 0%; } }
        .footer .bottom-info .cols .col:nth-child(4) {
          width: 25%; }
          .footer .bottom-info .cols .col:nth-child(4) .title {
            color: white;
            font-family: 'NexaBold'; }
        .footer .bottom-info .cols .col:last-child {
          margin-right: 0; }
        .footer .bottom-info .cols .col .address .title {
          font-family: 'NexaBold';
          font-size: 18px;
          color: white; }
          @media (max-width: 1390px) {
            .footer .bottom-info .cols .col .address .title {
              font-size: 15px; } }
        .footer .bottom-info .cols .col .address .description {
          font-family: 'Arial';
          font-size: 12px;
          color: white;
          margin: 5px 0 0;
          line-height: 18px; }
          @media (max-width: 1390px) {
            .footer .bottom-info .cols .col .address .description {
              font-size: 11px; } }
        .footer .bottom-info .cols .col .earth {
          display: block;
          width: 100%;
          height: auto; }
    .footer .bottom-info .social-media-list .title {
      color: white;
      font-family: 'NexaBold';
      font-size: 18px; }
      @media (max-width: 1390px) {
        .footer .bottom-info .social-media-list .title {
          font-size: 15px; } }
    .footer .bottom-info .social-media-list .list {
      margin-top: 5px;
      overflow: hidden; }
      .footer .bottom-info .social-media-list .list__item {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        background-color: #F4A14E;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain; }
        .footer .bottom-info .social-media-list .list__item:last-child {
          margin-right: 0; }
        .footer .bottom-info .social-media-list .list__item.facebook {
          background-image: url("../images/icon_facebook_orange.png"); }
        .footer .bottom-info .social-media-list .list__item.twitter {
          background-image: url("../images/icon_twitter_orange.png"); }
        .footer .bottom-info .social-media-list .list__item.youtube {
          background-image: url("../images/icon_youtube_orange.png"); }
        .footer .bottom-info .social-media-list .list__item.instagram {
          background-image: url("../images/icon_instagram_orange.png"); }
        .footer .bottom-info .social-media-list .list__item.flickr {
          background-image: url("../images/icon_flickr_orange.png"); }
        @media (max-width: 1390px) {
          .footer .bottom-info .social-media-list .list__item {
            width: 30px;
            height: 30px; } }
  .footer .phones {
    background-color: #52545D;
    position: relative;
    width: 100%;
    height: 200px; }
    .footer .phones .phone-list {
      overflow: hidden;
      padding: 69px 0; }
      .footer .phones .phone-list__item {
        float: left;
        font-family: 'Arial';
        font-size: 12px;
        color: white;
        background: url("../images/icon_phone.png") center left no-repeat;
        display: block;
        padding-left: 41px;
        margin-left: 50px; }
        .footer .phones .phone-list__item:first-child {
          margin-left: 0; }
        .footer .phones .phone-list__item .number {
          display: block;
          font-family: 'NexaBold';
          font-size: 30px;
          color: white; }
    .footer .phones .copyright {
      position: relative;
      left: 0;
      bottom: 20px; }
      .footer .phones .copyright .text {
        display: block;
        color: white;
        font-family: 'Arial';
        font-size: 10px; }
        .footer .phones .copyright .text .coinpel {
          font-family: 'NexaBold';
          font-size: 12px;
          text-transform: uppercase; }
    .footer .phones .back-top {
      display: none;
      position: fixed;
      right: 0;
      bottom: 3%;
      background-image: url("../images/icon_back-to-top.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 15px 11px;
      background-color: #383A41;
      box-shadow: none;
      border: none;
      width: 40px;
      height: 40px;
      margin-right: 3%;
      z-index: 2;
      opacity: .5;
      -webkit-transition: opacity 0.5s;
      -moz-transition: opacity 0.5s;
      transition: opacity 0.5s; }
      .footer .phones .back-top:hover {
        opacity: 1; }
    .footer .phones .zap-zap-icon {
      display: none;
      position: fixed;
      right: 0;
      bottom: 3%;
      background-image: url("../images/zapzap.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60px 60px;
      box-shadow: none;
      border: none;
      width: 70px;
      height: 70px;
      margin-right: 3%;
      z-index: 5;
      opacity: 1;
      -webkit-transition: opacity 0.5s;
      -moz-transition: opacity 0.5s;
      transition: opacity 0.5s; }
      .footer .phones .zap-zap-icon:hover {
        opacity: 1; }
  .footer .arrow {
    background: url("../images/footer-arrow.png") center right no-repeat;
    background-size: contain;
    display: block;
    width: 268px;
    height: 370px;
    position: absolute;
    bottom: -68px;
    left: -120px;
    z-index: 1; }

@media only screen and (max-width: 868px) {
  .footer {
    display: none; } }

a.internal-download {
  margin-bottom: 10px;
  color: #F4A14E; }
  a.internal-download:after {
    content: "";
    display: inline-block;
    background-image: url("../images/icon_link.png");
    background-repeat: no-repeat;
    background-position: right 3px;
    background-size: contain;
    width: 22px;
    height: 22px;
    padding-left: 10px; }

a.internal-link {
  margin-bottom: 10px;
  color: #F4A14E; }

.internal {
  position: relative;
  padding: 70px 0; }
  .internal .container {
    padding-top: 80px;
    padding-left: 80px; }
    .internal .container-header {
      overflow: hidden;
      margin-bottom: 30px; }
      .internal .container-header .lable {
        font-family: 'NexaBold';
        font-size: 13px;
        color: #CE3E26;
        display: block; }
      .internal .container-header .title {
        display: block;
        font-family: 'NexaThin';
        font-size: 45px;
        color: #666666; }
    .internal .container .content .infografico {
      max-width: 1200px; }
    .internal .container .content .side.left {
      width: 54%; }
    .internal .container .content .side.right {
      width: 40%; }
    @media only screen and (max-width: 868px) {
      .internal .container .content .side.right, .internal .container .content .side.left {
        width: 100% !important; }
      .internal .container .content .side.right {
        float: none; } }
    .internal .container .content p {
      font-family: 'Arial';
      font-size: 13px;
      color: #666666;
      line-height: 20px;
      font-weight: 100;
      margin-bottom: 30px; }
      .internal .container .content p:last-child {
        margin-bottom: 0; }
    .internal .container .content a.internal-download {
      margin-bottom: 10px;
      color: #F4A14E; }
      .internal .container .content a.internal-download:after {
        content: "";
        display: inline-block;
        background-image: url("../images/icon_link.png");
        background-repeat: no-repeat;
        background-position: right 3px;
        background-size: contain;
        width: 22px;
        height: 22px;
        padding-left: 10px; }
    .internal .container .content a.internal-link {
      margin-bottom: 10px;
      color: #F4A14E; }
    .internal .container .content h1 {
      display: block;
      font-family: 'NexaThin';
      font-size: 45px;
      color: #666666; }
    @media only screen and (max-width: 868px) {
      .internal .container .content h1 {
        font-size: 28px;
        margin-bottom: 0; } }
    .internal .container .content h2 {
      display: block;
      font-family: 'NexaThin';
      font-size: 36px;
      color: #666666; }
    .internal .container .content h3 {
      display: block;
      font-family: 'NexaThin';
      font-size: 24px;
      color: #666666; }
    .internal .container .content h4 {
      display: block;
      font-family: 'NexaBold';
      font-size: 16px;
      color: #666666; }
    .internal .container .content h5 {
      display: block;
      font-family: 'NexaBold';
      font-size: 12px;
      color: #666666; }
    .internal .container .content h6 {
      display: block;
      font-family: 'NexaLight';
      font-size: 12px;
      color: #F4A14E; }
    .internal .container .content .center {
      display: block;
      margin: 0 auto; }
    .internal .container .content .paula {
      display: block;
      margin-left: 50px; }
    .internal .container .content .fix-topPosition {
      margin-top: -132px; }
    .internal .container .content .big-image .bar {
      position: relative;
      width: 100%;
      height: 100px;
      background-color: #DDDDDD; }
      .internal .container .content .big-image .bar .text {
        font-family: 'NexaBold';
        font-size: 15px;
        color: #666666;
        text-align: right;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) translateZ(0);
        margin-left: -20px; }
        @media (max-width: 1200px) {
          .internal .container .content .big-image .bar .text {
            margin-left: -50px; } }
      .internal .container .content .big-image .bar .social-list {
        position: absolute;
        top: 0;
        right: 0;
        height: 100px;
        overflow: hidden; }
        .internal .container .content .big-image .bar .social-list__item {
          display: block;
          float: left;
          width: 50px;
          height: 100px;
          background-image: url("../images/internal-social-list.png");
          background-repeat: no-repeat; }
          .internal .container .content .big-image .bar .social-list__item.facebook {
            background-position: 0 0; }
          .internal .container .content .big-image .bar .social-list__item.instagram {
            background-position: -50px 0; }
          .internal .container .content .big-image .bar .social-list__item.twitter {
            background-position: -100px 0; }
    .internal .container .content .big-image .secretaria-foto {
      width: 50%; }
    .internal .container .content .big-image .secretaria-foto-paisagem {
      width: 100%; }
    .internal .container table.danos, .internal .container th, .internal .container td {
      border: 1px solid #CCC;
      width: 100%;
      font-family: 'Arial';
      font-size: 13px;
      color: #666666;
      line-height: 20px;
      font-weight: 100; }
    .internal .container table.danos th {
      background-color: #CCC; }
    .internal .container table.danos .veiculos {
      background-color: gainsboro;
      width: auto; }
    .internal .container table.danos .veiculos_num {
      background-color: gainsboro;
      width: 0%; }
    .internal .container table.danos input {
      position: relative;
      display: inline-block;
      padding: 0px 10px;
      -webkit-appearance: checkbox !important; }
    .internal .container .title-page {
      font-family: 'NexaLight';
      font-size: 24px;
      color: #F4A14E; }
    .internal .container .text {
      padding-right: 27px !important; }
  @media only screen and (max-width: 868px) {
    .internal .container {
      padding: 0; } }

@media only screen and (max-width: 868px) {
  .internal {
    padding-top: 35px; } }

.noticias {
  position: relative;
  padding: 70px 0; }
  .noticias .container {
    padding-top: 80px;
    padding-left: 80px; }
    .noticias .container-header {
      overflow: hidden;
      margin-bottom: 30px; }
      .noticias .container-header .lable {
        font-family: 'NexaBold';
        font-size: 13px;
        color: #CE3E26;
        display: block; }
      .noticias .container-header .title {
        display: block;
        font-family: 'NexaThin';
        font-size: 45px;
        color: #666666; }
    .noticias .container .content .infografico {
      max-width: 1200px; }
    .noticias .container .content .side.left {
      width: 64.44%; }
    .noticias .container .content .side.right {
      width: 40%; }
    .noticias .container .content p {
      font-family: 'Arial';
      font-size: 13px;
      color: #666666;
      line-height: 20px;
      font-weight: 100;
      margin-bottom: 30px; }
      .noticias .container .content p:last-child {
        margin-bottom: 0; }
      .noticias .container .content p.template-link {
        margin-bottom: 10px; }
    .noticias .container .content .pagination {
      display: inline-block;
      font-family: 'NexaBold';
      padding: 35px 0px 0px 0px; }
      .noticias .container .content .pagination a {
        color: #aaaaaa;
        float: left;
        text-align: center;
        padding: 5px 10.44px;
        text-decoration: none;
        transition: border-color .3s, color .3s;
        -webkit-transition: border-color .3s, color .3s;
        -moz-transition: border-color .3s, color .3s;
        -o-transition: border-color .3s, color .3s;
        border: 1px solid #dddddd;
        margin-right: 15px; }
      .noticias .container .content .pagination .numbers {
        float: left;
        overflow: hidden; }
      .noticias .container .content .pagination .total-pages {
        color: #aaaaaa;
        float: left;
        width: 65.77px;
        height: 32px;
        text-decoration: none;
        -moz-transition: border-color .3s, color .3s;
        -o-transition: border-color .3s, color .3s;
        -webkit-transition: border-color .3s, color .3s;
        transition: border-color .3s, color .3s;
        border: 1px solid #dddddd;
        text-align: center; }
      .noticias .container .content .pagination a.active {
        color: #FF5400;
        border: 1px solid #FF5400; }
      .noticias .container .content .pagination a:hover:not(.active) {
        border-color: #FF5400;
        color: #FF5400; }
    .noticias .container .content .container-link {
      height: 147px; }
      @media (min-width: 1024px) {
        .noticias .container .content .container-link {
          float: right;
          width: 60%;
          max-width: 464px;
          padding: 20px; } }
      @media (min-width: 1200px) {
        .noticias .container .content .container-link {
          float: right;
          width: 100%;
          max-width: 464px;
          margin: 0px -3px 0 0; } }
      @media (min-width: 1300px) {
        .noticias .container .content .container-link {
          float: right;
          width: 100%;
          max-width: 464px;
          margin: 0px 60px 0 0; } }
      @media (min-width: 1450px) {
        .noticias .container .content .container-link {
          float: right;
          width: 100%;
          max-width: 464px;
          margin: 0px 130px 0 0; } }
    .noticias .container .content h1 {
      display: block;
      font-family: 'NexaThin';
      font-size: 45px;
      color: #666666; }
    .noticias .container .content .template-title {
      display: block;
      font-family: 'Nexalight';
      font-size: 26px;
      color: #666666;
      line-height: 1.35em;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
      .noticias .container .content .template-title a {
        color: #666666; }
    .noticias .container .content .borderBottom {
      display: none; }
    .noticias .container .content .template-descripiton {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical; }
    .noticias .container .content .template-descripiton,
    .noticias .container .content .template-time {
      font-family: 'NexaBold';
      font-size: 15px;
      font-weight: 100;
      color: #aaaaaa;
      line-height: 22px;
      font-weight: lighter;
      margin-bottom: 37px; }
      .noticias .container .content .template-descripiton .template-time,
      .noticias .container .content .template-time .template-time {
        line-height: 1.35em; }
      .noticias .container .content .template-descripiton .template-descripiton,
      .noticias .container .content .template-time .template-descripiton {
        line-height: 1.35em; }
    .noticias .container .content blockquote {
      position: relative;
      /* width: 0; */
      text-transform: uppercase;
      border-left: 6px solid #222222;
      padding: 0px 0px 0px 20px;
      font-family: 'Nexabold';
      font-size: 13px;
      line-height: 35px;
      color: #ff5400;
      margin: 40px 0px 32px 0px; }
    .noticias .container .content h3 {
      display: block;
      font-family: 'NexaThin';
      font-size: 24px;
      color: #666666; }
    .noticias .container .content h4 {
      display: block;
      font-family: 'NexaBold';
      font-size: 16px;
      color: #666666; }
    .noticias .container .content h5 {
      display: block;
      font-family: 'NexaBold';
      font-size: 12px;
      color: #666666; }
    .noticias .container .content h6 {
      display: block;
      font-family: 'NexaLight';
      font-size: 12px;
      color: #F4A14E; }
    .noticias .container .content .center {
      display: block;
      margin: 0 auto; }

.full-header {
  box-shadow: 0 5px 3px -3px gray; }
  .full-header h1 {
    display: block;
    font-family: Arial;
    font-size: 38px;
    color: black; }
  .full-header .wrapper {
    padding: 0 8%; }

.portaldedados {
  min-height: 100%;
  position: relative;
  position: relative;
  width: 100%;
  padding: 0 8%; }
  .portaldedados .portal-section-label {
    position: relative;
    z-index: 1;
    height: 52px; }
    .portaldedados .portal-section-label .text {
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 0 44px;
      line-height: 42px;
      height: 40px;
      font-family: 'NexaBold';
      color: black;
      font-size: 18px;
      z-index: 3;
      margin: 15px 0; }
      .portaldedados .portal-section-label .text:before {
        content: '';
        display: block;
        width: 37px;
        height: 52px;
        background: url("../images//portaldedados/icon_home.png") center no-repeat;
        background-size: contain;
        position: absolute;
        top: -12px;
        left: 5px;
        z-index: -1; }
  .portaldedados-header {
    padding: 60px 0 60px;
    width: 100%; }
    .portaldedados-header .title {
      width: 40%;
      position: relative; }
    .portaldedados-header .logo-prefeitura {
      background: url("../images/logo-maior.png") center no-repeat;
      background-size: cover;
      width: 246px;
      height: 78px; }
    .portaldedados-header .description {
      font-family: 'Arial';
      font-size: 15px;
      color: #666666;
      line-height: 22px;
      margin: 15px 0 0; }
      .portaldedados-header .description .light {
        color: #F4A14E;
        font-style: italic; }
    .portaldedados-header .side.left {
      width: 50%;
      float: left; }
    .portaldedados-header .side.right {
      width: 50%; }
  .portaldedados-footer {
    background-color: #5A5C65;
    padding: 100px 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    text-align: center;
    margin-top: 75px; }
  .portaldedados .about {
    padding-top: 12px;
    padding-bottom: 120px; }
  .portaldedados .module {
    padding: 20px 0 40px;
    border-bottom: 1px solid black; }
  .portaldedados .pelotas-partner {
    max-width: 720px;
    margin-left: 200px; }
  .portaldedados .container {
    padding-top: 10px;
    padding-left: 20px; }
    .portaldedados .container .image-content {
      float: left; }
      .portaldedados .container .image-content.content-download {
        background-size: cover;
        padding: 10px 0;
        margin-left: -3px; }
      .portaldedados .container .image-content .image-download {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        width: 35px;
        height: 17px;
        margin: 0 auto 20px; }
        .portaldedados .container .image-content .image-download.xls {
          background-image: url("../images//portaldedados/icon_xls.png"); }
        .portaldedados .container .image-content .image-download.doc {
          background-image: url("../images//portaldedados/icon_doc.png"); }
        .portaldedados .container .image-content .image-download.csv {
          background-image: url("../images//portaldedados/icon_csv.png"); }
        .portaldedados .container .image-content .image-download.kmz {
          background-image: url("../images//portaldedados/icon_kmz.png"); }
        .portaldedados .container .image-content .image-download.dwg {
          background-image: url("../images//portaldedados/icon_dwg.png"); }
        .portaldedados .container .image-content .image-download.ods {
          background-image: url("../images//portaldedados/icon_ods.png"); }
        .portaldedados .container .image-content .image-download.pdf {
          background-image: url("../images//portaldedados/icon_pdf.png"); }
        .portaldedados .container .image-content .image-download.cdr {
          background-image: url("../images//portaldedados/icon_cdr.png"); }
      .portaldedados .container .image-content .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 101px;
        height: 95px;
        margin: 0 auto 20px; }
        .portaldedados .container .image-content .image.celphone {
          background-image: url("../images//portaldedados/icon_celphone.png"); }
        .portaldedados .container .image-content .image.home {
          background-image: url("../images//portaldedados/icon_home.png"); }
        .portaldedados .container .image-content .image.saude {
          background-image: url("../images//portaldedados/icon_saude.png"); }
        .portaldedados .container .image-content .image.educacao {
          background-image: url("../images//portaldedados/icon_educacao.png"); }
        .portaldedados .container .image-content .image.obras {
          background-image: url("../images//portaldedados/icon_obras.png"); }
        .portaldedados .container .image-content .image.gestao-da-cidade {
          background-image: url("../images//portaldedados/icon_gestao_da_cidade.png"); }
        .portaldedados .container .image-content .image.seguranca {
          background-image: url("../images//portaldedados/icon_seguranca.png"); }
        .portaldedados .container .image-content .image.transito-e-transporte {
          background-image: url("../images//portaldedados/icon_transito_e_transporte.png"); }
        .portaldedados .container .image-content .image.cultura {
          background-image: url("../images//portaldedados/icon_cultura.png"); }
        .portaldedados .container .image-content .image.meio-ambiente {
          background-image: url("../images//portaldedados/icon_meio_ambiente.png"); }
        .portaldedados .container .image-content .image.servicos-urbanos {
          background-image: url("../images//portaldedados/icon_servicos_urbanos.png"); }
        .portaldedados .container .image-content .image.sanep {
          background-image: url("../images//portaldedados/icon_sanep.png"); }
        .portaldedados .container .image-content .image.populacao {
          background-image: url("../images//portaldedados/icon_populacao.png"); }
        .portaldedados .container .image-content .image.social {
          background-image: url("../images//portaldedados/icon_social.png"); }
        .portaldedados .container .image-content .image.participacao-social {
          background-image: url("../images//portaldedados/icon_participacao_social.png"); }
        .portaldedados .container .image-content .image.orcamento-e-gestao {
          background-image: url("../images//portaldedados/icon_orcamento_e_gestao.png"); }
        .portaldedados .container .image-content .image.negocios-e-turismo {
          background-image: url("../images//portaldedados/icon_negocios_e_turismo.png"); }
        .portaldedados .container .image-content .image.habitacao {
          background-image: url("../images//portaldedados/icon_habitacao.png"); }
        .portaldedados .container .image-content .image.esporte-e-lazer {
          background-image: url("../images//portaldedados/icon_esporte_e_lazer.png"); }
      .portaldedados .container .image-content .label {
        font-family: Arial;
        font-size: 13px;
        font-weight: bold;
        color: #5A5C65;
        opacity: .75;
        display: block;
        text-align: center; }
    .portaldedados .container .text .title {
      display: block;
      font-family: Arial;
      font-size: 20px;
      color: black; }
    .portaldedados .container .text p {
      font-family: 'NexaThin';
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      font-weight: 100; }
    .portaldedados .container .text h4 {
      display: block;
      font-family: Arial;
      font-size: 16px;
      font-weight: 100;
      color: black; }
    .portaldedados .container .portaldedados-menu {
      overflow: hidden; }
      .portaldedados .container .portaldedados-menu .line {
        overflow: hidden; }
        .portaldedados .container .portaldedados-menu .line:nth-child(odd) .digital-menu__item:nth-child(odd) {
          background-color: rgba(221, 221, 221, 0.25); }
        .portaldedados .container .portaldedados-menu .line:nth-child(odd) .digital-menu__item:nth-child(even) {
          background-color: rgba(221, 221, 221, 0.1); }
        .portaldedados .container .portaldedados-menu .line:nth-child(even) .digital-menu__item:nth-child(odd) {
          background-color: rgba(221, 221, 221, 0.1); }
        .portaldedados .container .portaldedados-menu .line:nth-child(even) .digital-menu__item:nth-child(even) {
          background-color: rgba(221, 221, 221, 0.25); }
        .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item {
          position: relative;
          display: table;
          float: left;
          width: 20%;
          height: 200px;
          overflow: hidden; }
          .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content {
            display: table-cell; }
            .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image {
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              display: block;
              width: 101px;
              height: 95px;
              margin: 0 auto 20px; }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.saude {
                background-image: url("../images//portaldedados/icon_saude.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.educacao {
                background-image: url("../images//portaldedados/icon_educacao.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.obras {
                background-image: url("../images//portaldedados/icon_obras.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.gestao-da-cidade {
                background-image: url("../images//portaldedados/icon_gestao_da_cidade.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.seguranca {
                background-image: url("../images//portaldedados/icon_seguranca.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.transito-e-transporte {
                background-image: url("../images//portaldedados/icon_transito_e_transporte.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.cultura {
                background-image: url("../images//portaldedados/icon_cultura.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.meio-ambiente {
                background-image: url("../images//portaldedados/icon_meio_ambiente.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.servicos-urbanos {
                background-image: url("../images//portaldedados/icon_servicos_urbanos.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.sanep {
                background-image: url("../images//portaldedados/icon_sanep.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.populacao {
                background-image: url("../images//portaldedados/icon_populacao.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.social {
                background-image: url("../images//portaldedados/icon_social.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.participacao-social {
                background-image: url("../images//portaldedados/icon_participacao_social.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.orcamento-e-gestao {
                background-image: url("../images//portaldedados/icon_orcamento_e_gestao.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.negocios-e-turismo {
                background-image: url("../images//portaldedados/icon_negocios_e_turismo.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.habitacao {
                background-image: url("../images//portaldedados/icon_habitacao.png"); }
              .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .image.esporte-e-lazer {
                background-image: url("../images//portaldedados/icon_esporte_e_lazer.png"); }
            .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .label {
              font-family: Arial;
              font-size: 16px;
              color: #5A5C65;
              opacity: .75;
              display: block;
              text-align: center; }
            .portaldedados .container .portaldedados-menu .line .portaldedados-menu__item .content .description {
              font-family: 'Arial';
              font-size: 12px;
              color: #666666;
              display: block;
              text-align: center;
              line-height: 18px;
              width: 90%;
              margin: 30px auto 0; }
    .portaldedados .container .content .side.left {
      width: 54%; }
    .portaldedados .container .content .side.right {
      width: 40%; }
    .portaldedados .container .content p {
      font-family: 'Arial';
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      font-weight: 100;
      margin-bottom: 30px; }
      .portaldedados .container .content p:last-child {
        margin-bottom: 0; }
      .portaldedados .container .content p.internal-link {
        margin-bottom: 10px; }
        .portaldedados .container .content p.internal-link a {
          color: #F4A14E; }
          .portaldedados .container .content p.internal-link a:after {
            content: "";
            display: inline-block;
            background-image: url("../images/icon_link.png");
            background-repeat: no-repeat;
            background-position: right 3px;
            background-size: contain;
            width: 22px;
            height: 22px;
            padding-left: 10px; }
    .portaldedados .container .content .center {
      display: block;
      margin: 0 auto; }
    .portaldedados .container .content .paula {
      display: block;
      margin-left: 50px; }
    .portaldedados .container .content .fix-topPosition {
      margin-top: -132px; }
  .portaldedados h1 {
    display: block;
    font-family: Arial;
    font-size: 40px;
    color: black; }
  .portaldedados h2 {
    display: block;
    font-family: 'NexaThin';
    font-size: 36px;
    color: #666666; }
  .portaldedados h3 {
    display: block;
    font-family: 'NexaThin';
    font-size: 24px;
    color: #666666; }
  .portaldedados h4 {
    display: block;
    font-family: Arial;
    font-size: 17px;
    padding-bottom: 8px;
    font-weight: bold;
    color: black; }
  .portaldedados h5 {
    display: block;
    font-family: 'NexaBold';
    font-size: 12px;
    color: #666666; }
  .portaldedados h6 {
    display: block;
    font-family: 'NexaLight';
    font-size: 12px;
    color: #F4A14E; }

.digital {
  position: relative;
  width: 100%;
  background: url("../images/bg_digital.png") center no-repeat;
  background-size: cover; }
  .digital-header {
    padding: 120px 0 90px;
    width: 80%; }
    @media only screen and (max-width: 1023px) {
      .digital-header {
        width: 100%; } }
    .digital-header .description {
      font-family: 'Arial';
      font-size: 15px;
      color: #666666;
      line-height: 22px;
      margin: 15px 0 0; }
      .digital-header .description .light {
        color: #F4A14E;
        font-style: italic; }
  .digital .digital-menu {
    overflow: hidden; }
    .digital .digital-menu .line {
      overflow: hidden; }
      .digital .digital-menu .line:nth-child(odd) .digital-menu__item:nth-child(odd) {
        background-color: rgba(221, 221, 221, 0.25); }
      .digital .digital-menu .line:nth-child(odd) .digital-menu__item:nth-child(even) {
        background-color: rgba(221, 221, 221, 0.1); }
      .digital .digital-menu .line:nth-child(even) .digital-menu__item:nth-child(odd) {
        background-color: rgba(221, 221, 221, 0.1); }
      .digital .digital-menu .line:nth-child(even) .digital-menu__item:nth-child(even) {
        background-color: rgba(221, 221, 221, 0.25); }
      .digital .digital-menu .line .digital-menu__item {
        position: relative;
        display: table;
        float: left;
        width: 25%;
        height: 250px;
        overflow: hidden; }
        @media only screen and (max-width: 800px) {
          .digital .digital-menu .line .digital-menu__item {
            width: 50%; } }
        @media only screen and (max-width: 500px) {
          .digital .digital-menu .line .digital-menu__item {
            width: 100%;
            margin: 2px; } }
        .digital .digital-menu .line .digital-menu__item .content {
          display: table-cell;
          vertical-align: middle; }
          .digital .digital-menu .line .digital-menu__item .content .image {
            background-position: center;
            background-repeat: no-repeat;
            display: block;
            width: 101px;
            height: 95px;
            margin: 0 auto 20px; }
            .digital .digital-menu .line .digital-menu__item .content .image.immobile {
              background-image: url("../images//menu_digital/icon_immobile.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.edificapel {
              background-image: url("../images//menu_digital/icon_edificapel.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.sala-do-empreendedor {
              background-image: url("../images//menu_digital/icon_sala_empreendedor.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.company {
              background-image: url("../images//menu_digital/icon_company.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.rural-producer {
              background-image: url("../images//menu_digital/icon_rural-producer.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.procon {
              background-image: url("../images//menu_digital/icon_procon.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.transport {
              background-image: url("../images//menu_digital/icon_transport.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.environment {
              background-image: url("../images//menu_digital/icon_environment.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.sanep {
              background-image: url("../images//menu_digital/icon_sanep.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.server {
              background-image: url("../images//menu_digital/icon_server.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.phone {
              background-image: url("../images//menu_digital/icon_phone.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.road {
              background-image: url("../images//menu_digital/icon_road.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.lost {
              background-image: url("../images//menu_digital/icon_lost.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.tribute {
              background-image: url("../images//menu_digital/icon_tribute.png"); }
            .digital .digital-menu .line .digital-menu__item .content .image.refispel {
              background-image: url("../images//menu_digital/icon_refispel.png");
              background-size: contain;
              width: 160px;
              height: 145px;
              margin: 0 auto 0; }
          .digital .digital-menu .line .digital-menu__item .content .label {
            font-family: 'NexaBold';
            font-size: 16px;
            color: #5A5C65;
            text-transform: uppercase;
            opacity: .75;
            display: block;
            text-align: center; }
          .digital .digital-menu .line .digital-menu__item .content .description {
            font-family: 'Arial';
            font-size: 12px;
            color: #666666;
            display: block;
            text-align: center;
            line-height: 18px;
            width: 90%;
            margin: 30px auto 0; }
  .digital .icon-smile {
    width: 100%;
    overflow: hidden;
    margin-top: 90px;
    margin-bottom: 20px; }
    .digital .icon-smile .icon {
      background: url("../images/icon_smile.png") center no-repeat;
      float: right;
      display: block;
      width: 82px;
      height: 80px; }

.empreendedor {
  position: relative;
  width: 100%;
  background: url("../images/bg_digital.png") center no-repeat;
  background-size: cover; }
  .empreendedor-header {
    padding: 120px 0 90px;
    width: 80%; }
    @media only screen and (max-width: 1023px) {
      .empreendedor-header {
        width: 100%; } }
    .empreendedor-header .description {
      font-family: 'Arial';
      font-size: 15px;
      color: #666666;
      line-height: 22px;
      margin: 15px 0 0; }
      .empreendedor-header .description .light {
        color: #F4A14E;
        font-style: italic; }
    .empreendedor-header .logo-empreendedor {
      display: block;
      background: url(../images/sala-empreendedor/banco-logo.png) center no-repeat;
      width: 300px;
      height: 100px;
      background-size: contain; }
  .empreendedor .empreendedor-menu {
    overflow: hidden; }
    .empreendedor .empreendedor-menu .line {
      overflow: hidden; }
      .empreendedor .empreendedor-menu .line:nth-child(odd) .empreendedor-menu__item:nth-child(odd) {
        background-color: rgba(221, 221, 221, 0.25); }
      .empreendedor .empreendedor-menu .line:nth-child(odd) .empreendedor-menu__item:nth-child(even) {
        background-color: rgba(221, 221, 221, 0.1); }
      .empreendedor .empreendedor-menu .line:nth-child(even) .empreendedor-menu__item:nth-child(odd) {
        background-color: rgba(221, 221, 221, 0.1); }
      .empreendedor .empreendedor-menu .line:nth-child(even) .empreendedor-menu__item:nth-child(even) {
        background-color: rgba(221, 221, 221, 0.25); }
      .empreendedor .empreendedor-menu .line .empreendedor-menu__item {
        position: relative;
        display: table;
        float: left;
        width: 25%;
        height: 250px;
        overflow: hidden; }
        @media only screen and (max-width: 800px) {
          .empreendedor .empreendedor-menu .line .empreendedor-menu__item {
            width: 50%; } }
        @media only screen and (max-width: 500px) {
          .empreendedor .empreendedor-menu .line .empreendedor-menu__item {
            width: 100%;
            margin: 2px; } }
        .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content {
          display: table-cell;
          vertical-align: middle; }
          .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            width: 90px;
            height: 80px;
            margin: 0 auto 20px; }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.consulta-protocolo {
              background-image: url("../images//sala-empreendedor/1.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.alvara {
              background-image: url("../images//sala-empreendedor/2.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.notafiscal {
              background-image: url("../images//sala-empreendedor/3.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.mei {
              background-image: url("../images//sala-empreendedor/4.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.entidades {
              background-image: url("../images//sala-empreendedor/5.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.autonomo {
              background-image: url("../images//sala-empreendedor/6.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.jucis {
              background-image: url("../images//sala-empreendedor/7.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.mei-portal {
              background-image: url("../images//sala-empreendedor/8.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.alvara2 {
              background-image: url("../images//sala-empreendedor/9.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.bombeiros {
              background-image: url("../images//sala-empreendedor/10.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.ambiental {
              background-image: url("../images//sala-empreendedor/11.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.viabilidades {
              background-image: url("../images//sala-empreendedor/12.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.sanep {
              background-image: url("../images//sala-empreendedor/sanep.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.instru-normativas {
              background-image: url("../images//sala-empreendedor/instru-normativas.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.edificapel {
              background-image: url("../images//sala-empreendedor/edificapel.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.vigilancia {
              background-image: url("../images//sala-empreendedor/vigi-sanitaria.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.sebrae {
              background-image: url("../images//sala-empreendedor/sebrae.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.legislacao {
              background-image: url("../images//sala-empreendedor/legislacao.png"); }
            .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .image.legislacao-fim {
              background-image: url("../images//sala-empreendedor/legislacao-fim.png"); }
          .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .label {
            font-family: 'NexaBold';
            font-size: 16px;
            color: #5A5C65;
            text-transform: uppercase;
            opacity: .75;
            display: block;
            text-align: center; }
          .empreendedor .empreendedor-menu .line .empreendedor-menu__item .content .description {
            font-family: 'Arial';
            font-size: 12px;
            color: #666666;
            display: block;
            text-align: center;
            line-height: 18px;
            width: 90%;
            margin: 30px auto 0; }
  .empreendedor .icon-smile {
    width: 100%;
    overflow: hidden;
    margin-top: 90px;
    margin-bottom: 20px; }
    .empreendedor .icon-smile .icon {
      background: url("../images/icon_smile.png") center no-repeat;
      float: right;
      display: block;
      width: 82px;
      height: 80px; }

.news {
  position: relative;
  min-width: 906px;
  max-width: 935px;
  width: auto;
  padding: 100px 0; }
  .news iframe {
    width: 100%; }
  .news object {
    width: 100%;
    border-bottom: 1px solid gainsboro;
    height: 600px; }
  .news .container {
    position: relative; }
    .news .container.small {
      width: 906px; }
    .news .container.big {
      width: 935px; }
    .news .container .borderBottom {
      display: block;
      width: 100%;
      height: 1px;
      background-color: #DDDDDD;
      margin-top: 15px; }
    .news .container .text, .news .container p {
      font-family: 'Arial';
      font-size: 15px;
      font-weight: 100;
      color: #343434;
      line-height: 22px;
      font-weight: lighter;
      margin-bottom: 37px; }
    .news .container .marginTop30 {
      margin-top: 30px; }
    .news .container .marginTop45 {
      margin-top: 45px; }
    .news .container .marginTop60 {
      margin-top: 60px; }
  .news .headline {
    position: relative;
    width: 100%; }
    .news .headline.left-border {
      padding: 10px 0 0 25px; }
      .news .headline.left-border:before {
        content: '';
        display: block;
        width: 6px;
        height: 150px;
        background-color: #222222;
        position: absolute;
        top: 0;
        left: 0; }
    .news .headline .breadcrumb {
      font-family: 'NexaBold';
      font-size: 13px;
      color: #FF5400;
      text-transform: uppercase; }
    .news .headline .title {
      font-family: 'NexaThin';
      font-size: 45px;
      color: #222222;
      line-height: 48px; }
      .news .headline .title.size30 {
        font-size: 30px;
        line-height: 33px; }
      .news .headline .title.size20 {
        font-size: 20px;
        line-height: 23px; }
      .news .headline .title.bold {
        font-family: 'NexaBold'; }
    .news .headline .description {
      font-family: 'Arial';
      font-size: 15px;
      font-weight: 100;
      color: #343434;
      line-height: 22px;
      margin: 10px 0 0; }
    .news .headline .headline-info {
      font-family: 'Arial';
      font-size: 13px;
      font-weight: 100;
      color: #343434;
      line-height: 18px;
      margin-top: 25px; }
      .news .headline .headline-info .name {
        font-family: 'NexaBold';
        color: #FF5400; }
      .news .headline .headline-info .date {
        display: block;
        font-size: 11px; }
    .news .headline .social-icons {
      margin-top: 45px;
      margin-bottom: 10px;
      overflow: hidden; }
      .news .headline .social-icons .icon {
        background-image: url("../images/sprite-social.png");
        background-repeat: no-repeat;
        display: block;
        float: left;
        width: 65px;
        height: 45px;
        margin-right: 6px; }
        .news .headline .social-icons .icon.facebook {
          background-position: 0; }
        .news .headline .social-icons .icon.twitter {
          background-position: -70px; }
        .news .headline .social-icons .icon.plus {
          background-position: -140px; }
        .news .headline .social-icons .icon.linkedin {
          background-position: -210px; }
        .news .headline .social-icons .icon.whatsapp {
          background-position: -280px; }
        .news .headline .social-icons .icon.email {
          background-position: -350px; }
  .news .container-image.full {
    width: 100%; }
  .news .container-image .image.gif {
    max-width: 700px; }
  .news .container-image .info {
    overflow: hidden;
    margin-top: 10px; }
    .news .container-image .info .subtitle {
      font-family: 'Arial';
      font-weight: 100;
      font-size: 11px;
      color: #343434; }
    .news .container-image .info .author {
      font-family: 'Arial';
      font-weight: 100;
      font-size: 13px;
      color: #343434;
      background: url("../images/icon_camera.png") center left no-repeat;
      padding-left: 20px; }
      .news .container-image .info .author .name {
        font-family: 'NexaBold';
        font-size: 15px; }
  .news .container-text {
    padding-left: 25px;
    margin-top: 75px;
    width: 735px; }
    .news .container-text img {
      width: 100%; }
    .news .container-text a {
      color: #FF9900;
      text-decoration: none;
      font-weight: bold; }
    .news .container-text ul {
      margin: 0;
      -webkit-margin-start: 0px;
      -webkit-margin-end: 0px;
      -webkit-padding-start: 40px; }
      .news .container-text ul li {
        font-family: Roboto, sans-serif;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 37px;
        list-style: disc;
        text-align: -webkit-match-parent; }
    .news .container-text h1 {
      font-size: 40px;
      line-height: 43px; }
    .news .container-text h2 {
      font-size: 30px;
      line-height: 33px; }
    .news .container-text h3 {
      font-size: 20px;
      line-height: 23px; }
    .news .container-text h6 {
      font-size: 11px; }
    .news .container-text h1, .news .container-text h2, .news .container-text h3 {
      font-family: 'NexaThin';
      color: #222222;
      margin-bottom: 37px; }
    .news .container-text h6 {
      color: #666666;
      margin: 5px 0px 37px 0;
      font-family: Roboto, sans-serif;
      font-weight: 100; }
    .news .container-text blockquote {
      position: relative;
      width: 560px;
      border-left: 6px solid #222222;
      padding: 20px 0 20px 20px;
      font-family: 'ClearfaceRegular';
      font-size: 20px;
      line-height: 35px;
      color: #666666;
      margin: 0px 0px 37px 20px; }
  .news .container-facebook {
    margin: 20px 0 20px 25px; }
  .news .container-audio {
    margin: 0; }
    .news .container-audio audio {
      width: 100%; }
  .news .container-media {
    margin: 0; }
    .news .container-media video {
      width: 100%; }
    .news .container-media #youtube-player {
      width: 700px;
      height: 394px; }
  .news .container-tags {
    padding-top: 55px;
    padding-left: 25px;
    padding-bottom: 70px; }
    .news .container-tags .title {
      font-family: 'NexaBold';
      font-size: 20px;
      color: #222222;
      text-transform: uppercase;
      margin-bottom: 5px; }
    .news .container-tags .tag-list {
      font-family: 'Arial';
      font-size: 15px;
      color: #666666; }
      .news .container-tags .tag-list .tag {
        font-weight: bolder;
        font-style: italic;
        color: #F4A14E; }
  .news .related-news {
    width: 100%; }
    .news .related-news__item {
      display: block;
      width: 24.2%;
      float: left;
      box-shadow: 0 0 2px gray;
      margin-right: 1%; }
      .news .related-news__item:last-child {
        margin-right: 0; }
      .news .related-news__item .image {
        background-image: url("../images/imagempelotas.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 100%;
        height: 147px; }
      .news .related-news__item .info {
        padding: 15px 20px;
        height: 63px; }
        .news .related-news__item .info .title {
          font-family: 'NexaBold';
          font-size: 14px;
          color: #727279;
          line-height: 16px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; }
        .news .related-news__item .info .description {
          font-family: 'Arial';
          font-weight: lighter;
          font-size: 11px;
          color: #666666;
          display: block;
          line-height: 16px;
          margin-top: 15px; }
      .news .related-news__item .read-more {
        font-family: 'NexaBold';
        font-size: 16px;
        color: #F4A14E;
        display: block;
        width: 100%;
        height: 43px;
        line-height: 43px;
        text-transform: uppercase;
        text-align: center;
        border-top: 1px solid #E9E9E9; }
  .news .gallery .gallery-top {
    width: 700px;
    height: 466px;
    margin: 30px 0 0 0px; }
  .news .gallery .gallery-thumbs {
    width: 700px;
    height: 112px;
    margin: 8px 0 0 0px; }
    .news .gallery .gallery-thumbs .swiper-slide {
      width: 169px;
      height: 112px; }
  .news .gallery .swiper-slide {
    background-size: cover; }
  .news .gallery .swiper-button-next,
  .news .gallery .swiper-button-prev {
    background: url("../images/slide-arrow.png") center no-repeat;
    background-color: black;
    opacity: .5;
    transition: opacity .3s ease-in-out;
    height: 100%;
    bottom: 0;
    top: auto; }
    .news .gallery .swiper-button-next:hover,
    .news .gallery .swiper-button-prev:hover {
      opacity: 0.7; }
  .news .gallery .swiper-button-prev {
    margin-left: -10px;
    transform: rotate(180deg); }
  .news .gallery .swiper-button-next {
    margin-right: -10px; }
  @media only screen and (max-width: 768px) {
    .news .displayer {
      display: none; } }
  .news .mobile-back {
    display: none; }
  .news.tempMobile {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding: 5px 0 25px; }
    .news.tempMobile .mobile-back {
      display: block;
      margin: 25px 0;
      padding-left: 25px; }
    .news.tempMobile .container.small {
      width: 100%; }
    .news.tempMobile .container.big {
      width: 100%; }
    .news.tempMobile .container .text, .news.tempMobile .container p {
      font-size: 11px;
      line-height: 16px;
      font-weight: 400 !important; }
    .news.tempMobile .container .headline .breadcrumb {
      font-size: 11px; }
    .news.tempMobile .container .headline .title {
      font-size: 34px;
      margin-top: 15px;
      line-height: 34px; }
    .news.tempMobile .container .headline .description {
      font-size: 14px;
      line-height: 18px; }
    .news.tempMobile .container .headline .social-icons {
      width: 215px; }
      .news.tempMobile .container .headline .social-icons a {
        margin-bottom: 5px; }
    .news.tempMobile .container .container-tags {
      padding-top: 25px;
      padding-bottom: 10px; }
      .news.tempMobile .container .container-tags .tag-list {
        margin-bottom: 0; }
    .news.tempMobile .container .related-news__item {
      width: 100%;
      max-width: 220px;
      margin-right: 0;
      margin-bottom: 15px; }
      .news.tempMobile .container .related-news__item .info {
        height: auto; }
        .news.tempMobile .container .related-news__item .info .title {
          margin: 0;
          font-size: 18px;
          line-height: 22px; }
    .news.tempMobile .container .container-text {
      width: 100%;
      margin-top: 25px;
      padding-right: 25px; }
      .news.tempMobile .container .container-text img {
        margin-bottom: 25px; }
      .news.tempMobile .container .container-text video {
        margin-bottom: 25px; }
      .news.tempMobile .container .container-text audio {
        margin-bottom: 25px; }
      .news.tempMobile .container .container-text blockquote {
        width: 100%;
        font-size: 18px;
        line-height: 28px;
        padding: 15px;
        margin: 0 0 20px; }
    .news.tempMobile .gallery .gallery-top {
      width: 100%;
      height: 170px;
      margin: 25px 0 0; }
      @media (min-width: 360px) {
        .news.tempMobile .gallery .gallery-top {
          height: 197px; } }
      @media (min-width: 375px) {
        .news.tempMobile .gallery .gallery-top {
          height: 206px; } }
      @media (min-width: 412px) {
        .news.tempMobile .gallery .gallery-top {
          height: 231px; } }
      @media (min-width: 414px) {
        .news.tempMobile .gallery .gallery-top {
          height: 232px; } }
      @media (min-width: 768px) {
        .news.tempMobile .gallery .gallery-top {
          height: 457px; } }
    .news.tempMobile .gallery .gallery-thumbs {
      display: none;
      width: 100%; }

.preloader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #F9F9F9;
  min-width: 1024px; }
  .preloader .center {
    width: 500px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .preloader .center .logo {
      display: block;
      margin: 0 auto; }
    .preloader .center .bar {
      position: relative;
      width: 100%;
      height: 30px;
      margin-top: 30px;
      background: #aaa;
      padding: 3px;
      border-radius: 30px; }
      .preloader .center .bar .progress {
        display: block;
        width: 0%;
        height: 24px;
        background-color: #F4A14E;
        border-radius: 30px; }
    .preloader .center .loading-text {
      display: block;
      text-align: center;
      margin-top: 20px;
      font-family: 'NexaBold';
      font-size: 16px;
      color: #F4A14E;
      text-transform: uppercase; }
    .preloader .center .percent {
      display: block;
      text-align: center;
      margin-top: 10px;
      font-family: 'NexaBold';
      font-size: 16px;
      color: #F4A14E; }
  .preloader .cache {
    display: none; }

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/futura.ttf"); }

@font-face {
  font-family: "Futura MD";
  src: url("../fonts/Futura/futura_medium.ttf"); }

@font-face {
  font-family: "Futura Bold Cond";
  src: url("../fonts/Futura/Futura_bold_md.ttf"); }

* {
  margin: 0;
  padding: 0; }

body {
  font-family: 'Signika', sans-serif;
  font-size: 18px;
  color: #000;
  background-size: 85%;
  /*background-position: center;*/
  background-repeat: no-repeat; }

header {
  background-color: #21a95f;
  border-radius: 0 0 0 600px;
  height: 550px; }
  header .navbar-menu a {
    color: white !important;
    font-family: "Futura MD" !important;
    font-size: 12px; }
    header .navbar-menu a img {
      width: 120px; }
  header .people-area div:nth-child(1) {
    padding-right: 100px; }
    header .people-area div:nth-child(1) img {
      width: 280px; }
  header .people-area div:nth-child(2) img {
    width: 340px; }

section.programa-economia {
  margin-top: 200px;
  font-family: "Futura Bold Cond" !important; }
  section.programa-economia p {
    font-family: "Futura MD" !important;
    color: #777; }

section.inscreva-unidade {
  margin-top: 200px;
  background-color: #f8c900;
  border-radius: 0 600px 0 0;
  height: 475px; }
  section.inscreva-unidade div.orange-doll {
    margin-top: 73px; }
    section.inscreva-unidade div.orange-doll img {
      width: 358px; }
  section.inscreva-unidade div.form {
    margin-top: 200px; }
  section.inscreva-unidade h4 {
    text-transform: uppercase;
    margin-left: 160px;
    margin-top: 75px;
    margin-bottom: 35px; }
    section.inscreva-unidade h4 span {
      color: white;
      font-family: 'Futura Bold Cond' !important;
      font-size: 32px; }
  section.inscreva-unidade form .submit-group-input {
    margin-left: 362px !important; }
  section.inscreva-unidade form .form-group input {
    width: 440px;
    border-radius: 20px;
    padding: 3px 18px; }
    section.inscreva-unidade form .form-group input::placeholder {
      color: #AAA; }
    section.inscreva-unidade form .form-group input.submit-input {
      padding: 0 4px 4px 4px;
      width: 80px;
      background-color: #f35300;
      color: white;
      font-family: "Futura Bold Cond" !important;
      cursor: pointer; }

section.inscreva-ideia {
  background-color: #f35300;
  border-radius: 0 0 0 270px;
  height: 550px;
  overflow: hidden; }
  section.inscreva-ideia div.orange-guy {
    margin-top: 80px; }
    section.inscreva-ideia div.orange-guy img {
      width: 340px; }
  section.inscreva-ideia div.form {
    margin-top: 200px; }
  section.inscreva-ideia h4 {
    text-transform: uppercase;
    margin-top: 75px;
    margin-bottom: 35px; }
    section.inscreva-ideia h4 span {
      color: white;
      font-family: 'Futura Bold Cond' !important;
      font-size: 32px; }
  section.inscreva-ideia form .form-group input {
    width: 440px;
    border-radius: 20px;
    padding: 3px 18px; }
    section.inscreva-ideia form .form-group input::placeholder {
      color: #AAA; }
    section.inscreva-ideia form .form-group input.submit-input {
      padding: 0 4px 4px 4px;
      width: 80px;
      background-color: #f8c900;
      color: white;
      font-family: "Futura Bold Cond" !important;
      cursor: pointer; }
  section.inscreva-ideia form .form-group textarea {
    border-radius: 20px;
    padding: 3px 18px; }
    section.inscreva-ideia form .form-group textarea::placeholder {
      color: #AAA; }

footer.e-da-minha-conta-footer {
  height: 70px; }
  footer.e-da-minha-conta-footer img {
    width: 120px; }

.confirm-message {
  background-color: #21a95f;
  width: 100%;
  height: 100%;
  color: white;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 3rem;
  display: none; }
  .confirm-message p {
    display: inline-block;
    vertical-align: middle;
    line-height: normal; }

div.div-logo-pref {
  width: 100px !important; }

.center {
  text-align: center; }

@media only screen and (max-width: 480px) {
  header {
    border-radius: 0 0 0 140px !important; }
    header .people-area div img {
      width: 235px; }
  section.programa-economia {
    margin-top: 75px;
    width: 100%;
    font-family: "Futura Bold Cond" !important; }
    section.programa-economia h1 {
      font-size: 18px; }
    section.programa-economia h3 {
      font-size: 16px; }
    section.programa-economia p {
      font-family: "Futura MD" !important;
      color: #777;
      width: 100%; }
    section.programa-economia .click-download img {
      width: 100%; }
  section.inscreva-unidade {
    margin-top: 200px;
    background-color: #f8c900;
    border-radius: 0 200px 0 0;
    height: 575px; }
    section.inscreva-unidade div.orange-doll {
      margin-top: -153px; }
      section.inscreva-unidade div.orange-doll img {
        width: 240px; }
    section.inscreva-unidade div.form {
      margin-top: 200px; }
    section.inscreva-unidade h4 {
      text-transform: uppercase;
      margin-left: 40px;
      margin-top: 25px;
      margin-bottom: 35px; }
      section.inscreva-unidade h4 span {
        color: white;
        font-family: 'Futura Bold Cond' !important;
        font-size: 32px; }
    section.inscreva-unidade form .submit-group-input {
      text-align: right !important;
      margin-right: 5%;
      margin-left: 0 !important; }
    section.inscreva-unidade form .form-group {
      text-align: center; }
      section.inscreva-unidade form .form-group input {
        width: 90%;
        border-radius: 20px;
        padding: 3px 18px; }
        section.inscreva-unidade form .form-group input::placeholder {
          color: #AAA; }
        section.inscreva-unidade form .form-group input.submit-input {
          padding: 0 4px 4px 4px;
          width: 80px;
          background-color: #f35300;
          color: white;
          font-family: "Futura Bold Cond" !important;
          cursor: pointer; }
  section.inscreva-ideia {
    background-color: #f35300;
    border-radius: 0 0 0 175px;
    height: 770px;
    overflow: hidden; }
    section.inscreva-ideia div.orange-guy {
      margin-top: 10px; }
      section.inscreva-ideia div.orange-guy img {
        width: 190px; }
    section.inscreva-ideia div.form {
      margin-top: 200px; }
    section.inscreva-ideia h4 {
      text-transform: uppercase;
      margin-top: 5px;
      margin-bottom: 35px; }
      section.inscreva-ideia h4 span {
        color: white;
        font-family: 'Futura Bold Cond' !important;
        font-size: 32px; }
    section.inscreva-ideia form {
      text-align: center; }
      section.inscreva-ideia form .form-group .submit-group-input {
        text-align: right !important;
        margin-right: 5%;
        margin-left: 0 !important; }
      section.inscreva-ideia form .form-group input {
        width: 90%;
        border-radius: 20px;
        padding: 3px 18px; }
        section.inscreva-ideia form .form-group input::placeholder {
          color: #AAA; }
        section.inscreva-ideia form .form-group input.submit-input {
          padding: 0 4px 4px 4px;
          width: 80px;
          background-color: #f8c900;
          color: white;
          font-family: "Futura Bold Cond" !important;
          cursor: pointer; }
      section.inscreva-ideia form .form-group textarea {
        width: 90%;
        border-radius: 20px;
        padding: 3px 18px; }
        section.inscreva-ideia form .form-group textarea::placeholder {
          color: #AAA; } }

.header2 {
  position: relative;
  width: 100%;
  overflow: visible; }
  .header2 .banner {
    position: relative;
    width: 100%;
    height: 420px;
    z-index: 5;
    background-image: url("../images//banners/banner1.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover; }
    .header2 .banner .video {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 420px;
      overflow: hidden; }
      .header2 .banner .video .bg_video {
        position: absolute;
        top: 0;
        left: 0;
        width: 1920px;
        height: 1080px;
        min-height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1; }
    .header2 .banner .search {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) translateZ(0);
      z-index: 99; }
      .header2 .banner .search__text {
        position: relative;
        margin: 0 auto;
        width: 960px; }
        @media (max-width: 1366px) {
          .header2 .banner .search__text {
            width: 850px; } }
        .header2 .banner .search__text .text {
          display: block;
          width: 100%;
          height: 80px;
          background-color: white;
          padding: 0 20px;
          color: #727279;
          font-family: 'NexaLight';
          font-size: 26px; }
          .header2 .banner .search__text .text ::-webkit-input-placeholder {
            color: #727279;
            font-family: 'NexaLight';
            font-size: 26px; }
          .header2 .banner .search__text .text ::-moz-placeholder {
            color: #727279;
            font-family: 'NexaLight';
            font-size: 26px; }
          .header2 .banner .search__text .text :-ms-input-placeholder {
            color: #727279;
            font-family: 'NexaLight';
            font-size: 26px; }
          .header2 .banner .search__text .text :-moz-placeholder {
            color: #727279;
            font-family: 'NexaLight';
            font-size: 26px; }
          @media (max-width: 1366px) {
            .header2 .banner .search__text .text {
              height: 65px; } }
        .header2 .banner .search__text .search-button {
          position: absolute;
          top: -5px;
          right: 5px;
          display: block;
          width: 90px;
          height: 90px;
          border: none;
          background-image: url("../images/icon_search.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: rgba(255, 255, 255, 0); }
          @media (max-width: 1366px) {
            .header2 .banner .search__text .search-button {
              width: 75px;
              height: 75px; } }
      .header2 .banner .search__buttons {
        position: relative;
        margin: 0 auto;
        width: 960px;
        margin-top: 5px; }
        @media (max-width: 1366px) {
          .header2 .banner .search__buttons {
            width: 850px; } }
  .header2 .scroll-down {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 15px;
    margin-left: -15px;
    z-index: 3; }
    .header2 .scroll-down p {
      text-align: center;
      font-size: 14px;
      color: #fff;
      font-family: 'NexaLight';
      line-height: 20px; }
    .header2 .scroll-down .icon_mouse {
      background: url("../images/icon_mouse.png") center no-repeat;
      display: block;
      width: 100%;
      height: 52px;
      margin-bottom: 5px; }
    .header2 .scroll-down .icon_arrow {
      background: url("../images/icon_mouse_arrow.png") center no-repeat;
      display: block;
      width: 16px;
      height: 10px;
      margin: 0 auto;
      animation-fill-mode: forwards; }
      .header2 .scroll-down .icon_arrow.arrow1 {
        animation: arrow 1s ease-in-out infinite; }
      .header2 .scroll-down .icon_arrow.arrow2 {
        animation: arrow 1s ease-in-out infinite .3s; }

@keyframes arrow {
  0% {
    opacity: 1; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

.buscador .hidden {
  display: none; }

@media only screen and (max-width: 1023px) {
  .buscador .title {
    font-size: 20px !important; } }

.buscador .headline {
  position: relative;
  width: 100%; }
  .buscador .headline.left-border {
    padding: 10px 0 0 25px; }
    .buscador .headline.left-border:before {
      content: '';
      display: block;
      width: 6px;
      height: 110px;
      background-color: #222222;
      position: absolute;
      top: 0;
      left: 0; }
  .buscador .headline .breadcrumb {
    font-family: 'NexaBold';
    font-size: 13px;
    color: #FF5400;
    text-transform: uppercase; }
  .buscador .headline .title {
    font-family: 'NexaThin';
    font-size: 45px;
    color: #222222;
    line-height: 48px; }
    @media only screen and (max-width: 1023px) {
      .buscador .headline .title {
        font-size: 16px; } }
    .buscador .headline .title.size30 {
      font-size: 30px;
      line-height: 33px; }
    .buscador .headline .title.size20 {
      font-size: 20px;
      line-height: 23px; }
    .buscador .headline .title.bold {
      font-family: 'NexaBold'; }
  .buscador .headline .description {
    font-family: 'Arial';
    font-size: 15px;
    font-weight: 100;
    color: #343434;
    line-height: 22px;
    margin: 10px 0 0; }
  .buscador .headline .headline-info {
    font-family: 'Arial';
    font-size: 13px;
    font-weight: 100;
    color: #343434;
    line-height: 18px;
    margin-top: 25px; }
    .buscador .headline .headline-info .name {
      font-family: 'NexaBold';
      color: #FF5400; }
    .buscador .headline .headline-info .date {
      display: block;
      font-size: 11px; }

.buscador .search__controllers {
  position: relative;
  display: block;
  width: 100%;
  float: right; }
  .buscador .search__controllers.padding {
    padding: 40px 20px 120px; }
    @media only screen and (max-width: 1023px) {
      .buscador .search__controllers.padding {
        padding: 10px 5px 130px; } }
  .buscador .search__controllers .search__controll {
    margin: 10px 10px; }
    @media only screen and (max-width: 1023px) {
      .buscador .search__controllers .search__controll .search__order {
        display: block; } }
    .buscador .search__controllers .search__controll .paginator {
      width: 100%;
      display: block;
      overflow-x: scroll;
      padding: 10px 0; }
      .buscador .search__controllers .search__controll .paginator .paginator__button {
        margin: 0 0.5px; }
    .buscador .search__controllers .search__controll p {
      font-family: 'NexaBold';
      font-size: 16px;
      margin-bottom: 8px; }
    .buscador .search__controllers .search__controll input[name='url'] {
      display: inline-block;
      padding: 12.5px 12.5px 12.5px 12.5px;
      color: #727279;
      font-family: 'NexaLight';
      font-size: 16px;
      background-color: #EEEEEE !important;
      width: 50%; }
    .buscador .search__controllers .search__controll .copyurl {
      padding: 12.5px 12.5px 12.5px 12.5px;
      opacity: 0.8; }
    .buscador .search__controllers .search__controll a {
      margin: 10px 0;
      padding: 5px 10px;
      background-color: #F4A14E;
      color: white;
      font-family: 'NexaLight';
      cursor: pointer; }
      .buscador .search__controllers .search__controll a:hover {
        opacity: 0.7;
        transition: 0.3s all; }
      .buscador .search__controllers .search__controll a.selected {
        background-color: #F4A14E;
        font-family: 'NexaBold';
        border: 2px solid #333;
        border-radius: 3px; }

.buscador .search__results {
  background-color: transparent;
  max-height: 1000px;
  width: 100%;
  position: relative;
  top: 0;
  margin-top: 15px; }
  @media only screen and (max-width: 800px) {
    .buscador .search__results {
      max-height: 400px; } }
  .buscador .search__results .result {
    border-bottom: 1px solid #e2e2e2;
    font-family: 'NexaLight';
    margin: 0;
    padding: 20px;
    width: 100%; }
    .buscador .search__results .result:hover {
      background-color: #F8F8F8; }
    .buscador .search__results .result span {
      color: #FF5400;
      display: block;
      font-family: 'NexaBold';
      font-size: 14px;
      padding: 2px 0; }
    .buscador .search__results .result a {
      color: #666666;
      font-size: 16px;
      cursor: pointer; }
      .buscador .search__results .result a.description {
        font-size: 12px; }

.buscador ::-webkit-scrollbar-track {
  background-color: #F4F4F4; }

.buscador ::-webkit-scrollbar {
  width: 6px;
  background: #F4F4F4; }

.buscador ::-webkit-scrollbar-thumb {
  background: #dad7d7; }

.buscador .right {
  float: right; }
  .buscador .right .search__controllers {
    background-color: #F4F4F4; }

.buscador .left {
  float: left; }

.buscador .content {
  width: 50%;
  padding: 10px; }
  @media only screen and (max-width: 900px) {
    .buscador .content {
      width: 100%; } }

.buscador .search__advanced {
  max-height: 1000px;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  overflow: visible; }
  .buscador .search__advanced .search__advanced_fields {
    padding: 10px 0;
    background-color: rgba(0, 0, 0, 0); }
    .buscador .search__advanced .search__advanced_fields .form input[type='text'] {
      background-color: #EEEEEE !important;
      width: 98%;
      margin: 10px; }
      @media only screen and (max-width: 1023px) {
        .buscador .search__advanced .search__advanced_fields .form input[type='text'] {
          padding-right: 0px; } }
    .buscador .search__advanced .search__advanced_fields .form button {
      border: none;
      margin: 10px;
      padding: 10px 35px;
      background-color: #F4A14E;
      color: white;
      font-family: 'NexaBold';
      font-size: 18px;
      cursor: pointer; }
      .buscador .search__advanced .search__advanced_fields .form button:hover {
        opacity: 0.7;
        transition: 0.3s all; }
    .buscador .search__advanced .search__advanced_fields .form p {
      margin: 10px 10px;
      font-family: 'NexaLight';
      font-size: 14px; }
    .buscador .search__advanced .search__advanced_fields .form input[type='date'] {
      width: 98%;
      margin: 4px 10px; }
    .buscador .search__advanced .search__advanced_fields .form select {
      width: 98%;
      margin: 4px 10px; }
    .buscador .search__advanced .search__advanced_fields .form .option {
      margin: 0px 10px; }

.search__results {
  background-color: #F4F4F4;
  display: block;
  max-height: 0px;
  overflow: hidden;
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  transition: max-height 0.8s;
  width: 90.6%;
  position: absolute;
  top: 65px;
  z-index: 3 !important; }
  .search__results p {
    display: none;
    color: #666666;
    font-family: 'NexaBold';
    font-size: 16px;
    padding: 10px; }
    .search__results p.active {
      display: block; }
  .search__results.active {
    max-height: 300px; }
  .search__results .results_all {
    float: left;
    width: 57.5%;
    overflow-y: scroll;
    max-height: 300px; }
    .search__results .results_all::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #F5F5F5; }
    .search__results .results_all::-webkit-scrollbar {
      width: 8px;
      background-color: #F5F5F5; }
    .search__results .results_all::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: orange; }
    .search__results .results_all .result {
      display: none;
      border-bottom: 1px solid #e2e2e2;
      font-family: 'NexaLight';
      margin: 10px;
      padding: 10px; }
      .search__results .results_all .result.active {
        display: block; }
      .search__results .results_all .result span {
        color: #FF5400;
        display: block;
        font-family: 'NexaBold';
        font-size: 14px;
        padding: 2px 0; }
      .search__results .results_all .result a {
        color: #666666;
        font-size: 16px; }
  .search__results .results__sec {
    display: none;
    background-color: #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    float: right;
    font-family: 'NexaLight';
    height: 300px;
    padding: 20px;
    width: 41%; }
    .search__results .results__sec.active {
      display: block; }
    .search__results .results__sec span {
      display: block;
      color: #FF5400;
      font-family: 'NexaBold';
      font-size: 14px;
      padding: 2px 0; }
    .search__results .results__sec .info {
      margin-top: 30px; }
      .search__results .results__sec .info span {
        font-size: 14px;
        font-family: 'NexaLight';
        color: #666666; }
    .search__results .results__sec a {
      font-family: 'NexaBold';
      color: #666666;
      font-size: 18px; }

div.search__advanced {
  background-color: white;
  display: block;
  max-height: 0px;
  overflow: hidden;
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  transition: max-height 0.8s;
  width: 90.6%;
  position: absolute;
  z-index: 3 !important; }
  div.search__advanced.active {
    max-height: 300px; }
  div.search__advanced .search__advanced_fields {
    background-color: #F4F4F4;
    height: 300px;
    padding: 40px 20px; }
    div.search__advanced .search__advanced_fields h1 {
      color: #ACACB2;
      font-family: 'NexaBold';
      font-size: 18px;
      padding-left: 30px; }
    div.search__advanced .search__advanced_fields .form input[name='tags'] {
      background-color: #EEEEEE !important;
      margin: 5px 10px;
      width: 96%; }
    div.search__advanced .search__advanced_fields .form select {
      width: 47%;
      margin: 0 10px; }
    div.search__advanced .search__advanced_fields .form input[type='date'] {
      /*-moz-appearance: textfield;
   				appearance: textfield;*/
      float: left;
      width: 47%;
      margin: 0px 10px;
      font-family: 'NexaLight';
      font-size: 14px;
      color: #727279;
      padding: 12px;
      background-color: #F4F4F4;
      padding-left: 40px;
      -webkit-appearance: none;
      /* Remove estilo padrão do Chrome */
      -moz-appearance: none;
      /* Remove estilo padrão do FireFox */
      appearance: none;
      /* Remove estilo padrão do FireFox*/
      background: url("../images/icon.select.jpg") no-repeat #eeeeee;
      /* Imagem de fundo (Seta) */
      background-position: 100% center;
      /*Posição da imagem do background*/ }
      div.search__advanced .search__advanced_fields .form input[type='date']::-webkit-calendar-picker-indicator {
        display: none; }
      div.search__advanced .search__advanced_fields .form input[type='date']::-webkit-inner-spin-button {
        -webkit-appearance: none; }
    div.search__advanced .search__advanced_fields .form .option {
      margin: 20px 0px; }

.search__buttons a.search__advanced {
  color: white;
  font-size: 14px;
  float: right;
  margin: 15px 0px;
  font-family: 'NexaBold'; }

.search__buttons a.search__advanced:hover {
  color: #999999; }

.w3-image {
  max-width: 100%;
  height: auto; }

img {
  vertical-align: middle; }

a {
  color: inherit; }

.w3-table, .w3-table-all {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table; }

.w3-table-all {
  border: 1px solid #ccc; }

.w3-bordered tr, .w3-table-all tr {
  border-bottom: 1px solid #ddd; }

.w3-striped tbody tr:nth-child(even) {
  background-color: #f1f1f1; }

.w3-table-all tr:nth-child(odd) {
  background-color: #fff; }

.w3-table-all tr:nth-child(even) {
  background-color: #f1f1f1; }

.w3-hoverable tbody tr:hover, .w3-ul.w3-hoverable li:hover {
  background-color: #ccc; }

.w3-centered tr th, .w3-centered tr td {
  text-align: center; }

.w3-table td, .w3-table th, .w3-table-all td, .w3-table-all th {
  padding: 8px 8px;
  display: table-cell;
  text-align: left;
  vertical-align: top; }

.w3-table th:first-child, .w3-table td:first-child, .w3-table-all th:first-child, .w3-table-all td:first-child {
  padding-left: 16px; }

.w3-btn, .w3-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap; }

.w3-btn:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.w3-btn, .w3-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.w3-disabled, .w3-btn:disabled, .w3-button:disabled {
  cursor: not-allowed;
  opacity: 0.3; }

.w3-disabled *, :disabled * {
  pointer-events: none; }

.w3-btn.w3-disabled:hover, .w3-btn:disabled:hover {
  box-shadow: none; }

.w3-badge, .w3-tag {
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center; }

.w3-badge {
  border-radius: 50%; }

.w3-ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.w3-ul li {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd; }

.w3-ul li:last-child {
  border-bottom: none; }

.w3-tooltip, .w3-display-container {
  position: relative; }

.w3-tooltip .w3-text {
  display: none; }

.w3-tooltip:hover .w3-text {
  display: inline-block; }

.w3-ripple:active {
  opacity: 0.5; }

.w3-ripple {
  transition: opacity 0s; }

.w3-input {
  padding: 8px;
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%; }

.w3-select {
  padding: 9px 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc; }

.w3-dropdown-click, .w3-dropdown-hover {
  position: relative;
  display: inline-block;
  cursor: pointer; }

.w3-dropdown-hover:hover .w3-dropdown-content {
  display: block; }

.w3-dropdown-hover:first-child, .w3-dropdown-click:hover {
  background-color: #ccc;
  color: #000; }

.w3-dropdown-hover:hover > .w3-button:first-child, .w3-dropdown-click:hover > .w3-button:first-child {
  background-color: #ccc;
  color: #000; }

.w3-dropdown-content {
  cursor: auto;
  color: #000;
  background-color: #fff;
  display: none;
  position: absolute;
  min-width: 160px;
  margin: 0;
  padding: 0;
  z-index: 1; }

.w3-check, .w3-radio {
  width: 24px;
  height: 24px;
  position: relative;
  top: 6px; }

.w3-sidebar {
  height: 100%;
  width: 200px;
  background-color: #fff;
  position: fixed !important;
  z-index: 1;
  overflow: auto; }

.w3-bar-block .w3-dropdown-hover, .w3-bar-block .w3-dropdown-click {
  width: 100%; }

.w3-bar-block .w3-dropdown-hover .w3-dropdown-content, .w3-bar-block .w3-dropdown-click .w3-dropdown-content {
  min-width: 100%; }

.w3-bar-block .w3-dropdown-hover .w3-button, .w3-bar-block .w3-dropdown-click .w3-button {
  width: 100%;
  text-align: left;
  padding: 8px 16px; }

.w3-main, #main {
  transition: margin-left .4s; }

.w3-modal {
  z-index: 3;
  display: none;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4); }

.w3-modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 600px; }

.w3-bar {
  width: 100%;
  overflow: hidden; }

.w3-center .w3-bar {
  display: inline-block;
  width: auto; }

.w3-bar .w3-bar-item {
  padding: 8px 16px;
  float: left;
  width: auto;
  border: none;
  display: block;
  outline: 0; }

.w3-bar .w3-dropdown-hover, .w3-bar .w3-dropdown-click {
  position: static;
  float: left; }

.w3-bar .w3-button {
  white-space: normal; }

.w3-bar-block .w3-bar-item {
  width: 100%;
  display: block;
  padding: 8px 16px;
  text-align: left;
  border: none;
  white-space: normal;
  float: none;
  outline: 0; }

.w3-bar-block.w3-center .w3-bar-item {
  text-align: center; }

.w3-block {
  display: block;
  width: 100%; }

.w3-responsive {
  display: block;
  overflow-x: auto; }

.w3-container:after, .w3-container:before, .w3-panel:after, .w3-panel:before, .w3-row:after, .w3-row:before, .w3-row-padding:after, .w3-row-padding:before,
.w3-cell-row:before, .w3-cell-row:after, .w3-clear:after, .w3-clear:before, .w3-bar:before, .w3-bar:after {
  content: "";
  display: table;
  clear: both; }

.w3-col, .w3-half, .w3-third, .w3-twothird, .w3-threequarter, .w3-quarter {
  float: left;
  width: 100%; }

.w3-col.s1 {
  width: 8.33333%; }

.w3-col.s2 {
  width: 16.66666%; }

.w3-col.s3 {
  width: 24.99999%; }

.w3-col.s4 {
  width: 33.33333%; }

.w3-col.s5 {
  width: 41.66666%; }

.w3-col.s6 {
  width: 49.99999%; }

.w3-col.s7 {
  width: 58.33333%; }

.w3-col.s8 {
  width: 66.66666%; }

.w3-col.s9 {
  width: 74.99999%; }

.w3-col.s10 {
  width: 83.33333%; }

.w3-col.s11 {
  width: 91.66666%; }

.w3-col.s12 {
  width: 99.99999%; }

@media (min-width: 601px) {
  .w3-col.m1 {
    width: 8.33333%; }
  .w3-col.m2 {
    width: 16.66666%; }
  .w3-col.m3, .w3-quarter {
    width: 24.99999%; }
  .w3-col.m4, .w3-third {
    width: 33.33333%; }
  .w3-col.m5 {
    width: 41.66666%; }
  .w3-col.m6, .w3-half {
    width: 49.99999%; }
  .w3-col.m7 {
    width: 58.33333%; }
  .w3-col.m8, .w3-twothird {
    width: 66.66666%; }
  .w3-col.m9, .w3-threequarter {
    width: 74.99999%; }
  .w3-col.m10 {
    width: 83.33333%; }
  .w3-col.m11 {
    width: 91.66666%; }
  .w3-col.m12 {
    width: 99.99999%; } }

@media (min-width: 993px) {
  .w3-col.l1 {
    width: 8.33333%; }
  .w3-col.l2 {
    width: 16.66666%; }
  .w3-col.l3 {
    width: 24.99999%; }
  .w3-col.l4 {
    width: 33.33333%; }
  .w3-col.l5 {
    width: 41.66666%; }
  .w3-col.l6 {
    width: 49.99999%; }
  .w3-col.l7 {
    width: 58.33333%; }
  .w3-col.l8 {
    width: 66.66666%; }
  .w3-col.l9 {
    width: 74.99999%; }
  .w3-col.l10 {
    width: 83.33333%; }
  .w3-col.l11 {
    width: 91.66666%; }
  .w3-col.l12 {
    width: 99.99999%; } }

.w3-content {
  max-width: 980px;
  margin: auto; }

.w3-rest {
  overflow: hidden; }

.w3-cell-row {
  display: table;
  width: 100%; }

.w3-cell {
  display: table-cell; }

.w3-cell-top {
  vertical-align: top; }

.w3-cell-middle {
  vertical-align: middle; }

.w3-cell-bottom {
  vertical-align: bottom; }

.w3-hide {
  display: none !important; }

.w3-show-block, .w3-show {
  display: block !important; }

.w3-show-inline-block {
  display: inline-block !important; }

@media (max-width: 600px) {
  .w3-modal-content {
    margin: 0 10px;
    width: auto !important; }
  .w3-modal {
    padding-top: 30px; }
  .w3-dropdown-hover.w3-mobile .w3-dropdown-content, .w3-dropdown-click.w3-mobile .w3-dropdown-content {
    position: relative; }
  .w3-hide-small {
    display: none !important; }
  .w3-mobile {
    display: block;
    width: 100% !important; }
  .w3-bar-item.w3-mobile, .w3-dropdown-hover.w3-mobile, .w3-dropdown-click.w3-mobile {
    text-align: center; }
  .w3-dropdown-hover.w3-mobile, .w3-dropdown-hover.w3-mobile .w3-btn, .w3-dropdown-hover.w3-mobile .w3-button, .w3-dropdown-click.w3-mobile, .w3-dropdown-click.w3-mobile .w3-btn, .w3-dropdown-click.w3-mobile .w3-button {
    width: 100%; } }

@media (max-width: 768px) {
  .w3-modal-content {
    width: 500px; }
  .w3-modal {
    padding-top: 50px; } }

@media (min-width: 993px) {
  .w3-modal-content {
    width: 900px; }
  .w3-hide-large {
    display: none !important; }
  .w3-sidebar.w3-collapse {
    display: block !important; } }

@media (max-width: 992px) and (min-width: 601px) {
  .w3-hide-medium {
    display: none !important; } }

@media (max-width: 992px) {
  .w3-sidebar.w3-collapse {
    display: none; }
  .w3-main {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

.w3-top, .w3-bottom {
  position: fixed;
  width: 100%;
  z-index: 1; }

.w3-top {
  top: 0; }

.w3-bottom {
  bottom: 0; }

.w3-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2; }

.w3-display-topleft {
  position: absolute;
  left: 0;
  top: 0; }

.w3-display-topright {
  position: absolute;
  right: 0;
  top: 0; }

.w3-display-bottomleft {
  position: absolute;
  left: 0;
  bottom: 0; }

.w3-display-bottomright {
  position: absolute;
  right: 0;
  bottom: 0; }

.w3-display-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); }

.w3-display-left {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%); }

.w3-display-right {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%); }

.w3-display-topmiddle {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%); }

.w3-display-bottommiddle {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%); }

.w3-display-container:hover .w3-display-hover {
  display: block; }

.w3-display-container:hover span.w3-display-hover {
  display: inline-block; }

.w3-display-hover {
  display: none; }

.w3-display-position {
  position: absolute; }

.w3-circle {
  border-radius: 50%; }

.w3-round-small {
  border-radius: 2px; }

.w3-round, .w3-round-medium {
  border-radius: 4px; }

.w3-round-large {
  border-radius: 8px; }

.w3-round-xlarge {
  border-radius: 16px; }

.w3-round-xxlarge {
  border-radius: 32px; }

.w3-row-padding, .w3-row-padding > .w3-half, .w3-row-padding > .w3-third, .w3-row-padding > .w3-twothird, .w3-row-padding > .w3-threequarter, .w3-row-padding > .w3-quarter, .w3-row-padding > .w3-col {
  padding: 0 8px; }

.w3-container, .w3-panel {
  padding: 0.01em 16px; }

.w3-panel {
  margin-top: 16px;
  margin-bottom: 16px; }

.w3-code, .w3-codespan {
  font-family: Consolas,"courier new";
  font-size: 16px; }

.w3-code {
  width: auto;
  background-color: #fff;
  padding: 8px 12px;
  border-left: 4px solid #4CAF50;
  word-wrap: break-word; }

.w3-codespan {
  color: crimson;
  background-color: #f1f1f1;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 110%; }

.w3-card, .w3-card-2 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

.w3-card-4, .w3-hover-shadow:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19); }

.w3-spin {
  animation: w3-spin 2s infinite linear; }

@keyframes w3-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.w3-animate-fading {
  animation: fading 10s infinite; }

@keyframes fading {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.w3-animate-opacity {
  animation: opac 0.8s; }

@keyframes opac {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.w3-animate-top {
  position: relative;
  animation: animatetop 0.4s; }

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

.w3-animate-left {
  position: relative;
  animation: animateleft 0.4s; }

@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0; }
  to {
    left: 0;
    opacity: 1; } }

.w3-animate-right {
  position: relative;
  animation: animateright 0.4s; }

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0; }
  to {
    right: 0;
    opacity: 1; } }

.w3-animate-bottom {
  position: relative;
  animation: animatebottom 0.4s; }

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0; }
  to {
    bottom: 0;
    opacity: 1; } }

.w3-animate-zoom {
  animation: animatezoom 0.6s; }

@keyframes animatezoom {
  from {
    transform: scale(0); }
  to {
    transform: scale(1); } }

.w3-animate-input {
  transition: width 0.4s ease-in-out; }

.w3-animate-input:focus {
  width: 100% !important; }

.w3-opacity, .w3-hover-opacity:hover {
  opacity: 0.60; }

.w3-opacity-off, .w3-hover-opacity-off:hover {
  opacity: 1; }

.w3-opacity-max {
  opacity: 0.25; }

.w3-opacity-min {
  opacity: 0.75; }

.w3-greyscale-max, .w3-grayscale-max, .w3-hover-greyscale:hover, .w3-hover-grayscale:hover {
  filter: grayscale(100%); }

.w3-greyscale, .w3-grayscale {
  filter: grayscale(75%); }

.w3-greyscale-min, .w3-grayscale-min {
  filter: grayscale(50%); }

.w3-sepia {
  filter: sepia(75%); }

.w3-sepia-max, .w3-hover-sepia:hover {
  filter: sepia(100%); }

.w3-sepia-min {
  filter: sepia(50%); }

.w3-tiny {
  font-size: 10px !important; }

.w3-small {
  font-size: 12px !important; }

.w3-medium {
  font-size: 15px !important; }

.w3-large {
  font-size: 18px !important; }

.w3-xlarge {
  font-size: 24px !important; }

.w3-xxlarge {
  font-size: 36px !important; }

.w3-xxxlarge {
  font-size: 48px !important; }

.w3-jumbo {
  font-size: 64px !important; }

.w3-left-align {
  text-align: left !important; }

.w3-right-align {
  text-align: right !important; }

.w3-justify {
  text-align: justify !important; }

.w3-center {
  text-align: center !important; }

.w3-border-0 {
  border: 0 !important; }

.w3-border {
  border: 1px solid #ccc !important; }

.w3-border-top {
  border-top: 1px solid #ccc !important; }

.w3-border-bottom {
  border-bottom: 1px solid #ccc !important; }

.w3-border-left {
  border-left: 1px solid #ccc !important; }

.w3-border-right {
  border-right: 1px solid #ccc !important; }

.w3-topbar {
  border-top: 6px solid #ccc !important; }

.w3-bottombar {
  border-bottom: 6px solid #ccc !important; }

.w3-leftbar {
  border-left: 6px solid #ccc !important; }

.w3-rightbar {
  border-right: 6px solid #ccc !important; }

.w3-section, .w3-code {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }

.w3-margin {
  margin: 16px !important; }

.w3-margin-top {
  margin-top: 16px !important; }

.w3-margin-bottom {
  margin-bottom: 16px !important; }

.w3-margin-left {
  margin-left: 16px !important; }

.w3-margin-right {
  margin-right: 16px !important; }

.w3-padding-small {
  padding: 4px 8px !important; }

.w3-padding {
  padding: 8px 16px !important; }

.w3-padding-large {
  padding: 12px 24px !important; }

.w3-padding-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.w3-padding-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.w3-padding-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important; }

.w3-padding-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important; }

.w3-padding-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important; }

.w3-left {
  float: left !important; }

.w3-right {
  float: right !important; }

.w3-button:hover {
  color: #000 !important;
  background-color: #ccc !important; }

.w3-transparent, .w3-hover-none:hover {
  background-color: transparent !important; }

.w3-hover-none:hover {
  box-shadow: none !important; }

/* Colors */
.w3-amber, .w3-hover-amber:hover {
  color: #000 !important;
  background-color: #ffc107 !important; }

.w3-aqua, .w3-hover-aqua:hover {
  color: #000 !important;
  background-color: #00ffff !important; }

.w3-blue, .w3-hover-blue:hover {
  color: #fff !important;
  background-color: #2196F3 !important; }

.w3-light-blue, .w3-hover-light-blue:hover {
  color: #000 !important;
  background-color: #87CEEB !important; }

.w3-brown, .w3-hover-brown:hover {
  color: #fff !important;
  background-color: #795548 !important; }

.w3-cyan, .w3-hover-cyan:hover {
  color: #000 !important;
  background-color: #00bcd4 !important; }

.w3-blue-grey, .w3-hover-blue-grey:hover, .w3-blue-gray, .w3-hover-blue-gray:hover {
  color: #fff !important;
  background-color: #607d8b !important; }

.w3-green, .w3-hover-green:hover {
  color: #fff !important;
  background-color: #4CAF50 !important; }

.w3-light-green, .w3-hover-light-green:hover {
  color: #000 !important;
  background-color: #8bc34a !important; }

.w3-indigo, .w3-hover-indigo:hover {
  color: #fff !important;
  background-color: #3f51b5 !important; }

.w3-khaki, .w3-hover-khaki:hover {
  color: #000 !important;
  background-color: #f0e68c !important; }

.w3-lime, .w3-hover-lime:hover {
  color: #000 !important;
  background-color: #cddc39 !important; }

.w3-orange, .w3-hover-orange:hover {
  color: #000 !important;
  background-color: #ff9800 !important; }

.w3-deep-orange, .w3-hover-deep-orange:hover {
  color: #fff !important;
  background-color: #ff5722 !important; }

.w3-pink, .w3-hover-pink:hover {
  color: #fff !important;
  background-color: #e91e63 !important; }

.w3-purple, .w3-hover-purple:hover {
  color: #fff !important;
  background-color: #9c27b0 !important; }

.w3-deep-purple, .w3-hover-deep-purple:hover {
  color: #fff !important;
  background-color: #673ab7 !important; }

.w3-red, .w3-hover-red:hover {
  color: #fff !important;
  background-color: #f44336 !important; }

.w3-sand, .w3-hover-sand:hover {
  color: #000 !important;
  background-color: #fdf5e6 !important; }

.w3-teal, .w3-hover-teal:hover {
  color: #fff !important;
  background-color: #009688 !important; }

.w3-yellow, .w3-hover-yellow:hover {
  color: #000 !important;
  background-color: #ffeb3b !important; }

.w3-white, .w3-hover-white:hover {
  color: #000 !important;
  background-color: #fff !important; }

.w3-black, .w3-hover-black:hover {
  color: #fff !important;
  background-color: #000 !important; }

.w3-grey, .w3-hover-grey:hover, .w3-gray, .w3-hover-gray:hover {
  color: #000 !important;
  background-color: #9e9e9e !important; }

.w3-light-grey, .w3-hover-light-grey:hover, .w3-light-gray, .w3-hover-light-gray:hover {
  color: #000 !important;
  background-color: #f1f1f1 !important; }

.w3-dark-grey, .w3-hover-dark-grey:hover, .w3-dark-gray, .w3-hover-dark-gray:hover {
  color: #fff !important;
  background-color: #616161 !important; }

.w3-pale-red, .w3-hover-pale-red:hover {
  color: #000 !important;
  background-color: #ffdddd !important; }

.w3-pale-green, .w3-hover-pale-green:hover {
  color: #000 !important;
  background-color: #ddffdd !important; }

.w3-pale-yellow, .w3-hover-pale-yellow:hover {
  color: #000 !important;
  background-color: #ffffcc !important; }

.w3-pale-blue, .w3-hover-pale-blue:hover {
  color: #000 !important;
  background-color: #ddffff !important; }

.w3-text-amber, .w3-hover-text-amber:hover {
  color: #ffc107 !important; }

.w3-text-aqua, .w3-hover-text-aqua:hover {
  color: #00ffff !important; }

.w3-text-blue, .w3-hover-text-blue:hover {
  color: #2196F3 !important; }

.w3-text-light-blue, .w3-hover-text-light-blue:hover {
  color: #87CEEB !important; }

.w3-text-brown, .w3-hover-text-brown:hover {
  color: #795548 !important; }

.w3-text-cyan, .w3-hover-text-cyan:hover {
  color: #00bcd4 !important; }

.w3-text-blue-grey, .w3-hover-text-blue-grey:hover, .w3-text-blue-gray, .w3-hover-text-blue-gray:hover {
  color: #607d8b !important; }

.w3-text-green, .w3-hover-text-green:hover {
  color: #4CAF50 !important; }

.w3-text-light-green, .w3-hover-text-light-green:hover {
  color: #8bc34a !important; }

.w3-text-indigo, .w3-hover-text-indigo:hover {
  color: #3f51b5 !important; }

.w3-text-khaki, .w3-hover-text-khaki:hover {
  color: #b4aa50 !important; }

.w3-text-lime, .w3-hover-text-lime:hover {
  color: #cddc39 !important; }

.w3-text-orange, .w3-hover-text-orange:hover {
  color: #ff9800 !important; }

.w3-text-deep-orange, .w3-hover-text-deep-orange:hover {
  color: #ff5722 !important; }

.w3-text-pink, .w3-hover-text-pink:hover {
  color: #e91e63 !important; }

.w3-text-purple, .w3-hover-text-purple:hover {
  color: #9c27b0 !important; }

.w3-text-deep-purple, .w3-hover-text-deep-purple:hover {
  color: #673ab7 !important; }

.w3-text-red, .w3-hover-text-red:hover {
  color: #f44336 !important; }

.w3-text-sand, .w3-hover-text-sand:hover {
  color: #fdf5e6 !important; }

.w3-text-teal, .w3-hover-text-teal:hover {
  color: #009688 !important; }

.w3-text-yellow, .w3-hover-text-yellow:hover {
  color: #d2be0e !important; }

.w3-text-white, .w3-hover-text-white:hover {
  color: #fff !important; }

.w3-text-black, .w3-hover-text-black:hover {
  color: #000 !important; }

.w3-text-grey, .w3-hover-text-grey:hover, .w3-text-gray, .w3-hover-text-gray:hover {
  color: #757575 !important; }

.w3-text-light-grey, .w3-hover-text-light-grey:hover, .w3-text-light-gray, .w3-hover-text-light-gray:hover {
  color: #f1f1f1 !important; }

.w3-text-dark-grey, .w3-hover-text-dark-grey:hover, .w3-text-dark-gray, .w3-hover-text-dark-gray:hover {
  color: #3a3a3a !important; }

.w3-border-amber, .w3-hover-border-amber:hover {
  border-color: #ffc107 !important; }

.w3-border-aqua, .w3-hover-border-aqua:hover {
  border-color: #00ffff !important; }

.w3-border-blue, .w3-hover-border-blue:hover {
  border-color: #2196F3 !important; }

.w3-border-light-blue, .w3-hover-border-light-blue:hover {
  border-color: #87CEEB !important; }

.w3-border-brown, .w3-hover-border-brown:hover {
  border-color: #795548 !important; }

.w3-border-cyan, .w3-hover-border-cyan:hover {
  border-color: #00bcd4 !important; }

.w3-border-blue-grey, .w3-hover-border-blue-grey:hover, .w3-border-blue-gray, .w3-hover-border-blue-gray:hover {
  border-color: #607d8b !important; }

.w3-border-green, .w3-hover-border-green:hover {
  border-color: #4CAF50 !important; }

.w3-border-light-green, .w3-hover-border-light-green:hover {
  border-color: #8bc34a !important; }

.w3-border-indigo, .w3-hover-border-indigo:hover {
  border-color: #3f51b5 !important; }

.w3-border-khaki, .w3-hover-border-khaki:hover {
  border-color: #f0e68c !important; }

.w3-border-lime, .w3-hover-border-lime:hover {
  border-color: #cddc39 !important; }

.w3-border-orange, .w3-hover-border-orange:hover {
  border-color: #ff9800 !important; }

.w3-border-deep-orange, .w3-hover-border-deep-orange:hover {
  border-color: #ff5722 !important; }

.w3-border-pink, .w3-hover-border-pink:hover {
  border-color: #e91e63 !important; }

.w3-border-purple, .w3-hover-border-purple:hover {
  border-color: #9c27b0 !important; }

.w3-border-deep-purple, .w3-hover-border-deep-purple:hover {
  border-color: #673ab7 !important; }

.w3-border-red, .w3-hover-border-red:hover {
  border-color: #f44336 !important; }

.w3-border-sand, .w3-hover-border-sand:hover {
  border-color: #fdf5e6 !important; }

.w3-border-teal, .w3-hover-border-teal:hover {
  border-color: #009688 !important; }

.w3-border-yellow, .w3-hover-border-yellow:hover {
  border-color: #ffeb3b !important; }

.w3-border-white, .w3-hover-border-white:hover {
  border-color: #fff !important; }

.w3-border-black, .w3-hover-border-black:hover {
  border-color: #000 !important; }

.w3-border-grey, .w3-hover-border-grey:hover, .w3-border-gray, .w3-hover-border-gray:hover {
  border-color: #9e9e9e !important; }

.w3-border-light-grey, .w3-hover-border-light-grey:hover, .w3-border-light-gray, .w3-hover-border-light-gray:hover {
  border-color: #f1f1f1 !important; }

.w3-border-dark-grey, .w3-hover-border-dark-grey:hover, .w3-border-dark-gray, .w3-hover-border-dark-gray:hover {
  border-color: #616161 !important; }

.w3-border-pale-red, .w3-hover-border-pale-red:hover {
  border-color: #ffe7e7 !important; }

.w3-border-pale-green, .w3-hover-border-pale-green:hover {
  border-color: #e7ffe7 !important; }

.w3-border-pale-yellow, .w3-hover-border-pale-yellow:hover {
  border-color: #ffffcc !important; }

.w3-border-pale-blue, .w3-hover-border-pale-blue:hover {
  border-color: #e7ffff !important; }

.w3-container {
  z-index: 99 !important; }
