.transparency {
  background: $white;
  padding: 70px 0 30px;

  &__list {
    overflow: hidden;
    margin-top: 40px;

    &__item {
      float: left;
      width: 30%;
      margin-right: 18%;

      &:first-child {
        margin-top: 0;
        width: 33%;
      }
      .title {
        font-family: 'NexaBold';
        font-size: 14px;
        color: $darkGray;
        text-transform: uppercase;
        text-align: center;
      }

      .description {
        font-family: 'Arial';
        font-size: 13px;
        color: $darkGrayText;
        line-height: 18px;
        display: block;
        margin-top: 5px;
      }

      img {
        width: 125%;
        height: auto;
      }

      .button {
        margin-top: 50px;
      }

      .graph-labels {
        &__item {
          font-family: 'Arial';
          font-size: 11px;
          color: $darkGray;
          display: inline-block;

          .orange {
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: $mainOrange;
          }

          .green {
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: $mainGreen;
          }
        }
      }
    }
  }
}
