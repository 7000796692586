.full-header{
  box-shadow: 0 5px 3px -3px gray;
  // width: 100vw !important;
  h1{
    display: block;
    font-family: Arial;
    font-size: 38px;
    color: $black;
  }
  .wrapper{
    padding: 0 8%;
  }
}

.portaldedados {
  min-height:100%;
  position:relative;

  .portal-section-label {
    position: relative;
    z-index: 1;
    height: 52px;

    .text {
      width: 100%;
      position: relative;
      display: inline-block;
      // background-color: $mainOrange;
      padding: 0 44px;
      line-height: 42px;
      height: 40px;

      font-family: 'NexaBold';
      color: $black;
      font-size: 18px;
      z-index: 3;
      margin: 15px 0;

      &:before {
        content: '';
        display: block;
        width: 37px;
        height: 52px;
        background: url($img-path + '/portaldedados/icon_home.png') center no-repeat;
        background-size: contain;

        position: absolute;
        top: -12px;
        left: 5px;
        z-index: -1;
      }
    }
  }
  position: relative;
  width: 100%;
  padding: 0 8%;

  &-header {
    padding: 60px 0 60px;
    width: 100%;

    .title{
      width: 40%;
      position: relative;
    }

    .logo-prefeitura{
      background: url($img-path + 'logo-maior.png') center no-repeat;
      background-size: cover;
      width: 246px;
      height: 78px;
    }

    .description {
      font-family: 'Arial';
      font-size: 15px;
      color: $darkGrayText;
      line-height: 22px;
      margin: 15px 0 0;

      .light {
        color: $mainOrange;
        font-style: italic;
      }
    }

    .side {
      &.left {
        width: 50%;
        float: left;
      }
      &.right {
        width: 50%;
      }
    }
  }

  &-footer {
    background-color: $darkGray;
    padding: 100px 0;
    // position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    text-align: center;
    margin-top: 75px;
  }

  .about{
    padding-top: 12px;
    padding-bottom: 120px;
  }

  .module{
    padding: 20px 0 40px;
    border-bottom: 1px solid black;
  }

  .pelotas-partner{
    max-width: 720px;
    margin-left: 200px;
    // &:first-child{
    //   border-top: 1px solid black;
    // }
  }


  .container {
    padding-top: 10px;
    padding-left: 20px;

    .image-content{
      float:left;

      &.content-download{
        background-size: cover;
        padding: 10px 0;
        margin-left: -3px;
      }
      .image-download{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        display: inline-block;
        width: 35px;
        height: 17px;
        margin: 0 auto 20px;

        &.xls { background-image: url($img-path + '/portaldedados/icon_xls.png'); }
        &.doc { background-image: url($img-path + '/portaldedados/icon_doc.png'); }
        &.csv { background-image: url($img-path + '/portaldedados/icon_csv.png'); }
        &.kmz { background-image: url($img-path + '/portaldedados/icon_kmz.png'); }
        &.dwg { background-image: url($img-path + '/portaldedados/icon_dwg.png'); }
        &.ods { background-image: url($img-path + '/portaldedados/icon_ods.png'); }
        &.pdf { background-image: url($img-path + '/portaldedados/icon_pdf.png'); }
        &.cdr { background-image: url($img-path + '/portaldedados/icon_cdr.png'); }
      }

      .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        display: block;
        width: 101px;
        height: 95px;
        margin: 0 auto 20px;

        &.celphone { background-image: url($img-path + '/portaldedados/icon_celphone.png'); }
        &.home { background-image: url($img-path + '/portaldedados/icon_home.png'); }
        &.saude { background-image: url($img-path + '/portaldedados/icon_saude.png'); }
        &.educacao { background-image: url($img-path + '/portaldedados/icon_educacao.png'); }
        &.obras { background-image: url($img-path + '/portaldedados/icon_obras.png'); }
        &.gestao-da-cidade { background-image: url($img-path + '/portaldedados/icon_gestao_da_cidade.png'); }
        &.seguranca { background-image: url($img-path + '/portaldedados/icon_seguranca.png'); }
        &.transito-e-transporte { background-image: url($img-path + '/portaldedados/icon_transito_e_transporte.png'); }
        &.cultura { background-image: url($img-path + '/portaldedados/icon_cultura.png'); }
        &.meio-ambiente { background-image: url($img-path + '/portaldedados/icon_meio_ambiente.png'); }
        &.servicos-urbanos { background-image: url($img-path + '/portaldedados/icon_servicos_urbanos.png'); }
        &.sanep { background-image: url($img-path + '/portaldedados/icon_sanep.png'); }
        &.populacao { background-image: url($img-path + '/portaldedados/icon_populacao.png'); }
        &.social { background-image: url($img-path + '/portaldedados/icon_social.png'); }
        &.participacao-social { background-image: url($img-path + '/portaldedados/icon_participacao_social.png'); }
        &.orcamento-e-gestao { background-image: url($img-path + '/portaldedados/icon_orcamento_e_gestao.png'); }
        &.negocios-e-turismo { background-image: url($img-path + '/portaldedados/icon_negocios_e_turismo.png'); }
        &.habitacao { background-image: url($img-path + '/portaldedados/icon_habitacao.png'); }
        &.esporte-e-lazer { background-image: url($img-path + '/portaldedados/icon_esporte_e_lazer.png'); }
      }
      .label {
        font-family: Arial;
        font-size: 13px;
        font-weight: bold;
        color: $darkGray;
        opacity: .75;
        display: block;
        text-align: center;
      }
    }

    .text{
      .title{
        display: block;
        font-family: Arial;
        font-size: 20px;
        color: $black;
      }
      p{
        font-family: 'NexaThin';
        font-size: 14px;
        color: $darkGrayText;
        line-height: 20px;
        font-weight: 100;
      }
      h4{
        display: block;
        font-family: Arial;
        font-size: 16px;
        font-weight: 100;
        color: $black;
      }
    }

    .portaldedados-menu {
      overflow: hidden;

      .line {
        overflow: hidden;

        &:nth-child(odd) {
          .digital-menu__item {
            &:nth-child(odd) {
              background-color: rgba(221, 221, 221, .25);
            }

            &:nth-child(even) {
              background-color: rgba(221, 221, 221, .1);
            }
          }
        }

        &:nth-child(even) {
          .digital-menu__item {
            &:nth-child(odd) {
              background-color: rgba(221, 221, 221, .1);
            }

            &:nth-child(even) {
              background-color: rgba(221, 221, 221, .25);
            }
          }
        }

        .portaldedados-menu__item {
          position: relative;
          display: table;
          float: left;
          width: 20%;
          height: 200px;
          overflow: hidden;

          .content {
            display: table-cell;

            .image {
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;

              display: block;
              width: 101px;
              height: 95px;
              margin: 0 auto 20px;

              &.saude { background-image: url($img-path + '/portaldedados/icon_saude.png'); }
              &.educacao { background-image: url($img-path + '/portaldedados/icon_educacao.png'); }
              &.obras { background-image: url($img-path + '/portaldedados/icon_obras.png'); }
              &.gestao-da-cidade { background-image: url($img-path + '/portaldedados/icon_gestao_da_cidade.png'); }
              &.seguranca { background-image: url($img-path + '/portaldedados/icon_seguranca.png'); }
              &.transito-e-transporte { background-image: url($img-path + '/portaldedados/icon_transito_e_transporte.png'); }
              &.cultura { background-image: url($img-path + '/portaldedados/icon_cultura.png'); }
              &.meio-ambiente { background-image: url($img-path + '/portaldedados/icon_meio_ambiente.png'); }
              &.servicos-urbanos { background-image: url($img-path + '/portaldedados/icon_servicos_urbanos.png'); }
              &.sanep { background-image: url($img-path + '/portaldedados/icon_sanep.png'); }
              &.populacao { background-image: url($img-path + '/portaldedados/icon_populacao.png'); }
              &.social { background-image: url($img-path + '/portaldedados/icon_social.png'); }
              &.participacao-social { background-image: url($img-path + '/portaldedados/icon_participacao_social.png'); }
              &.orcamento-e-gestao { background-image: url($img-path + '/portaldedados/icon_orcamento_e_gestao.png'); }
              &.negocios-e-turismo { background-image: url($img-path + '/portaldedados/icon_negocios_e_turismo.png'); }
              &.habitacao { background-image: url($img-path + '/portaldedados/icon_habitacao.png'); }
              &.esporte-e-lazer { background-image: url($img-path + '/portaldedados/icon_esporte_e_lazer.png'); }
            }

            .label {
              font-family: Arial;
              font-size: 16px;
              color: $darkGray;
              opacity: .75;
              display: block;
              text-align: center;
            }

            .description {
              font-family: 'Arial';
              font-size: 12px;
              color: $darkGrayText;
              display: block;
              text-align: center;
              line-height: 18px;
              width: 90%;
              margin: 30px auto 0;
            }
          }
        }
      }
    }

    .content {
      .side {

        &.left {
          width: 54%;
        }

        &.right {
          width: 40%;
        }
      }

      p {
        font-family: 'Arial';
        font-size: 14px;
        color: $darkGrayText;
        line-height: 20px;
        font-weight: 100;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        &.internal-link{
          margin-bottom: 10px;
          a{
            color: $mainOrange;
            &:after{
              content: "";
              display: inline-block;
              background-image: url($img-path + 'icon_link.png');
              background-repeat: no-repeat;
              background-position: right 3px;
              background-size: contain;
              width: 22px;
              height: 22px;
              padding-left: 10px;
            }
          }
        }
      }

      .center {
        display: block;
        margin: 0 auto;
      }

      .paula {
        display: block;
        margin-left: 50px;
      }

      .fix-topPosition {
        margin-top: -132px;
      }
    }
  }

  h1{
    display: block;
    font-family: Arial;
    font-size: 40px;
    color: $black;
  }

  h2{
    display: block;
    font-family: 'NexaThin';
    font-size: 36px;
    color: $darkGrayText;
  }

  h3{
    display: block;
    font-family: 'NexaThin';
    font-size: 24px;
    color: $darkGrayText;
  }

  h4{
    display: block;
    font-family: Arial;
    font-size: 17px;
    padding-bottom: 8px;
    font-weight: bold;
    color: $black;
  }

  h5{
    display: block;
    font-family: 'NexaBold';
    font-size: 12px;
    color: $darkGrayText;
  }

  h6{
    display: block;
    font-family: 'NexaLight';
    font-size: 12px;
    color: $mainOrange;
  }
}
