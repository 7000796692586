* {
	box-sizing: border-box;
}

html, body {
	display: block;
	width: 100%;
	height: 100%;
	min-height: 100%;
	text-rendering: optimizeLegibility !important;
  	-webkit-font-smoothing: antialiased;
  	text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
	backface-visibility: hidden;
	line-height: normal;
	// min-width: 1024px;

	&.tempMobile {
		min-width: auto;
		overflow-x: hidden;

		.swiper-button-next,
		.swiper-button-prev {
			opacity: 1!important;
			background-color: rgba(0, 0, 0, .5)!important;
			padding: 0 20px;
		}


		.noticias {
			padding: 32px;

			.section-label {
				width: 121px;

				.text {
					display: none;
				}

				.button {
					display: block;
					width: 121px;
					margin-top: 20px;
				}
			}

			.container {
				padding: 0;
				width: 100%;
				padding-top: 30px;

				.news-list{
					.template-link{
						img {
							width: 100%;
						}
						.container-link{
							.template-time{
							line-height: 34px
							}

							.template-title{
								display: -webkit-box;
								-webkit-line-clamp: 8;
								-webkit-box-orient: vertical;
							}

							.template-descripiton{
								display: none;
							}
						}
					}
					.borderBottom {
						display: block;
						width: 100%;
						height: 1px;
						background-color: #e5e5e5;
						margin-top: 29px;
						margin-bottom: 20px;

					}
				}
				.pagination {
					margin-top: 10px;
					padding: 0;
					a.prev, a.next, div a {
						margin-right: 3.6px;

						@include galaxys5 {
							margin-right: 10.84px;
						}
						@include iphone6 {
							margin-right: 14.2px;
						}
						@include nexus {
							margin-right: 20.8px;
						}
						@include iplus {
							margin-right: 21.2px;
						}

					}
					a.last{
						margin: 0;
					}
				}

				.side {
					width: 100%!important;
					.left{
						float: none;
					}
				}



				p.internal-link a {
					line-height: 14px;
				}
			}
		}
	}
}

body {

	&.hidden-scroll { overflow: hidden; }
}

.clearfix {

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

a { text-decoration: none; }

.left { float: left; }
.right { float: right; }

ul {
	margin: 0;
	padding: 0;

	li {
		list-style: none;
		margin: 0;
	}
}

input, select, textarea {
	outline: none;

	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
	border: none;
}

.app {

	.wrapper {
		height: 100%;
		min-height: 100%;
		position: relative;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 0 2%;
		overflow: hidden;

		&.small {
			max-width: 1200px;
		}

		&.no-hidden {
			overflow: visible;
		}
	}

	.logo {
    background-image: url($img-path + 'logo.png');
	background-size: contain;
	background-repeat: no-repeat;
    display: block;
    width: 225px;
    height: 149px;

		&.white {
			width: 100%;
			height: 214px;
			background: url($img-path + 'logo-white.png') center no-repeat;
			background-size: contain;
		}

		&.logo-digital {
			display: block;
			background: url($img-path + 'logo-digital.png') center no-repeat;
			width: 316px;
			height: 38px;
		}
  }

	.button {
		font-family: 'NexaLight';
		font-size: 14px;
		color: $mediumGrayText;
		text-transform: uppercase;
		border: 1px solid $mediumGrayText;
		display: inline-block;
		height: 40px;
		line-height: 40px;
		padding: 0 25px;
		overflow: hidden;

		&.bold {
			font-family: 'NexaBold';
		}

		&.small {
			padding: 0 15px;
		}

		&.white {
			border-color: $white;
			color: $white;

			.icon.access {
				background: url("../images/icon_access_white.png") center no-repeat;
			}
		}

		&.orange {
			display: inline-block;
			height: 40px;
			padding: 0 15px;
			text-align: center;
			line-height: 40px;
			background-color: $mainOrange;
			color: $white;
			margin-right: 8px;
			font-family: 'NexaBold';
			font-size: 16px;

			border: 1px solid $mainOrange;
			text-transform: initial;
			transition: all .2s ease-in-out;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				opacity: .9;
			}
		}

		.value {
			display: inline-block;
		}

		.icon {
			display: inline-block;
			vertical-align: middle;

			&.access {
				background: url($img-path + 'icon_access.png') center no-repeat;
				width: 13px;
				height: 13px;
				margin-left: 11px;
			}

			&.back {
				background: url($img-path + 'icon_back.png') center no-repeat;
				width: 18px;
				height: 16px;
				margin: 11px 11px 11px 0;
			}
		}
	}

	.section-label {
		position: relative;
		z-index: 1;
		height: 52px;

		.text {
			position: relative;
			display: inline-block;
			background-color: $mainOrange;
			padding: 0 20px;
			line-height: 42px;
			height: 40px;

			font-family: 'NexaBold';
			color: $white;
			font-size: 13px;
			text-transform: uppercase;
			z-index: 3;
			margin: 6px 0;

			&:before {
				content: '';
				display: block;
				width: 37px;
				height: 52px;
				background: url($img-path + 'big_arrow.png') center no-repeat;
				background-size: contain;

				position: absolute;
				top: -6px;
				left: 5px;
				z-index: -1;
			}

			&:after {
				content: '';
				display: block;
				width: 69px;
				height: 30px;
				background: url($img-path + 'section_arrows.png') center no-repeat;

				position: absolute;
				top: 50%;
				right: -79px;
				margin-top: -15px;
			}
			@media only screen and (max-width: 868px) {
	          &:after {
	          	display:none;
	          }
	        }
		}
	}
 	@media only screen and (max-width: 500px) {
      .section-label > a{
        display:none;
      }
    }

	.highlight-list .slider {
		overflow: hidden;
		height: 400px;
	}

	.slider-arrows {

		.arrow {
			background: url($img-path + 'slider_arrow.png') center no-repeat;
			background-size: 10px 18px;
			display: inline-block;
			width: 40px;
			height: 40px;
			border: 1px solid $white;

			&.next {
				transform: rotate(180deg);
				margin-left: 5px;
			}

			&.swiper-button-disabled {
				opacity: .5;
				cursor: default;
			}
		}
	}

	.popup-video {
		display: none;
		width: 100%;
		min-width: 1024px;
		height: 100vh;
		overflow: hidden;

		position: fixed;
		top: 0;
		left: 0;
		z-index: 3;

		.cover {
			width: 100%;
			height: 100vh;
			opacity: .9;

			position: absolute;
			top: 0;
			left: 0;
			background-color: $mainRed;
		}

		.modal {
			width: 100%;
			height: 100%;

			&__header {
				margin-top: 80px;
				overflow: hidden;

				.title {
					font-family: 'NexaLight';
					font-size: 30px;
					color: $white;
				}
			}

			.youtube-player {
				position: relative;
				width: 100%;
				height: 75%;
				margin-top: 20px;
			}
		}
	}
	.popup-vimeo {
		display: none;
		width: 100%;
		min-width: 1024px;
		height: 100vh;
		overflow: hidden;

		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		text-align: center;

		.cover {
			width: 100%;
			height: 100vh;
			opacity: .45;

			position: absolute;
			top: 0;
			left: 0;
			background-color: $mainRed;
		}

		.modal {
			width: 900px;
			height: 100%;
			margin: 0 auto;

			&__header {
				margin-top: 80px;
				overflow: hidden;

				.title {
					font-family: 'NexaLight';
					font-size: 30px;
					color: $white;
				}
			}

			.youtube-player {
				position: relative;
				width: 100%;
				height: 65%;
				margin-top: 20px;
			}
			a.button{
				background-color: #fe5405;
				color: #fff;
				border: none;
			}
			a.saiba-mais{
				position:absolute;
				bottom: -30px;
				font-family: 'NexaLight';
				font-size: 18px;
				text-align: left;
				width: 100%;
				padding: 7.5px;
				background: #fff;
				color: #333;
			}
		}
	}

	.search-bar {
		width: 100%;
		height: 90px;
		background: #aaa;
		border-top: 5px solid white;
		border-bottom: 5px solid white;
		background-color: $superLightGray;

		.text {
			display: block;
			width: 100%;
			height: 80px;
			background-color: transparent;
			padding-right: 115px;
			color: $mediumGray;
			font-family: 'NexaLight';
			font-size: 26px;

			::-webkit-input-placeholder {
				color: $mediumGray;
				font-family: 'NexaLight';
				font-size: 26px;
			}
			::-moz-placeholder {
				color: $mediumGray;
				font-family: 'NexaLight';
				font-size: 26px;
			}
			:-ms-input-placeholder {
				color: $mediumGray;
				font-family: 'NexaLight';
				font-size: 26px;
			}
			:-moz-placeholder {
				color: $mediumGray;
				font-family: 'NexaLight';
				font-size: 26px;
			}
		}

		.search-button {
			position: absolute;
			top: -5px;
			right: 40px;

			display: block;
			width: 90px;
			height: 90px;
			border: none;

			background-image: url($img-path + 'icon_search.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-color: rgba(255,255,255,0);
		}
	}

	.link{
		color: #F4A14E;
		font-style: italic;
	}
}

.form{
	.text {
		display: block;
		width: 100%;
		padding: 12.5px 12.5px 12.5px 30px;
		background-color: $superLightGray;
		padding-right: 115px;
		color: $mediumGray;
		font-family: 'NexaLight';
		font-size: 16px;
		margin: 5px 0;

		::-webkit-input-placeholder {
			color: $mediumGray;
			font-family: 'NexaLight';
			font-size: 16px;
		}
		::-moz-placeholder {
			color: $mediumGray;
			font-family: 'NexaLight';
			font-size: 16px;
		}
		:-ms-input-placeholder {
			color: $mediumGray;
			font-family: 'NexaLight';
			font-size: 16px;
		}
		:-moz-placeholder {
			color: $mediumGray;
			font-family: 'NexaLight';
			font-size: 16px;
		}
	}

	select {
		width: 100%;
		font-family: 'NexaLight';
		font-size:14px;
		color: $mediumGray;
		padding:12px;
		background-color:$superLightGray;
		padding-left:40px;
		-webkit-appearance: none;  /* Remove estilo padrão do Chrome */
		-moz-appearance: none; /* Remove estilo padrão do FireFox */
		appearance: none; /* Remove estilo padrão do FireFox*/
		background: url($img-path + 'icon.select.jpg') no-repeat #eeeeee;  /* Imagem de fundo (Seta) */
		background-position: 100% center;  /*Posição da imagem do background*/

		option {
			width:100%;
			font-family: 'NexaLight';
			color: $mediumGray;
			font-size:16px;
			padding:12.5px;
		}
	}
	.option {
  		position: relative;
		display: inline-block;
		padding: 0px 10px;

		label {
			display: inline-block;
		    width: 15px;
		    height: 15px;
			border: 2px solid #c8c8cc;
		    cursor: pointer;
		    position: absolute;
		    top: 0;
		    left: 0;
		    &:after {
		      content: '';
		      width: 7px;
		      height: 5px;
		      position: absolute;
		      top: 1px;
		      left: 1px;
		      border: 2px solid $mediumGray;
		      border-top: none;
		      border-right: none;
		      background: transparent;
		      opacity: 0;
		      transform: rotate(-45deg);
		    }
		    &:hover::after {
		      opacity: 0.5;
		    }
		}
		input[type=checkbox] {
			visibility: hidden;
			&:checked + label:after {
			  opacity: 1;
			}
		}
	}
	span{
		display: inline-block;
		font-family: 'NexaLight';
		color: $mediumGray;
		font-size:13px;
		&.status{
			display: block;
	        font-family: 'NexaBold';
	        font-size: 16px;
			&.success{
		        color: $mainGreen;
			}
			&.error{
		        color: $mainRed;
			}
			&.warning{
		        color: $mainOrange;
			}
		}
	}
	p{
    margin-bottom: 10px !important;
	}

	.switch-input{
		.switch {
			position: relative;
			display: inline-block;
			width: 60px;
			height: 34px;
		}

		/* Hide default HTML checkbox */
		.switch input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		/* The slider */
		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #ccc;
			-webkit-transition: .4s;
			transition: .4s;
		}

		.slider:before {
			position: absolute;
			content: "";
			height: 26px;
			width: 26px;
			left: 4px;
			bottom: 4px;
			background-color: white;
			-webkit-transition: .4s;
			transition: .4s;
		}

		input:checked + .slider {
			background-color: #F4A14E;
		}

		input:focus + .slider {
			box-shadow: 0 0 1px #2196F3;
		}

		input:checked + .slider:before {
			-webkit-transform: translateX(26px);
			-ms-transform: translateX(26px);
			transform: translateX(26px);
		}

		/* Rounded sliders */
		.slider.round {
			border-radius: 34px;
		}

		.slider.round:before {
			border-radius: 50%;
		}

		.switch-span{

		}
	}
}
