.updates {
  position: relative;
  background-color: $bgLight;
  padding: 70px 0 0;

  .highlights {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-top: 65px;
    padding-top: 10px;

    .side {
      position: relative;
      width: 50%;
      min-height: 400px;

      &.left {
        width: 46%;
        padding-left: 20px;

        &:before {
          content: '';
          display: block;
          width: 6px;
          height: 150px;
          background-color: $mainRed;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .title {
        font-family: 'NexaBold';
        font-size: 13px;
        color: $mainRed;
        text-transform: uppercase;
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

        .updated-time {
          font-family: 'Arial';
          font-size: 9px;
          color: $greyText;
          text-transform: initial;
          margin-left: 5px;
        }
      }

      .big-title {
        font-family: 'NexaThin';
        font-size: 60px;
        color: $darkGrayText;
        margin: 40px 0 0;
        line-height: 60px;

        .bold {
          font-family: 'NexaBold';
        }

        @media (min-width: 1200px) and (max-width: 1400px) {
          font-size: 50px;
          line-height: 50px;
        }

        @media (max-width: 1200px) {
          font-size: 40px;
          line-height: 40px;
        }
      }

      .description {
        font-family: 'Arial';
        font-size: 13px;
        margin: 20px 0 0;
        color: $darkGrayText;
        line-height: 24px;
      }

      .button {
        font-family: 'NexaLight';
        font-size: 14px;
        color: $darkGrayText;
        border: 1px solid $greyText;
        display: inline-block;
        height: 40px;
        padding: 0 25px;
        line-height: 40px;
        text-transform: uppercase;
        margin-top: 45px;
      }

      .block-image {
        display: block;
        width: 100%;
        height: 400px;
      }

      .cover-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 230px;
        height: 100%;
        background-color: rgba(90, 92, 101, .95);
        padding: 40px 25px 40px 40px;
        z-index: 2;

        .content {
          position: absolute;
          top: 40px;
          left: 40px;
          z-index: 3;

          .section-label {

            .wrapper {
              padding: 0;
            }

            .text {
              min-width: 218px;
              width: 100%;
              display: inline-block;
              white-space: nowrap;
              color: white !important;
            }
            a{
              color: white !important;
            }
          }

          .title {
            display: block;
            max-width: 180px;
            color: $white;
            font-family: 'NexaBold';
            font-size: 20px;
            text-transform: initial;
            margin-top: 25px;
            min-height: 100px;
            //max-height: 100px;
          }

          .slider-arrows {
            position: relative;
            height: 40px;
            margin-top: 15px;

            .arrow {
              background: url($img-path + 'slider_arrow.png') center no-repeat;
              background-size: 10px 18px;
              display: inline-block;
              width: 40px;
              height: 40px;
              border: 1px solid $white;

              &.next {
                transform: rotate(180deg);
                margin-left: 5px;
              }
            }
          }

          .button {
            font-family: 'NexaLight';
            font-size: 14px;
            color: $lightGrayText;
            border: 1px solid $borderGray;
            height: 40px;
            line-height: 40px;
            text-transform: uppercase;
            padding: 0 30px;
            text-align: center;
          }
        }
      }
    }
  }
}