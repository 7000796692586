.empreendedor {
  position: relative;
  width: 100%;
  background: url($img-path + 'bg_digital.png') center no-repeat;
  background-size: cover;

  &-header {
    padding: 120px 0 90px;
    width: 80%;
    @media only screen and (max-width: 1023px) {
      width:100%;
    }

    .description {
      font-family: 'Arial';
      font-size: 15px;
      color: $darkGrayText;
      line-height: 22px;
      margin: 15px 0 0;

      .light {
        color: $mainOrange;
        font-style: italic;
      }
    }
    .logo-empreendedor{
      display: block;
      background: url(../images/sala-empreendedor/banco-logo.png) center no-repeat;
      width: 300px;
      height: 100px;
      background-size: contain;
    }
  }

  .empreendedor-menu {
    overflow: hidden;

    .line {
      overflow: hidden;

      &:nth-child(odd) {
        .empreendedor-menu__item {
          &:nth-child(odd) {
            background-color: rgba(221, 221, 221, .25);
          }

          &:nth-child(even) {
            background-color: rgba(221, 221, 221, .1);
          }
        }
      }

      &:nth-child(even) {
        .empreendedor-menu__item {
          &:nth-child(odd) {
            background-color: rgba(221, 221, 221, .1);
          }

          &:nth-child(even) {
            background-color: rgba(221, 221, 221, .25);
          }
        }
      }

      .empreendedor-menu__item {
        position: relative;
        display: table;
        float: left;
        width: 25%;
        @media only screen and (max-width: 800px) {
          width:50%;
        }

        @media only screen and (max-width: 500px) {
          width:100%;
          margin: 2px;
        }
        height: 250px;
        overflow: hidden;

        .content {
          display: table-cell;
          vertical-align: middle;

          .image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            display: block;
            width: 90px;
            height: 80px;
            margin: 0 auto 20px;

            &.consulta-protocolo { background-image: url($img-path + '/sala-empreendedor/1.png'); }
            &.alvara { background-image: url($img-path + '/sala-empreendedor/2.png'); }
            &.notafiscal { background-image: url($img-path + '/sala-empreendedor/3.png'); }
            &.mei { background-image: url($img-path + '/sala-empreendedor/4.png'); }
            &.entidades { background-image: url($img-path + '/sala-empreendedor/5.png'); }
            &.autonomo { background-image: url($img-path + '/sala-empreendedor/6.png'); }
            &.jucis { background-image: url($img-path + '/sala-empreendedor/7.png'); }
            &.mei-portal { background-image: url($img-path + '/sala-empreendedor/8.png'); }
            &.alvara2 { background-image: url($img-path + '/sala-empreendedor/9.png'); }
            &.bombeiros { background-image: url($img-path + '/sala-empreendedor/10.png'); }
            &.ambiental { background-image: url($img-path + '/sala-empreendedor/11.png'); }
            &.viabilidades { background-image: url($img-path + '/sala-empreendedor/12.png'); }
            &.sanep { background-image: url($img-path + '/sala-empreendedor/sanep.png'); }
            &.instru-normativas { background-image: url($img-path + '/sala-empreendedor/instru-normativas.png'); }
            &.edificapel { background-image: url($img-path + '/sala-empreendedor/edificapel.png'); }
            &.vigilancia { background-image: url($img-path + '/sala-empreendedor/vigi-sanitaria.png'); }
            &.sebrae { background-image: url($img-path + '/sala-empreendedor/sebrae.png'); }
            &.legislacao { background-image: url($img-path + '/sala-empreendedor/legislacao.png'); }
            &.legislacao-fim { background-image: url($img-path + '/sala-empreendedor/legislacao-fim.png'); }
            
          }

          .label {
            font-family: 'NexaBold';
            font-size: 16px;
            color: $darkGray;
            text-transform: uppercase;
            opacity: .75;
            display: block;
            text-align: center;
          }

          .description {
            font-family: 'Arial';
            font-size: 12px;
            color: $darkGrayText;
            display: block;
            text-align: center;
            line-height: 18px;
            width: 90%;
            margin: 30px auto 0;
          }
        }
      }
    }
  }

  .icon-smile {
    width: 100%;
    overflow: hidden;
    margin-top: 90px;
    margin-bottom: 20px;

    .icon {
      background: url($img-path + 'icon_smile.png') center no-repeat;
      float: right;
      display: block;
      width: 82px;
      height: 80px;
    }
  }
}
