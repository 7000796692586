.preloader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: $bgLight;
  min-width: 1024px;

  .center {
    width: 500px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .logo {
      display: block;
      margin: 0 auto;
    }

    .bar {
      position: relative;
      width: 100%;
      height: 30px;
      margin-top: 30px;
      background: #aaa;
      padding: 3px;
      border-radius: 30px;

      .progress {
        display: block;
        width: 0%;
        height: 24px;
        background-color: $mainOrange;
        border-radius: 30px;
      }
    }

    .loading-text {
      display: block;
      text-align: center;
      margin-top: 20px;
      font-family: 'NexaBold';
      font-size: 16px;
      color: $mainOrange;
      text-transform: uppercase;
    }

    .percent {
      display: block;
      text-align: center;
      margin-top: 10px;
      font-family: 'NexaBold';
      font-size: 16px;
      color: $mainOrange;
    }
  }

  .cache {
    display: none;
  }
}