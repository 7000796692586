.full-slider {
  position: relative;
  width: 100%;
  padding: 20px 0;

  .slider {
    position: relative;

    .swiper-container {
      width: 100%;
      height: 200px;
    }

    &__item {
      width: 100%;
      height: 200px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
    }

    .description {
      height: 100px;
      padding: 0 40px;
      background-color: $darkGray;
      position: absolute;
      //right: 130px;
      right: 9%;
      bottom: 15px;
      z-index: 1;
      transition: all .3s ease-in-out;

      &:before {
        content: '';
        display: block;
        width: 92px;
        height: 130px;
        background: url($img-path + 'big_arrow.png') center no-repeat;

        position: absolute;
        top: -15px;
        left: 15px;
        z-index: 2;
      }

      .text {
        position: relative;
        display: block;
        line-height: 107px;
        text-transform: uppercase;
        font-family: 'NexaBold';
        color: $white;
        font-size: 20px;
        text-align: center;
        z-index: 3;
      }
    }

    .slider-arrows {
      position: absolute;
      top: 30px;
      //right: 130px;
      right: 9%;
      height: 40px;
      z-index: 2;

      .arrow {
        //background: url($img-path + 'slider_arrow.png') center no-repeat;
        background-size: 10px 18px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 1px solid $white;

        &.next {
          background: url($img-path + 'slider_arrow-right.png') center no-repeat;
          transform: rotate(00deg);
          margin-left: 5px;
        }

        &.prev {
          background: url($img-path + 'slider_arrow-left.png') center no-repeat;
        }
      }
    }
  }
}
