.multimedia {
  position: relative;
  background-color: $bgLight;
  padding: 150px 0 30px;

  .side {

    &.left {
      width: 43%;
    }

    &.right {
      width: 50%;
    }

    .gallery-photos {
      width: 100%;
      overflow: hidden;
      margin-top: 70px;

      &__item {
        display: block;
        float: left;
        width: 48%;
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 2%;
        margin-bottom: 2%;        

        &:nth-child(2) {
          margin-right: 0;
        }

        &:nth-child(3) {
          margin-bottom: 0;
        }

        &:nth-child(4) {
          margin-right: 0;
          margin-bottom: 0;
        }

        @media (max-width: 1180px) {
          height: 180px;
        }
      }
    }

    .youtube-list {
      width: 100%;
      margin-top: 70px;

      .line {
        overflow: hidden;
        width: 100%;

        .youtube-list__item {
          position: relative;
          float: left;
          display: block;
          width: 23.6%;
          height: 95px;
          margin-right: 1.8%;
          margin-top: 1.8%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          &:nth-child(4n) {
            margin-right: 0;
          }

          .icon-play {
            display: block;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            border: 3px solid $white;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) translateZ(0);
            opacity: .5;

            background-color: rgba(255, 255, 255, .2);
            background-image: url($img-path + 'icon_play.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 13px 15px;

            transition: all .3s ease-in-out;
          }
        }

        &.big {

          .youtube-list__item {
            width: 49.1%;
            height: 200px;
            margin-top: 0;

            &:last-child {
              margin-right: 0;
            }

            .icon-play {
              width: 107px;
              height: 107px;
              background-size: auto;
            }
          }  
        }
      }
    }

    .button {
      margin-top: 25px;
    }
  }
}