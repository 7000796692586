.footer {
  position: relative;
  background-color: $bgDark;
  
  .site-map {
    padding: 70px 0 0;

    .list {
      width: 100%;
      overflow: hidden;
      margin-top: 70px;

      .col {
        float: left;
        width: 11.11%;

        .title {
          font-family: 'NexaBold';
          font-size: 14px;
          color: $greyText2;
          text-transform: uppercase;
        }

        .links {
          margin-top: 5px;

          &__item {
            font-family: 'Arial';
            font-size: 11px;
            color: $greyText2;
            display: block;
            line-height: 20px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .bottom-info {
    padding: 0;

    .wrapper {
      bottom: -115px;
      z-index: 1;
    }

    .cols {
      overflow: hidden;

      .col {
        float: left;
        margin-top: 100px;

        @media (max-width: 1250px) {
          margin-top: 45px;
        }

        &:nth-child(1) {
          width: 12%;
          margin-right: 3%;
        }

        &:nth-child(2) {
          width: 20%;
          margin-right: 5%;

          @media (max-width: 1250px) {
            width: 23%;
            margin-right: 2%;
          }
        }

        &:nth-child(3) {
          width: 18%;
          margin-right: 2%;

          @media (max-width: 1250px) {
            width: 20%;
            margin-right: 0%;
          }
        }

        &:nth-child(4) {
          width: 25%;

          .title{
            color: white;
            font-family: 'NexaBold';
          }
        }

        &:last-child {
          margin-right: 0;
        }

        .address {

          .title {
            font-family: 'NexaBold';
            font-size: 18px;
            color: $white;

            @media (max-width: 1390px) {
              font-size: 15px;
            }
          }

          .description {
            font-family: 'Arial';
            font-size: 12px;
            color: $white;
            margin: 5px 0 0;
            line-height: 18px;

            @media (max-width: 1390px) {
              font-size: 11px;
            }
          }
        }

        .earth {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    .social-media-list {

      .title {
        color: $white;
        font-family: 'NexaBold';
        font-size: 18px;

        @media (max-width: 1390px) {
          font-size: 15px;
        }
      }

      .list {
        margin-top: 5px;
        overflow: hidden;

        &__item {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-right: 10px;
          background-color: $mainOrange;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;

          &:last-child { margin-right: 0; }

          &.facebook { background-image: url($img-path + 'icon_facebook_orange.png'); }
          &.twitter { background-image: url($img-path + 'icon_twitter_orange.png'); }
          &.youtube { background-image: url($img-path + 'icon_youtube_orange.png'); }
          &.instagram { background-image: url($img-path + 'icon_instagram_orange.png'); }
          &.flickr { background-image: url($img-path + 'icon_flickr_orange.png'); }

          @media (max-width: 1390px) {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  .phones {
    background-color: #52545D;
    position: relative;
    width: 100%;
    height: 200px;

    .phone-list {
      overflow: hidden;
      padding: 69px 0;

      &__item {
        float: left;
        font-family: 'Arial';
        font-size: 12px;
        color: $white;
        background: url($img-path + 'icon_phone.png') center left no-repeat;
        display: block;
        padding-left: 41px;
        margin-left: 50px;

        &:first-child {
          margin-left: 0;
        }

        .number {
          display: block;
          font-family: 'NexaBold';
          font-size: 30px;
          color: $white;
        }
      }
    }

    .copyright {
      position: relative;
      left: 0;
      bottom: 20px;

      .text {
        display: block;
        color: $white;
        font-family: 'Arial';
        font-size: 10px;

        .coinpel {
          font-family: 'NexaBold';
          font-size: 12px;
          text-transform: uppercase;
        }
      }
    }

    .back-top {
      display: none;
      position: fixed;
      right: 0;
      bottom: 3%;

      background-image: url($img-path + 'icon_back-to-top.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 15px 11px;
      background-color: #383A41;
      box-shadow: none;
      border: none;
     // display: block;
      width: 40px;
      height: 40px;
      margin-right: 3%;
      z-index: 2;
      opacity: .5;
      -webkit-transition: opacity 0.5s;
      -moz-transition: opacity 0.5s;
      transition: opacity 0.5s;

      &:hover {
        opacity: 1
      }
    }

    .zap-zap-icon {
      display: none;
      position: fixed;
      right: 0;
      bottom: 3%;

      background-image: url($img-path + 'zapzap.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60px 60px;
      //background-color: #383A41;
      box-shadow: none;
      border: none;
      // display: block;
      width: 70px;
      height: 70px;
      margin-right: 3%;
      z-index: 5;
      //opacity: .5;
      opacity: 1;
      -webkit-transition: opacity 0.5s;
      -moz-transition: opacity 0.5s;
      transition: opacity 0.5s;

      &:hover {
        opacity: 1
      }
    }
  }
  
  .arrow {
    background: url($img-path + 'footer-arrow.png') center right no-repeat;
    background-size: contain;
    display: block;
    width: 268px;
    height: 370px;

    position: absolute;
    bottom: -68px;
    left: -120px;
    z-index: 1;
  }
}

 @media only screen and (max-width: 868px) {
  .footer{
    display: none;
  }
}