.navbar{
  padding: 0 !important;
}

.transparency-portal{
  background-image: url($img-path + 'transparencia/menu-background.png');
  background-repeat: no-repeat;
  background-size: 100%;
  font-family: Roboto, sans-serif;
  .wrapper{
    text-align: -webkit-center;

    .download-dados{
        display: block !important;
        margin-left: 50px !important;
    }
    .section-label{
      margin-bottom: 70px;
      padding: 100px 100px 0 100px;

      h1{
        background-color: #F4A14E;
        padding: 0 20px;
        line-height: 42px;
        height: 40px;
        font-family: 'NexaBold';
        color: white;
        font-size: 13px;
        text-transform: uppercase;
        display: block;
        float: left;
      }
    }

    .menu{
      .line{
        overflow: hidden;
        width: 70% !important;

        .image{
          height: 60%;
          display: block;
          background-repeat: no-repeat;
          background-position-x: center;
        }
      }

      .line:nth-child(1){
        a:nth-child(1){
          .image{
            background-image: url($img-path + 'transparencia/administracao-icon.png');
            background-size: 25%;
          }
        }
        a:nth-child(2){
          .image{
            background-image: url($img-path + 'transparencia/receita-icon.png');
            background-size: 35%;
            background-position-y: 30%;
          }
        }
        a:nth-child(3){
          .image{
            background-image: url($img-path + 'transparencia/despesa-icon.png');
            background-size: 25%;
            background-position-y: 30%;
          }
        }
      }
      .line:nth-child(2){
        a:nth-child(1){
          .image{
            background-image: url($img-path + 'transparencia/gestao-de-pessoas-icon.png');
            background-size: 25%;
          }
        }
        a:nth-child(2){
          .image{
            background-image: url($img-path + 'transparencia/fale-conosco-icon.png');
            background-size: 35%;
            background-position-y: 30%;
          }
        }
        a:nth-child(3){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 25%;
            background-position-y: 30%;
          }
        }
      }

      .description{
        margin: 60px 0 60px 60px;
        text-align: left;
        font-weight: bold;
        color: #5A5C65;
        .secretaria{
          margin-bottom: 15px;
        }
      }

      .menu__item{
        position: relative;
        display: table;
        float: left;
        width: 33%;
        height: 200px;
        overflow: hidden;
        background-color: white;
        &.darken{
          background-color: rgba(221, 221, 221, 1);
        }
      }

      .content{
        display: table-cell;
        padding-top: 10%;
        .label{
          font-family: 'NexaBold';
          font-size: 16px;
          color: #5A5C65;
          text-transform: uppercase;
          opacity: .75;
          display: block;
          text-align: center;
        }
        .description{

        }
      }
    }

    .menu-transparencia{
      .line{
        overflow: hidden;
        width: 70% !important;

        .image{
          height: 60%;
          display: block;
          background-repeat: no-repeat;
          background-position-x: center;
        }
      }

      .line:nth-child(1){
        a:nth-child(1){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 25%;
          }
        }
        a:nth-child(2){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 35%;
            background-position-y: 30%;
          }
        }
        a:nth-child(3){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 25%;
            background-position-y: 30%;
          }
        }
      }
      .line:nth-child(2){
        a:nth-child(1){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 25%;
          }
        }
        a:nth-child(2){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 35%;
            background-position-y: 30%;
          }
        }
        a:nth-child(3){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 25%;
            background-position-y: 30%;
          }
        }
      }
      .line:nth-child(3){
        a:nth-child(1){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 25%;
          }
        }
        a:nth-child(2){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 35%;
            background-position-y: 30%;
          }
        }
        a:nth-child(3){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 25%;
            background-position-y: 30%;
          }
        }
      }
      .line:nth-child(4){
        a:nth-child(1){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 25%;
          }
        }
        a:nth-child(2){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 35%;
            background-position-y: 30%;
          }
        }
        a:nth-child(3){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 25%;
            background-position-y: 30%;
          }
        }
      }
      .line:nth-child(5){
        a:nth-child(1){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 25%;
          }
        }
        a:nth-child(2){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 35%;
            background-position-y: 30%;
          }
        }
        a:nth-child(3){
          .image{
            background-image: url($img-path + 'transparencia/mais-publicacoes-icon.png');
            background-size: 25%;
            background-position-y: 30%;
          }
        }
      }

      .description{
        margin: 60px 0 60px 60px;
        text-align: left;
        font-weight: bold;
        color: #5A5C65;
        .secretaria{
          margin-bottom: 15px;
        }
      }

      .menu__item{
        position: relative;
        display: table;
        float: left;
        width: 33%;
        height: 200px;
        overflow: hidden;
        background-color: white;
        &.darken{
          background-color: rgba(221, 221, 221, 1);
        }
      }

      .content{
        display: table-cell;
        padding-top: 10%;
        .label{
          font-family: 'NexaBold';
          font-size: 16px;
          color: #5A5C65;
          text-transform: uppercase;
          opacity: .75;
          display: block;
          text-align: center;
        }
        .description{

        }
      }
    }
  }
}

.internal{
  .wrapper {
    .download-dados {
      display: block !important;
      margin-left: 50px !important;

      content: "";
      background-image: url($img-path + '/images/icon_link.png');
      background-repeat: no-repeat;
      background-position: right 3px;
      background-size: contain;
      padding-left: 18px;
      color: #F4A14E !important;
    }
  }
}

.news {
  position: relative;
  min-width: 906px;
  max-width: 935px;
  width: auto;
  padding: 100px 0;

  iframe {
    width: 100%;
  }

  object {
    width: 100%;
    border-bottom: 1px solid gainsboro;
    height: 600px;
  }

  .container {
    position: relative;

    &.small { width: 906px; }
    &.big { width: 935px; }

    .borderBottom {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $greyText2;
      margin-top: 15px;
    }

    .text, p {
      font-family: 'Arial';
      font-size: 15px;
      font-weight: 100;
      //color: $superLightText;
      color: $mainBlack;
      line-height: 22px;
      font-weight: lighter;
      margin-bottom: 37px;
    }

    .marginTop30 { margin-top: 30px; }
    .marginTop45 { margin-top: 45px; }
    .marginTop60 { margin-top: 60px; }
  }

  .headline {
    position: relative;
    width: 100%;

    &.left-border {
      padding: 10px 0 0 25px;

      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 150px;
        background-color: $mainBlack;

        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .breadcrumb {
      font-family: 'NexaBold';
      font-size: 13px;
      color: $darkOrange;
      text-transform: uppercase;
    }

    .title {
      font-family: 'NexaThin';
      font-size: 45px;
      color: $mainBlack;
      line-height: 48px;

      &.size30 {
        font-size: 30px;
        line-height: 33px;
      }

      &.size20 {
        font-size: 20px;
        line-height: 23px;
      }

      &.bold {
        font-family: 'NexaBold';
      }
    }

    .description {
      font-family: 'Arial';
      font-size: 15px;
      font-weight: 100;
      color: $superLightText;
      line-height: 22px;
      margin: 10px 0 0;
    }

    .headline-info {
      font-family: 'Arial';
      font-size: 13px;
      font-weight: 100;
      color: $superLightText;
      line-height: 18px;
      margin-top: 25px;

      .name {
        font-family: 'NexaBold';
        color: $darkOrange;
      }

      .date {
        display: block;
        font-size: 11px;
      }
    }

    .social-icons {
      margin-top: 45px;
      margin-bottom: 10px;
      overflow: hidden;

      .icon {
        background-image: url($img-path + 'sprite-social.png');
        background-repeat: no-repeat;
        display: block;
        float: left;
        width: 65px;
        height: 45px;
        margin-right: 6px;

        &.facebook { background-position: 0; }
        &.twitter { background-position: -70px; }
        &.plus { background-position: -140px; }
        &.linkedin { background-position: -210px; }
        &.whatsapp { background-position: -280px; }
        &.email { background-position: -350px; }
      }
    }
  }

  .container-image {

    &.full {
      width: 100%;
    }

    .image {

      &.gif {
        max-width: 700px;
      }
    }

    .info {
      overflow: hidden;
      margin-top: 10px;

      .subtitle {
        font-family: 'Arial';
        font-weight: 100;
        font-size: 11px;
        color: $superLightText;
      }

      .author {
        font-family: 'Arial';
        font-weight: 100;
        font-size: 13px;
        color: $superLightText;
        background: url($img-path + 'icon_camera.png') center left no-repeat;
        padding-left: 20px;

        .name {
          font-family: 'NexaBold';
          font-size: 15px;
        }
      }
    }
  }

  .container-text {
    padding-left: 25px;
    margin-top: 75px;
    width: 735px;

    img {
      width: 100%;
    }

    a {
      color:#FF9900;
      text-decoration: none;
      font-weight: bold;
    }
    // blockquote {
    //  // margin: 0px 0px 37px 20px;

    //   &:first-child {
    //     margin-top: 0;
    //   }
    // }
    ul {
      margin: 0;


      -webkit-margin-start: 0px;
      -webkit-margin-end: 0px;
      -webkit-padding-start: 40px;
      li {
        font-family: Roboto, sans-serif;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 37px;
        list-style: disc;
        text-align: -webkit-match-parent;

      }
    }
    h1{
      font-size: 40px;
      line-height: 43px;
    }

    h2{
      font-size: 30px;
      line-height: 33px;
    }

    h3 {
      font-size: 20px;
      line-height: 23px;
    }

    h6 {
      font-size: 11px;
    }

    h1, h2, h3 {
      font-family: 'NexaThin';
      color: $mainBlack;
      margin-bottom: 37px;
    }
    h6 {
      color: $darkGrayText;
      margin: 5px 0px 37px 0;
      font-family: Roboto, sans-serif;
      font-weight: 100;
    }
    blockquote {
      position: relative;
      //margin: 0px 0px 37px 20px;
      width: 560px;

      border-left: 6px solid $mainBlack;
      padding: 20px 0 20px 20px;


      font-family: 'ClearfaceRegular';
      font-size: 20px;
      line-height: 35px;
      color: $darkGrayText;
      margin: 0px 0px 37px 20px;

    }
  }

  .container-facebook {
    margin: 20px 0 20px 25px;
  }

  .container-audio{
    margin: 0;

    audio {
      width: 100%;
    }
  }

  .container-media {
    margin: 0;

    video {
      width: 100%;
     // height: 394px;
    }


    #youtube-player {
      width: 700px;
      height: 394px;
    }
  }

  .container-tags {
    padding-top: 55px;
    padding-left: 25px;
    padding-bottom: 70px;

    .title {
      font-family: 'NexaBold';
      font-size: 20px;
      color: $mainBlack;
      text-transform: uppercase;
      margin-bottom: 5px;

    }

    .tag-list {
      font-family: 'Arial';
      font-size: 15px;
      color: $darkGrayText;

      .tag {
        font-weight: bolder;
        font-style: italic;
        color: $mainOrange;
      }
    }
  }

  .related-news {
    width: 100%;

    &__item {
      display: block;
      width: 24.2%;
      // min-height: 315px;
      float: left;
      box-shadow: 0 0 2px gray;
      margin-right: 1%;

      &:last-child {
        margin-right: 0;
      }

      .image {
        background-image: url($img-path + 'imagempelotas.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 100%;
        height: 147px;
      }

      .info {
        padding: 15px 20px;
        height: 63px;

        .title {
          font-family: 'NexaBold';
          font-size: 14px;
          color: $mediumGray;
          line-height: 16px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

        }

        .description {
          font-family: 'Arial';
          font-weight: lighter;
          font-size: 11px;
          color: $darkGrayText;
          display: block;
          line-height: 16px;
          margin-top: 15px;
        }
      }

      .read-more {
        font-family: 'NexaBold';
        font-size: 16px;
        color: $mainOrange;
        display: block;
        width: 100%;
        height: 43px;
        line-height: 43px;
        text-transform: uppercase;
        text-align: center;
        border-top: 1px solid #E9E9E9;
      }
    }
  }

  .gallery {

    .gallery-top {
      width: 700px;
      height: 466px;
      margin: 30px 0 0 0px;
    }

    .gallery-thumbs {
      width: 700px;
      height: 112px;
      margin: 8px 0 0 0px;

      .swiper-slide {
        width: 169px;
        height: 112px;
      }
    }

    .swiper-slide {
      background-size: cover;
    }

    .swiper-button-next,
    .swiper-button-prev {
      background: url($img-path + 'slide-arrow.png') center no-repeat;
      background-color: black;
      opacity: .5;
      transition: opacity .3s ease-in-out;
      height: 100%;
      bottom: 0;
      top: auto;
      &:hover { opacity: 0.7; }
    }

    .swiper-button-prev {
      margin-left: -10px;
      transform: rotate(180deg);
    }

    .swiper-button-next { margin-right: -10px; }
  }

  @media only screen and (max-width: 768px) {
    .displayer {
      display: none;
    }
  }

  .mobile-back {
    display: none;
  }

  &.tempMobile {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding: 5px 0 25px;

    .mobile-back {
      display: block;
      margin: 25px 0;
      padding-left: 25px;
    }

    .container {

      &.small { width: 100%; }
      &.big { width: 100%; }

      .text, p {
        font-size: 11px;
        line-height: 16px;
      }

      .headline {

        .breadcrumb {
          font-size: 11px;
        }

        .title {
          font-size: 34px;
          margin-top: 15px;
          line-height: 34px;
        }

        .description {
          font-size: 14px;
          line-height: 18px;
        }

        .social-icons {
          width: 215px;

          a { margin-bottom: 5px; }
        }
      }

      .container-tags {
        padding-top: 25px;
        padding-bottom: 10px;

        .tag-list {
          margin-bottom: 0;
        }
      }

      .related-news {

        &__item {
          width: 100%;
          max-width: 220px;
          margin-right: 0;
          margin-bottom: 15px;

          .info {
            height: auto;

            .title {
              margin: 0;
              font-size: 18px;
              line-height: 22px;
            }
          }
        }
      }

      .container-text {
        width: 100%;
        margin-top: 25px;
        padding-right: 25px;

        img {
          margin-bottom: 25px;
        }

        video {
          margin-bottom: 25px;
        }

        audio {
          margin-bottom: 25px;
        }

        blockquote {
          width: 100%;
          font-size: 18px;
          line-height: 28px;
          padding: 15px;
          margin: 0 0 20px;
        }
      }
    }

    .gallery {

      .gallery-top {
        width: 100%;
        height: 170px;
        margin: 25px 0 0;

        @include galaxys5 {
          height: 197px;
        }
        @include iphone6 {
          height: 206px;
        }
        @include nexus {
          height: 231px;
        }
        @include iplus {
          height: 232px;
        }
        @include ipad{
          height: 457px;
        }
      }

      .gallery-thumbs {
        display: none;
        width: 100%;
      }
    }
  }
}