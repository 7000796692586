.visite {
  position: relative;
  width: 100%;
  padding-top: 40px;
  background-color: $bgLight;

  .container {
    
    .slider {

      &__item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 663px;

        &:nth-child(1) {
          background-image: url($img-path + 'bigslider_image1.png');
        }

        &:nth-child(2) {
          background-image: url($img-path + 'bigslider_image2.png');
        }

        &:nth-child(3) {
          background-image: url($img-path + 'bigslider_image3.png');
        }

        &:nth-child(4) {
          background-image: url($img-path + 'bigslider_image4.png');
        }
      }
    }

    .box-info {
      padding: 70px 0;
      position: absolute;
      top: 40px;
      left: 0;
      width: 50%;
      height: calc(100% - 40px);
      background-color: rgba(90, 92, 101, .75);
      z-index: 1;

      .content {
        width: auto;
        max-width: 445px;
        margin: 0 auto;
        padding-left: 2%;
      }

      .description {
        font-family: 'NexaLight';
        font-size: 18px;
        color: $white;
        display: block;
        margin-top: 70px;
      }

      .divider {
        position: relative;
        display: block;
        width: 100%;
        max-width: 445px;
        height: 1px;
        background-color: $white;
        margin: 15px 0;

        &:before {
          content: '';
          display: block;
          width: 8px;
          height: 11px;
          background: $white;
          background: url($img-path + 'icon_losango.png') center no-repeat;

          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -5.5px;
          margin-left: -4px;
        }
      }

      .title {
        font-family: 'NexaThin';
        font-size: 34px;
        color: $white;
        display: block;
        line-height: 60px;

        .big {
          display: block;
          font-family: 'ArmoniosoRegular';
          font-size: 80px;
          color: $white;
        }
      }

      .slider-arrows {
        margin-top: 10px;
        margin-bottom: 50px;
      }
    }
  }
}