@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/futura.ttf");
}
@font-face {
  font-family: "Futura MD";
  src: url("../fonts/Futura/futura_medium.ttf");
}
@font-face {
  font-family: "Futura Bold Cond";
  src: url("../fonts/Futura/Futura_bold_md.ttf");
}

*{
  margin:0; padding:0;
}
body{
  font-family: 'Signika', sans-serif;
  font-size:18px; color:#000;
  background-size: 85%;
  /*background-position: center;*/
  background-repeat: no-repeat;
}

header{
  background-color: #21a95f;
  border-radius: 0 0 0 600px;
  //height: 100vh;
  height: 550px;
  .navbar-menu{
    a{
      color: white !important;
      font-family: "Futura MD" !important;
      font-size: 12px;
      img{
        width: 120px;
      }
    }
  }

  .people-area{
    div{
      &:nth-child(1){
        padding-right: 100px;
        img {
          width: 280px;
        }
      }
      &:nth-child(2) img{
        width: 340px;
      }
    }
  }
}

section.programa-economia{

  margin-top: 200px;
  font-family: "Futura Bold Cond" !important;

  p{
    font-family: "Futura MD" !important;
    color: #777;
  }
}

section.inscreva-unidade{
  margin-top: 200px;
  background-color: #f8c900;
  border-radius: 0 600px 0 0;
  height: 475px;

  div.orange-doll{
    margin-top: 73px;

    img {
      width: 358px;
    }
  }

  div.form{
    margin-top: 200px;
  }

  h4{
    text-transform: uppercase;
    margin-left: 160px;
    margin-top: 75px;
    margin-bottom: 35px;
    span{
      color: white;
      font-family: 'Futura Bold Cond' !important;
      font-size: 32px;
    }
  }

  form{
    .submit-group-input{
      margin-left: 362px !important;
    }
    .form-group{
      input{
        width: 440px;
        border-radius: 20px;
        padding: 3px 18px;

        &::placeholder{
          color: #AAA;
        }

        &.submit-input{
          padding: 0 4px 4px 4px;
          width: 80px;
          background-color: #f35300;
          color: white;
          font-family: "Futura Bold Cond" !important;
          cursor: pointer;
        }
      }
    }
  }
}

section.inscreva-ideia{
  background-color: #f35300;
  border-radius: 0 0 0 270px;
  height: 550px;
  overflow: hidden;
  div.orange-guy{
    margin-top: 80px;

    img {
      width: 340px;
    }
  }

  div.form{
    margin-top: 200px;
  }

  h4{
    text-transform: uppercase;
    margin-top: 75px;
    margin-bottom: 35px;
    span{
      color: white;
      font-family: 'Futura Bold Cond' !important;
      font-size: 32px;
    }
  }

  form{
    .form-group{
      input{
        width: 440px;
        border-radius: 20px;
        padding: 3px 18px;

        &::placeholder{
          color: #AAA;
        }

        &.submit-input{
          padding: 0 4px 4px 4px;
          width: 80px;
          background-color: #f8c900;
          color: white;
          font-family: "Futura Bold Cond" !important;
          cursor: pointer;
        }
      }
      textarea{
        border-radius: 20px;
        padding: 3px 18px;

        &::placeholder{
          color: #AAA;
        }
      }
    }
  }
}

footer.e-da-minha-conta-footer{
  height: 70px;
  img{
    width: 120px;
  }
}

.confirm-message{
  background-color: #21a95f;
  width: 100%;
  height: 100%;
  color: white;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 3rem;
  display: none;
  p{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
}


div.div-logo-pref{
  width: 100px !important;
}

.center{
  text-align: center;
}

@media only screen and (max-width: 480px) {
  header{
    border-radius: 0 0 0 140px !important;
    .people-area{
      div{
        img{
          width: 235px;
        }
      }
    }
  }

  section.programa-economia{
    margin-top: 75px;
    width: 100%;
    font-family: "Futura Bold Cond" !important;

    h1{
      font-size: 18px;
    }

    h3{
      font-size: 16px;
    }
    p{
      font-family: "Futura MD" !important;
      color: #777;
      width: 100%;
    }

    .click-download img{
      width: 100%;
    }
  }


  section.inscreva-unidade{
    margin-top: 200px;
    background-color: #f8c900;
    border-radius: 0 200px 0 0;
    height: 575px;

    div.orange-doll{
      margin-top: -153px;

      img {
        width: 240px;
      }
    }

    div.form{
      margin-top: 200px;
    }

    h4{
      text-transform: uppercase;
      margin-left: 40px;
      margin-top: 25px;
      margin-bottom: 35px;
      span{
        color: white;
        font-family: 'Futura Bold Cond' !important;
        font-size: 32px;
      }
    }

    form{

      .submit-group-input{
        text-align: right !important;
        margin-right: 5%;
        margin-left: 0 !important;
      }
      .form-group{
        text-align: center;
        input{
          width: 90%;
          border-radius: 20px;
          padding: 3px 18px;

          &::placeholder{
            color: #AAA;
          }

          &.submit-input{
            padding: 0 4px 4px 4px;
            width: 80px;
            background-color: #f35300;
            color: white;
            font-family: "Futura Bold Cond" !important;
            cursor: pointer;
          }
        }
      }
    }
  }

  section.inscreva-ideia{
    background-color: #f35300;
    border-radius: 0 0 0 175px;
    height: 770px;
    overflow: hidden;
    div.orange-guy{
      margin-top: 10px;
      img {
        width: 190px;
      }
    }

    div.form{
      margin-top: 200px;
    }

    h4{
      text-transform: uppercase;
      margin-top: 5px;
      margin-bottom: 35px;
      span{
        color: white;
        font-family: 'Futura Bold Cond' !important;
        font-size: 32px;
      }
    }

    form{
      text-align: center;
      .form-group{
        .submit-group-input{
          text-align: right !important;
          margin-right: 5%;
          margin-left: 0 !important;
        }

        input{
          width: 90%;
          border-radius: 20px;
          padding: 3px 18px;

          &::placeholder{
            color: #AAA;
          }

          &.submit-input{
            padding: 0 4px 4px 4px;
            width: 80px;
            background-color: #f8c900;
            color: white;
            font-family: "Futura Bold Cond" !important;
            cursor: pointer;
          }
        }
        textarea{
          width: 90%;
          border-radius: 20px;
          padding: 3px 18px;
          &::placeholder{
            color: #AAA;
          }
        }
      }
    }
  }
}