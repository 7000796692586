.buscador{
  .hidden{
    display: none;
  }
  .title{
    @media only screen and (max-width: 1023px) {
        font-size:20px !important;
      }
  }
  .headline {
    position: relative;
    width: 100%;

    &.left-border {
      padding: 10px 0 0 25px;

      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 110px;
        background-color: $mainBlack;

        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .breadcrumb {
      font-family: 'NexaBold';
      font-size: 13px;
      color: $darkOrange;
      text-transform: uppercase;
    }

    .title {
      font-family: 'NexaThin';
      font-size: 45px;
      color: $mainBlack;
      line-height: 48px;
      @media only screen and (max-width: 1023px) {
        font-size:16px;
      }

      &.size30 {
        font-size: 30px;
        line-height: 33px;
      }

      &.size20 {
        font-size: 20px;
        line-height: 23px;
      }

      &.bold {
        font-family: 'NexaBold';
      }
    }

    .description {
      font-family: 'Arial';
      font-size: 15px;
      font-weight: 100;
      color: $superLightText;
      line-height: 22px;
      margin: 10px 0 0;
    }

    .headline-info {
      font-family: 'Arial';
      font-size: 13px;
      font-weight: 100;
      color: $superLightText;
      line-height: 18px;
      margin-top: 25px;

      .name {
        font-family: 'NexaBold';
        color: $darkOrange;
      }

      .date {
        display: block;
        font-size: 11px;
      }
    }
  }
  .search__controllers{
    position:relative;
    display: block;
    width:100%;
    &.padding{
      padding:40px 20px 120px;
      @media only screen and (max-width: 1023px) {
        padding: 10px 5px 130px;
      }
    }
    float:right;
    .search__controll{
      margin: 10px 10px;
      // display:inline-block;
      .search__order{
        @media only screen and (max-width: 1023px) {
          display:block;
        }

      }
      .paginator{
        width: 100%;
        display: block;
        overflow-x: scroll;
        padding: 10px 0;
        .paginator__button{
          margin: 0 0.5px;
        }
      }
      p{
        font-family: 'NexaBold';
        font-size:16px;
        margin-bottom:8px;
      }
      input[name='url']{
        display: inline-block;
        padding: 12.5px 12.5px 12.5px 12.5px;
        color: #727279;
        font-family: 'NexaLight';
        font-size: 16px;
        background-color: #EEEEEE !important;
        width: 50%;
      }
      .copyurl{
        padding: 12.5px 12.5px 12.5px 12.5px;
        opacity: 0.8;
      }
      a{
        margin:10px 0;
        padding: 5px 10px;
        background-color: $mainOrange;
        color: $white;
        font-family: 'NexaLight';
        cursor: pointer;
        &:hover{
          opacity:0.7;
          transition: 0.3s all;
        }
        &.selected{
          background-color: $mainOrange;
          font-family: 'NexaBold';
          border: 2px solid #333;
          border-radius: 3px;
        }
      }
    }
  }
  .search__results{
    background-color: transparent;
    max-height: 1000px;
    @media only screen and (max-width: 800px) {
      max-height: 400px;
    }
    width:100%;
    position: relative;
    top: 0;
    margin-top: 15px;
    .result{
      border-bottom: 1px solid #e2e2e2;
      font-family: 'NexaLight';
      margin: 0;
      padding:20px;
      width:100%;
      &:hover{
        background-color: $bgLight2;
      }
      span{
        color: $darkOrange;
        display: block;
        font-family: 'NexaBold';
        font-size: 14px;
        padding: 2px 0;
      }

      a{
        color: $darkGrayText;
        font-size: 16px;
        cursor: pointer;
        &.description{
          font-size:12px;
        }
      }
    }
  }
  ::-webkit-scrollbar-track {
    background-color: #F4F4F4;
  }
  ::-webkit-scrollbar {
      width: 6px;
      background: #F4F4F4;
  }
  ::-webkit-scrollbar-thumb {
      background: #dad7d7;
  }
  .right{
    float:right;
    .search__controllers{
      background-color: $superLightGray;
    }
  }
  .left{
    float:left;
  }
  .content{
    width:50%;
    padding: 10px;
    @media only screen and (max-width: 900px) {
      width:100%;
    }
  }
  .search__advanced{
    max-height: 1000px;
    position:relative;
    background-color: rgba(0,0,0,0);
    overflow: visible;
    .search__advanced_fields{
      padding: 10px 0;
      background-color: rgba(0,0,0,0);
      .form{
        input[type='text']{
          background-color: #EEEEEE !important;
          width: 98%;
          margin:10px;
          @media only screen and (max-width: 1023px) {
            padding-right: 0px;
          }
        }
        button{
          border: none;
          margin:10px;
          padding: 10px 35px;
          background-color: $mainOrange;
          color: $white;
          font-family: 'NexaBold';
          font-size: 18px;
          cursor: pointer;
          &:hover{
            opacity:0.7;
            transition: 0.3s all;
          }
        }
        p{
          margin: 10px 10px;
          font-family: 'NexaLight';
          font-size: 14px;
        }
        input[type='date']{
          width: 98%;
          margin:4px 10px;
        }
        select{
          width: 98%;
          margin:4px 10px;
        }
        .option{
          margin:0px 10px;
        }
      }
    }
  }
}
